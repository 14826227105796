.sidebar-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow-y: scroll; /* Vertical scrolling */
  overflow-x: hidden; /* Hide horizontal overflow */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

.sidebar-content::-webkit-scrollbar {
  display: none; /* Hide vertical scrollbar in Safari and Chrome */
}
.sidebar-content2 {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-top: 3px;
}
.opdiv {
  background-color: #002132;
  display: flex;
  max-width: 259px;
  flex-direction: column;
  font-size: 18px;
  color: rgba(255, 255, 255, 0.6);
  font-weight: 400;
  padding: 27px 0;
  height: 100%;
  position: absolute;
  top: 0px;
  z-index: 3;
}
.opdiv-2 {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 5px;
}
.opimg {
  aspect-ratio: 4.55;
  object-fit: auto;
  object-position: center;
  width: 147px;
  align-self: center;
  max-width: 100%;
  display: flex;
  align-items: center;
}
.opdiv-3 {
  display: flex;
  margin-top: 30px;
  padding-right: 20px;
  gap: 18px;
  white-space: nowrap;
  cursor: pointer;
  padding: 10px 41px;
}
.opdiv-5 {
  display: flex;
  margin: 5px 0px;
  padding-right: 20px;
  gap: 18px;
  white-space: nowrap;
  cursor: pointer;
  padding: 10px 41px;
}
.opdiv2 {
  background-color: #002132;
  display: flex;
  max-width: 259px;
  flex-direction: column;
  font-size: 18px;
  color: rgba(255, 255, 255, 0.6);
  font-weight: 400;
  padding: 30px 0;
  height: 100%;
  position: absolute;
  top: 0px;
  z-index: 3;
}
.opdiv2-2 {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 5px;
}
.opimg2 {
  aspect-ratio: 4.55;
  object-fit: auto;
  object-position: center;
  width: 147px;
  align-self: center;
  max-width: 100%;
}
.opdiv2-3 {
  display: flex;
  margin-top: 15px;
  white-space: nowrap;
  cursor: pointer;
  justify-content: center;
  padding: 20px 0px 20px 0px;
}
.normal-link {
  text-decoration: none;
  color: #99a6ad;
}
.opimg2-2 {
  aspect-ratio: 1;
  object-fit: auto;
  object-position: left;
  width: 24px;
}
.opdiv2-15 {
  display: flex;
  gap: 11px;
  font-size: 16px;
  color: #fff;
  font-weight: 600;
  letter-spacing: 0.32px;
  padding: 13px 25px;
  cursor: pointer;
  width: fit-content;
}
.opimg2-8 {
  aspect-ratio: 0.95;
  object-fit: auto;
  object-position: center;
  width: 24px;
  height: 24px;
}
.opimg-2 {
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 24px;
}
.opdiv-4 {
  font-family: Karla, sans-serif;
}
.opdiv-4 img {
  width: 20px;
}
.opdiv-15 {
  border-radius: 40px;
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 1px;
  display: flex;
  gap: 11px;
  font-size: 16px;
  color: #fff;
  font-weight: 600;
  letter-spacing: 0.32px;
  padding: 13px 32px;
  cursor: pointer;
  width: fit-content;
}
.opdiv-16 {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}
.complete-sidebar {
  display: flex;
  flex-direction: row;
  height: 100%;
}
.opdiv2-16 {
  background-color: #002132;
  width: 170px;
  height: 100%;
  padding-top: 40px;
}
.opimg-8 {
  aspect-ratio: 0.95;
  object-fit: auto;
  object-position: center;
  width: 19px;
}
.activeTab {
  background-color: #023d5b;
}
.activeTab .opdiv-4 {
  color: #fff;
}

.topbardiv {
  background-color: #fff;
  display: flex;
  gap: 20px;
  padding: 22px 35px;
  position: absolute;
  top: 0px;
  width: 100%;
  padding-left: 75px;
  z-index: 1;
  box-shadow: 0px 4px 16px 0px rgba(99, 99, 99, 0.05);
}
@media (max-width: 991px) {
  .topbardiv {
    flex-wrap: wrap;
    padding: 0 20px;
  }
}
.topbardiv-2 {
  color: var(--Secondary-Text-Color);
  font: 300 20px/140% Roboto, -apple-system, Roboto, Helvetica, sans-serif;
  margin: auto;
}
.topbardiv-3 {
  display: flex;
  gap: 15px;
  justify-content: space-between;
  flex-grow: 1;
  flex-basis: auto;
  flex-direction: row-reverse;
}
@media (max-width: 991px) {
  .topbardiv-3 {
    flex-wrap: wrap;
  }
}
.topbardiv-4 {
  border-radius: 40px;
  background-color: rgba(247, 247, 247, 0.1);
  display: flex;
  gap: 15px;
  font-size: 15px;
  color: #e5e5e5;
  font-weight: 400;
  padding: 15px 25px;
  min-width: 300px;
}
@media (max-width: 991px) {
  .topbardiv-4 {
    padding: 0 20px;
  }
}
.topbarimg {
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 20px;
}
.topbardiv-5 {
  font-family: Karla, sans-serif;
  flex-grow: 1;
  flex-basis: auto;
  margin: auto 0;
  background-color: #193746;
  border: 0px;
  color: #fff !important;
}
.topbardiv-5::placeholder {
  color: #fff;
}
.topbardiv-5:focus {
  outline: none;
}
.topbardiv-6 {
  justify-content: center;
  display: flex;
  gap: 16px;
  font-size: 18px;
  color: #fff;
  font-weight: 500;
  padding: 13px 16px;
}
.topbardiv-7 {
  display: flex;
  gap: 14px;
}
.topbarimg-2 {
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 24px;
}
.topbardiv-8 {
  font-family: Karla, sans-serif;
}
.topbarimg-3 {
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 24px;
}
.topbarimg-4 {
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 50px;
}
.topbarimg-5 {
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 50px;
}
.topbarimg-6 {
  aspect-ratio: 0.92;
  object-fit: cover;
  object-position: center;
  width: 40px;
  height: 40px;
  align-self: start;
  border-radius: 50%;
  margin-right: 15px;
}
.topbardiv-9 {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  margin: auto 0;
}
.investor-profile-div {
  display: flex;
}
@media (max-width: 991px) {
  .topbardiv-9 {
    white-space: initial;
  }
}
.topbardiv-10 {
  display: flex;
  gap: 20px;
  font-size: 16px;
  color: #fff;
  font-weight: 500;
  line-height: 150%;
  justify-content: space-between;
}
@media (max-width: 991px) {
  .topbardiv-10 {
    white-space: initial;
  }
}
.topbardiv-11 {
  font-family: Karla, sans-serif;
}
.topbarimg-7 {
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 12px;
  align-self: start;
  margin-top: 5px;
}
.topbardiv-12 {
  color: #e5e5e5;
  font: 400 14px/143% Karla, -apple-system, Roboto, Helvetica, sans-serif;
}
.InvestorSide {
  background-color: #f9f9f9;
}
.InvestorSide .settings,
.InvestorSide .notifications,
.InvestorSide .investor-profile-div {
  cursor: pointer;
}
.InvestorSide .topbar-utilities {
  display: flex;
  gap: 30px;
  align-items: center;
}
.InvestorSide .oppo-heading {
  color: #696f79;
  font-family: "Karla", sans-serif;
  font-size: 20px;
  font-weight: 500;
}

.InvestorSide .InvestorSide-box {
  margin-top: 72px;
  margin-left: 72px;
  padding: 8px 8px;
  height: calc(100vh - 80px);
  overflow-y: scroll;
}
/* .noNamePreview-page .InvestorSide-box,
.companyOverview-page .InvestorSide-box {
  overflow-y: hidden !important;
} */
.investorDetailsPage .InvestorSide-box {
  margin-left: 0px;
  padding: 0px;
  background-color: #f6f8ff;
}
.investorDetailsPage .InvestorSide {
  background-color: #ffff;
}
/* 260px is sidebar width */
@media all and (min-width: 1760px) {
  /* .onGoingDeals-section .InvestorSide-box {
    max-width: initial;
    margin-left: 260px !important;
  } */
  /* .onGoingDeals-section .InvestorSide-content {
    max-width: initial;
    margin: initial;
  } */
}

.investorDetailsPage .cdetails-div {
  padding: 20px;
  margin-left: 80px;
  border-radius: 10px;
}
.investorDetailsPage .showInterestButtons-div {
  left: 0px;
  width: 100%;
}
/* .investorDetailsPage .btns-insideContent {
  left: 0px;
  width: 100%;
} */
/* .btns-insideContent {
  width: 100%;
  display: flex;
  justify-content: center;
} */
@media (min-width: 1500px) {
  .investorDetailsPage .showInterestButtons-div {
    width: 100%;
  }
  .investorDetailsPage .btns-insideContent {
    left: initial;
    margin-left: calc(50% - 750px);
  }
}
.toggle-card-type-btn {
  margin: 20px 0px;
  text-transform: none;
}
.css-d9c359-MuiButtonBase-root-MuiToggleButton-root {
  text-transform: none !important;
  padding: 7px 15px !important;
}
.data-availiabity-div {
  padding: 0px !important;
  padding-left: 20px !important;
  background-color: #fff !important;
  margin: 0px !important;
}

.InvestorSide .emptyCard {
  width: 300px;
}
.InvestorSide .stay-tuned-text {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 10px;
}
.InvestorSide .under-construnction-text {
  color: #667085;
  font-family: "Karla", sans-serif;
  font-size: 25px;
  font-weight: 400;
}
.InvestorSide .img-box {
  width: 300px;
  margin: auto;
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
.InvestorSide-content {
  max-width: 1500px;
  margin: 0px auto;
}
.InvestorSide-content-box {
  display: flex;
  justify-content: space-between;
  margin-left: 20px;
}

.shapingTheFuture {
  background: linear-gradient(90deg, #0140ed 57.78%, #4ef79a 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Karla;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.InvestorSide-content-action-buttons {
  display: flex;
  gap: 30px;
}
.InvestorSide-content-button-1 {
  padding: 15px 27px;
  cursor: pointer;
  padding: 12px;
  border-radius: 12px;
  height: fit-content;
  width: fit-content;
  border: 1px solid #114fee;
}
.InvestorSide-content-button-1 img {
  width: 100%;
}

.typeOfDeals {
  display: flex;
  gap: 30px;
  margin: 20px 0px 30px 0px;
  width: 100%;
  padding-bottom: 3px;
  padding-left: 20px;
}
.typeOfDeals-btn {
  background-color: #f9f9f9;
  border: 0px;
  color: #8692a6;
  width: fit-content;
  font-size: 22px;
  font-family: "Karla", sans-serif;
  align-self: start;
  cursor: pointer;
}
.typeOfDeals-active-btn {
  color: #00a6fb;
  font-weight: 600;
}
.typeOfDeals-active-btn::after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background-color: #00a6fb;
  margin-top: -4px;
  position: relative;
  z-index: 5;
}
.filter-box {
  background-color: #fff;
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
}
.upper-filter-box {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
.upper-filter-box-col {
  display: flex;
  flex-direction: column;
  border-right: 1px solid #dbdade;
  margin: 0px auto;
  width: 32.5%;
  padding-right: 20px;
}
.filter-box-inputs {
  display: flex;
  justify-content: space-between;
}
.filter-box-content {
  width: 90%;
  margin: 0px auto;
}
.filter-box-inputs-input {
  width: 46%;
  height: 46px;
}
.filterBox-Decorator {
  font-size: 14px;
  font-family: "Karla", sans-serif;
  color: #8692a6;
}
.css-1jq9w0k-JoySlider-track,
.css-hayzob-JoySlider-thumb {
  background-color: #00a6fb !important;
}
.css-hayzob-JoySlider-thumb::before {
  border-color: #fff !important;
}
.filter-box-content p,
.lower-filter-box p {
  color: #696f79;
  font-family: "Karla", sans-serif;
  font-size: 16px;
  font-weight: 500;
  margin-top: 20px;
}
.checkbox-cols {
  display: flex;
  gap: 20px;
  flex-direction: column;
}
.checkbox-section-1 {
  display: flex;
  gap: 25px;
  margin-top: 25px;
}
.checkbox-cols .reason-checkbox {
  color: #515151;
}
.lower-filter-box {
  margin: 20px;
}
.filter-checkbox {
  width: 150px;
  height: 60px;
  color: #515151 !important;
  font-weight: 500;
}
.checkbox-section-2 {
  margin-top: 25px;
}
.investor-stage-table td {
  border: 0px;
  width: 150px;
}
.investor-stage-table {
  border-bottom: 1px solid #dbdade;
}
