.landing-page *,
.open-listing-page *,
.landing-page-terms *,
.investor-profile *,
.investor-edit-profile *,
.newDeals-page *,
.noNameProfile *,
.analytics-page * {
  font-family: "Satoshi", "sans-serif" !important;
}
.open-listing-page .Card-div-3,
.open-listing-page .Card-div-24 {
  font-weight: 500;
}
.faded-content::before,
.faded-content::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  height: 30px; /* The fade height */
  pointer-events: none; /* So the pseudo-element doesn't interfere with clicks */
}

.faded-content::before {
  top: 0;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );
}

.faded-content::after {
  bottom: 0;
  background: linear-gradient(
    to top,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );
}
