.companyOnboarding {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-top: 16px;
  gap: 16px;
  align-items: stretch;
}
@media (max-height: 820px) {
  .companyOnboarding {
    margin-bottom: 100px;
  }
}
.suggested-section {
  border-radius: 4px;
  background-color: #fff;
  padding: 16px;
  width: 25%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  /* height of steps component */
  max-height: 644px;
}
.companyOnboarding-form {
  width: 62%;
}
.suggested-section .heading {
  color: var(--Primary-Text-Color);
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
}
.suggested-section .subheading {
  color: var(--Secondary-Text-Color);
  font-size: 14px;
  font-weight: 500;
}
.suggested-section .subheading b {
  color: var(--Primary-Text-Color);
}
.companyOnboarding-form-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px 80px;
}
.onboardingForm-card {
  height: 100%;
  overflow: scroll;
}
.onboardingForm-card .heading {
  color: var(--Primary-Text-Color);
  font-size: 20px;
  font-weight: 500;
}
.onboardingForm-card .subheading {
  color: var(--Secondary-Text-Color);
  font-size: 14px;
  margin-bottom: 24px;
}
.onboardingForm-card .tnc-text-block {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: auto;
}

/* Select time field */
/* .CalenderSetup .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  height: 16px;
}
.CalenderSetup .css-4jnixx-MuiStack-root > .MuiTextField-root,
.CalenderSetup .css-160unip > .MuiTextField-root {
  min-width: 160px;
  width: 160px;
} */
.ApplicationUnderReview {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.underReview-heading {
  font-size: 20px;
  font-family: "Roboto";
  color: var(--Primary-Text-Color);
}
.underReview-subheading {
  font-size: 14px;
  text-align: center;
  color: var(--Secondary-Text-Color);
}
.TermsnConditions {
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: calc(100vh - 300px);
}
.tnc-document {
  height: 90%;
}
.DataroomPreparation {
  height: 645px;
}
@media all and (min-height: 950px) {
  .DataroomPreparation {
    height: 100%;
  }
}
