.profile-box {
  height: 220px;
  width: 237px;
  position: absolute;
  top: 60px;
  right: -10px;
}

.profile-box .discord-card-left {
  height: 220px;
  left: 0;
  top: 0;
  width: 237px;
}

.profile-box .overlap {
  border-radius: 10px;
  height: 220px;
  position: relative;
}

.profile-box .card-base {
  background-color: #193746;
  border-radius: 10px;
  height: 220px;
  left: 0;
  position: absolute;
  top: 0;
  width: 237px;
}

.profile-box .overlap-group {
  height: 91px;
  left: 0;
  position: absolute;
  top: 0;
  width: 237px;
}

.profile-box .rectangle {
  background-color: #002132;
  border-radius: 10px 10px 0px 0px;
  height: 59px;
  left: 0;
  position: absolute;
  top: 0;
  width: 237px;
}

.profile-box .ellipse {
  background-color: #002132;
  border-radius: 34.49px/34.29px;
  height: 69px;
  left: 7px;
  position: absolute;
  top: 22px;
  width: 69px;
}

.profile-box .img {
  height: 60px;
  left: 11px;
  object-fit: cover;
  position: absolute;
  top: 26px;
  width: 60px;
  border-radius: 50%;
}

.profile-box .div {
  background-color: #002132;
  border-radius: 10px;
  height: 118px;
  left: 6px;
  position: absolute;
  top: 96px;
  width: 225px;
}

.profile-box .group {
  height: 20px;
  left: 207px;
  position: absolute;
  top: 10px;
  width: 20px;
}

.profile-box .overlap-2 {
  border-radius: 10px;
  height: 20px;
  position: relative;
}

.profile-box .icon {
  height: 10px;
  left: 5px;
  position: absolute;
  top: 5px;
  width: 10px;
}

.profile-box .ellipse-2 {
  background-color: #f7f7f71a;
  border-radius: 10px;
  height: 20px;
  left: 0;
  position: absolute;
  top: 0;
  width: 20px;
}

.profile-box .group-2 {
  height: 44px;
  left: 20px;
  position: absolute;
  top: 106px;
  width: 81px;
}

.profile-box .text-wrapper {
  color: #e5e5e5;
  font-family: "Karla", Helvetica;
  font-size: 12px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 24px;
  white-space: nowrap;
}

.profile-box .text-wrapper-2 {
  color: #ffffff;
  font-family: "Karla", Helvetica;
  font-size: 14px;
  font-weight: 700;
  height: 24px;
  left: 0;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.profile-box .group-3 {
  height: 44px;
  left: 20px;
  position: absolute;
  top: 160px;
  width: 72px;
}

.profile-box .text-wrapper-3 {
  color: #ffffff;
  font-family: "Karla", Helvetica;
  font-size: 10px;
  font-weight: 700;
  height: 24px;
  left: 0;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.profile-box .line {
  height: 1px;
  left: 11px;
  position: absolute;
  top: 154px;
  width: 215px;
}
