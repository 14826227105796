.investorDetails {
  display: flex;
  margin-top: 120px;
  margin-left: 20px;
}
.investorDetails .div {
  border-radius: 10px;
  width: 25%;
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 38px 35px;
  height: fit-content;
}
.companyDetailsPage .div {
  width: 100%;
}
.investorDetails .div-2 {
  color: #000;
  letter-spacing: 0investorDetails 0.4px;
  align-self: start;
  font: 500 20px Roboto, sans-serif;
}
.investorDetails .div-3 {
  align-self: start;
  display: flex;
  margin-top: 31px;
  align-items: start;
  gap: 15px;
}
.investorDetails .div-4 {
  background-color: #00a6fb;
  border-radius: 50%;
  align-items: center;
  color: #fff;
  white-space: nowrap;
  letter-spacing: 0investorDetails 0.32px;
  justify-content: center;
  width: 30px;
  height: 30px;
  display: flex;
  font: 500 16px Karla, sans-serif;
}
.investorDetails .div-5 {
  display: flex;
  flex-direction: column;
}
.investorDetails .div-6 {
  color: #696f79;
  letter-spacing: 0investorDetails 0.24px;
  font: 400 12px Karla, sans-serif;
}
.investorDetails .div-7 {
  color: #000;
  letter-spacing: 0investorDetails 0.28px;
  margin-top: 9px;
  font: 500 14px Karla, sans-serif;
}
.investorDetails .div-8 {
  align-self: start;
  display: flex;
  gap: 20px;
  font-size: 10px;
  color: #1775d5;
  font-weight: 400;
  letter-spacing: 0investorDetails 0.2px;
  justify-content: space-between;
  margin: 5px 0 0 22px;
}
.investorDetails .img {
  aspect-ratio: 0investorDetails 0.06;
  object-fit: auto;
  object-position: center;
  width: 2px;
  fill: #00a6fb;
  stroke-width: 2px;
  stroke: #00a6fb;
  border-color: rgba(0, 166, 251, 1);
  border-style: solid;
  border-width: 2px;
}
.investorDetails .div-9 {
  font-family: Karla, sans-serif;
  border-radius: 10px;
  background-color: #d8effd;
  align-self: start;
  margin-top: 4px;
  justify-content: center;
  padding: 6px 14px;
  color: #1775d5;
  font-size: 10px;
}
.investorDetails .complete {
  background-color: #d6f7e5;
  color: #00cd9e;
}
.investorDetails .div-10 {
  align-self: start;
  display: flex;
  margin-top: 6px;
  gap: 15px;
  color: #696f79;
  margin-top: 30px;
  cursor: pointer;
}
.investorDetails .div-11,
.investorDetails .div-31 {
  stroke-width: 1px;
  border-color: rgba(105, 111, 121, 1);
  border-style: solid;
  border-width: 1px;
  background-color: #fff;
  border-radius: 50%;
  align-items: center;
  white-space: nowrap;
  letter-spacing: 0investorDetails 0.32px;
  justify-content: center;
  width: 30px;
  height: 30px;
  display: flex;
  font: 500 16px Karla, sans-serif;
  z-index: 4;
}
.investorDetails .div-11::after {
  content: "";
  position: absolute;
  width: 1px;
  background-color: #696f79;
  height: 80px;
  margin-top: 110px;
}
.investorDetails .active-step {
  background-color: #00a6fb;
  border-color: #00a6fb;
  color: #fff;
}
.investorDetails .active-step::after {
  background-color: #00a6fb;
}
.investorDetails .div-12 {
  letter-spacing: 0investorDetails 0.24px;
  flex-grow: 1;
  flex-basis: auto;
  margin: auto 0;
  font: 400 12px Karla, sans-serif;
}
.investorDetails .div-13 {
  color: #696f79;
  letter-spacing: 0investorDetails 0.28px;
  font: 400 14px Karla, sans-serif;
  margin-top: 9px;
}
.investorDetails .div-14 {
  border-radius: 10px;
  border-color: rgba(105, 111, 121, 1);
  border-style: solid;
  border-width: 1px;
  margin-top: 9px;
  color: #696f79;
  white-space: nowrap;
  letter-spacing: 0investorDetails 0.2px;
  justify-content: center;
  padding: 6px 19px;
  font: 400 10px Karla, sans-serif;
  width: fit-content;
}
.investorDetails .div-15 {
  align-self: start;
  display: flex;
  margin-top: 17px;
  gap: 15px;
  color: #696f79;
}
.investorDetails .div-16 {
  stroke-width: 1px;
  border-color: rgba(105, 111, 121, 1);
  border-style: solid;
  border-width: 1px;
  background-color: #fff;
  border-radius: 50%;
  align-items: center;
  white-space: nowrap;
  letter-spacing: 0investorDetails 0.32px;
  justify-content: center;
  width: 30px;
  height: 30px;
  padding: 0 10px;
  font: 500 16px Karla, sans-serif;
}
.investorDetails .div-17 {
  letter-spacing: 0investorDetails 0.24px;
  flex-grow: 1;
  flex-basis: auto;
  margin: auto 0;
  font: 400 12px Karla, sans-serif;
}
.investorDetails .div-18 {
  color: #696f79;
  letter-spacing: 0investorDetails 0.28px;
  font: 400 14px Karla, sans-serif;
}
.investorDetails .div-19 {
  border-radius: 10px;
  border-color: rgba(105, 111, 121, 1);
  border-style: solid;
  border-width: 1px;
  margin-top: 9px;
  color: #696f79;
  white-space: nowrap;
  letter-spacing: 0investorDetails 0.2px;
  justify-content: center;
  padding: 6px 19px;
  font: 400 10px Karla, sans-serif;
}
.investorDetails .div-20 {
  align-self: start;
  display: flex;
  margin-top: 18px;
  gap: 15px;
  color: #696f79;
}
.investorDetails .div-21 {
  stroke-width: 1px;
  border-color: rgba(105, 111, 121, 1);
  border-style: solid;
  border-width: 1px;
  background-color: #fff;
  border-radius: 50%;
  align-items: center;
  white-space: nowrap;
  letter-spacing: 0investorDetails 0.32px;
  justify-content: center;
  width: 30px;
  height: 30px;
  padding: 0 10px;
  font: 500 16px Karla, sans-serif;
}
.investorDetails .div-22 {
  letter-spacing: 0investorDetails 0.24px;
  flex-grow: 1;
  flex-basis: auto;
  margin: auto 0;
  font: 400 12px Karla, sans-serif;
}
.investorDetails .div-23 {
  color: #696f79;
  letter-spacing: 0investorDetails 0.28px;
  font: 400 14px Karla, sans-serif;
}
.investorDetails .div-24 {
  border-radius: 10px;
  border-color: rgba(105, 111, 121, 1);
  border-style: solid;
  border-width: 1px;
  margin-top: 6px;
  color: #696f79;
  white-space: nowrap;
  letter-spacing: 0investorDetails 0.2px;
  justify-content: center;
  padding: 6px 19px;
  font: 400 10px Karla, sans-serif;
}
.investorDetails .div-25 {
  align-self: start;
  display: flex;
  margin-top: 18px;
  gap: 15px;
  color: #696f79;
}
.investorDetails .div-26 {
  stroke-width: 1px;
  border-color: rgba(105, 111, 121, 1);
  border-style: solid;
  border-width: 1px;
  background-color: #fff;
  border-radius: 50%;
  align-items: center;
  white-space: nowrap;
  letter-spacing: 0investorDetails 0.32px;
  justify-content: center;
  width: 30px;
  height: 30px;
  padding: 0 10px;
  font: 500 16px Karla, sans-serif;
}
.investorDetails .div-27 {
  letter-spacing: 0investorDetails 0.24px;
  flex-grow: 1;
  flex-basis: auto;
  margin: auto 0;
  font: 400 12px Karla, sans-serif;
}
.investorDetails .div-28 {
  color: #696f79;
  letter-spacing: 0investorDetails 0.28px;
  font: 400 14px Karla, sans-serif;
}
.investorDetails .div-29 {
  border-radius: 10px;
  border-color: rgba(105, 111, 121, 1);
  border-style: solid;
  border-width: 1px;
  margin-top: 6px;
  color: #696f79;
  white-space: nowrap;
  letter-spacing: 0investorDetails 0.2px;
  justify-content: center;
  padding: 6px 19px;
  font: 400 10px Karla, sans-serif;
}
.investorDetails .div-30 {
  display: flex;
  flex-direction: column;
}
.Header .group {
  height: 55px;
  width: 188px;
  position: absolute;
  right: 30px;
}
.Header .group-2 {
  height: 55px;
  width: 188px;
  position: absolute;
  right: 180px;
}

@media all and (max-width: 991px) {
  .Header .group {
    position: relative;
  }
}
.css-w3f085-control {
  height: initial !important;
  min-height: 64px !important;
}
.investorDetails .input-div {
  display: flex;
  margin-top: 30px;
}
.investorDetails .input-section {
  width: 100%;
}
/* .account-input-section {
  max-width: 1160px;
} */
.investorDetails-row-input {
  width: 100%;
  margin-right: 50px;
}
.investorDetails-row-large-input {
  width: calc(100% - 50px);
}
.investorDetails .react-select-input {
  background-color: #fff;
}
.investorDetails .large-input,
.investor-account-box .large-input {
  height: fit-content;
  display: flex;
  align-items: flex-start;
}
.investorDetails .editable-row {
  padding: 0px;
}
.investorDetails .editable-header {
  height: 30px;
}
.kNitqo {
  background-color: #eaebec;
  color: #696f79;
}
.investorDetails .editable-cell {
  width: 157px;
}
.companyDetailsPage .investorDetails .editable-cell {
  width: 140px;
}
.investorDetails .add-row,
.invite-members-div {
  border-radius: 6px;
  border: 1px solid #00a6fb;
  background: #fff;
  height: fit-content;
  width: fit-content;
  padding: 10px 15px;
  color: #fff;
  background-color: #00a6fb;
  text-align: center;
  font-family: Karla;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.investorDetails .delete-row {
  border-radius: 6px;
  border: 1px solid #d32f2f;
  background: #fff;
  height: fit-content;
  width: fit-content;
  padding: 8px 35px;
  color: #d32f2f;
  text-align: center;
  font-family: Karla;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.investorDetails .investmentDetails-table {
  margin-top: 30px;
}
.table-edit-buttons {
  margin-top: 20px;
}
.investorDetailsPage .next-buttons {
  display: flex;
  display: flex;
  align-items: center;
  gap: 30px;
}
.investorDetailsPage .nextStep {
  border-radius: 6px;
  background: #00a6fb;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.32px;
  width: 200px;
  display: flex;
  height: 55px;
  border: 0px;
  justify-content: center;
  align-items: center;
}
.Preview-button {
  border-radius: 6px;
  border: 1px solid #00a6fb !important;
  background: #e5f5fe;
  color: #00a6fb;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.32px;
  width: 200px;
  display: flex;
  height: 55px;
  border: 0px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.previousStep {
  border-radius: 6px;
  background: #fff;
  color: #00a6fb;
  border: 1px solid #00a6fb;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.32px;
  width: 200px;
  display: flex;
  height: 55px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.investorDetailsPage .second-step,
.investorDetailsPage .third-step,
.investorDetailsPage .fourth-step,
.investorDetailsPage .fifth-step,
.investorDetailsPage .sixth-step,
.investorDetailsPage .seventh-step,
.investorDetailsPage .eighth-step,
.investorDetailsPage .ninth-step,
.investorDetailsPage .eleventh-step {
  display: none;
}
.second-step .investorDetails-first-section {
  padding-bottom: 20px;
}
.investorDetails .investment-team-member-addition {
  border-top: 1px dashed #000;
}
.investorDetails .navigation-buttons {
  display: flex;
  flex-direction: row;
  margin: 25px 0 20px 30px;
  justify-content: space-between;
  width: 95%;
}
.investorDetailsPage .skip-button {
  border-radius: 6px;
  height: fit-content;
  width: fit-content;
  padding: 18px 0px;
  color: #d32f2f;
  text-align: center;
  font-family: Karla;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 10px;
}
.investorDetails .ticket-size-div {
  display: flex;
  width: 100%;
}
.investorDetails .ticket-size-min,
.investorDetails .ticket-size-max {
  padding-left: 30px;
}
.investorDetails .ticket-size-text-div {
  width: 50%;
}
.investorDetails .ticket-size-div {
  margin-top: 10px;
}
.investorDetails .third-step-first-row,
.investorDetails .third-step-second-row,
.investorDetails .third-step-third-row,
.investorDetails .industry-focus-first-row {
  width: 100%;
  display: flex;
  margin-top: 20px;
}
.companyDetailsPage .third-step-second-row,
.companyDetailsPage .companyReview,
.companyDetailsPage .FoundingTeam,
.fundingDetails,
.documents,
.InvestorSide .companyReview,
.InvestorSide .FoundingTeam,
.cdetails-div-18 {
  padding-bottom: 20px;
  border-bottom: 1px dashed #8692a6;
}
.InvestorSide .profile-boxes {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}
/* .InvestorSide .companyReview{
    margin-left: 20px;
  } */
.InvestorSide .typeOfDeals {
  margin-bottom: 10px;
}
.investorDetails .stage-of-investment-input {
  width: 30%;
  margin-right: 45px;
}
.investorDetails .yes-or-no-button {
  border: 0px;
  height: 70px;
  width: 80px;
  border: 1px solid #8692a6;
  background-color: #fff;
  border-radius: 10px;
}
.investorDetails .yes-button {
  margin-right: 20px;
}
.investorDetails .selected-button {
  background-color: #dff0f8;
  color: #00a6fb;
  border: 1px solid #00a6fb;
}
.investorDetails .other-criteria {
  width: 70%;
  padding-left: 5px;
}
.investorDetails .coinvestment-div {
  width: 40%;
}
.investorDetails .NDA-text {
  font-size: 14px;
  color: #667085;
  margin-top: 30px;
  font-family: Karla;
}
@media all and (max-width: 991px) {
  .investorDetails .NDA-text {
    padding: 0px 10px;
  }
}
.investorDetails .terms-condition-text {
  font-size: 16px;
  color: #696f79;
  font-family: Karla;
  gap: 10px;
}
.investorDetails .terms-condition-text {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

/* .investorDetailsPage .dialog-box{
    width: 500px !important;
  } */
.react-tagsinput-input {
  width: 350px !important;
}
.react-tagsinput {
  border-radius: 10px;
  width: 75%;
}
.send-invite {
  margin-left: 20px;
  background-color: #00a6fb;
  border: 0px;
  border-radius: 5px;
  font-size: 12px;
  color: #fff;
  padding: 10px 10px;
  width: 105px;
  display: flex;
  align-items: center;
  gap: 5px;
  height: fit-content;
}

@media all and (max-width: 991px) {
  .select-peers-input {
    flex-direction: column;
    gap: 10px;
  }
  .send-invite {
    margin-left: 0px;
  }
  .react-tagsinput {
    width: 100%;
  }
  .dialogcontent {
    padding: 15px;
  }
}

.dialogcontenttext {
  font-family: "Karla", sans-serif !important;
  color: #000 !important;
  margin-bottom: 10px !important;
}
.react-tagsinput-tag {
  background-color: #f4f4f4 !important;
  border-radius: 20px !important;
  border: 0px !important;
  color: #000 !important;
  padding: 12px 28px 12px 12px !important;
}
.react-tagsinput-tag a::before {
  position: absolute;
  content: url("../../Assets/Images/signup/cross.svg") !important;
  margin-left: 7px;
}
.investorDetails .InvestorLogo,
.InvestorSide .InvestorLogo {
  width: 134px;
  height: 117px;
  object-fit: contain; /* Use 'contain' if you don't want the image to be cropped */
  display: block;
}
.investorDetails .companyReview-img {
  width: 134px;
}
.investorDetails .InvestorLogo {
  max-width: initial;
}
.Header .overlap-group-wrapper {
  height: 55px;
  width: 190px;
}

.Header .overlap-group {
  background-color: #ffffff;
  border-radius: 40px;
  height: 55px;
  position: relative;
  width: 188px;
}

.Header .text-wrapper-4 {
  color: #000000;
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-weight: 500;
  left: 86px;
  letter-spacing: 0.32px;
  line-height: normal;
  position: absolute;
  top: 18px;
  white-space: nowrap;
}

.Header .material-symbols {
  height: 24px;
  left: 47px;
  position: absolute;
  top: 16px;
  width: 24px;
}
.investorDetailsPage .landing-header {
  height: 100px;
}
.investorDetailsPage .cm-menu-inner {
  margin-top: 5px;
}
.details-section {
  width: 100%;
}
.investorDetails .investorDetails-first-section {
  width: 95%;
  background-color: #f7f7f7;
  margin: 0px auto;
  padding: 30px;
  border-radius: 10px;
}
.investorDetails-heading,
.investorDetails-heading-visible {
  color: #000;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.investorDetails-heading-stage {
  color: #494f58;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 20px;
}
.investorDetails-subheading {
  color: #696f79;
  font-family: Roboto;
  font-size: 25px;
  font-weight: 500;
  margin-top: 20px;
}
.investorDetails-sub-heading {
  color: rgba(134, 146, 166, 1);
  font-family: Karla;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  margin-top: 40px;
  width: 100%;
}

.upload-btn-wrapper input[type="file"] {
  width: 134px;
  height: 117px;
  font-size: 16px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
.upload-file-wrapper {
  display: flex;
  min-height: 70px;
}
.investorDetails .upload-file-wrapper {
  flex-direction: column;
}

.upload-file-wrapper input[type="file"] {
  width: 300px;
  height: 64px;
  font-size: 16px;
  position: absolute;
  left: 0;
  top: 30px;
  opacity: 0;
}
.investorDetails .upload-file-wrapper input[type="file"] {
  width: 30px;
  height: 40px;
  right: 8px;
  top: 15px;
  left: initial;
}
.investorDetails .upload-file-wrapper {
  min-height: initial;
}
.upload-img-btn,
.upload-file-btn {
  background-color: #f7f7f7;
  border: none;
}
.upload-btn-wrapper {
  display: flex;
  align-items: flex-start;
  gap: 15px;
  margin-top: 30px;
}
.investorDetails .label {
  font-size: 16px;
  font-weight: 500;
  color: rgba(105, 111, 121, 1);
  font-family: "Karla", sans-serif;
  /* margin-bottom: 20px; */
  line-height: 18px;
  text-wrap: nowrap;
}

.investorDetails .input {
  min-height: 64px;
  min-width: 100%;
  font-size: 14px;
  background: none;
  border: 1px solid rgba(134, 146, 166, 1);
  background-color: #fff;
  border-radius: 6px;
  padding: 20px;
  font-family: "Karla", sans-serif;
}

.investorDetails .input:focus-within {
  border: 2px solid #0b6bcb;
}

.css-9wr34-JoyInput-root:focus-within::before {
  --Input-focused: 0 !important;
  /* border: none; */
}

.investorDetails .react-select-input {
  background-color: #fff;
}
.investorDetails .large-input,
.investor-account-box .large-input {
  width: 100% !important;
  font-size: 12px;
  font-weight: 400;
  background: none;
  background-color: #fff;
  border-radius: 6px;
  padding: 20px;
  font-family: "Karla", sans-serif !important;
  border: 1px solid rgba(134, 146, 166, 1) !important;
}
.companyDetails-business-desc-div
  .css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root,
.companyDetails-business-desc-div .css-1hof3tc,
.account-biography .css-1hof3tc,
.account-biography .css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root {
  width: 100% !important;
  height: 100% !important;
  font-family: "Karla", sans-serif;
  padding: 0px !important;
}
.business-desc-form-control {
  width: 95% !important;
}
@media all and (max-width: 991px) {
  .business-desc-form-control {
    width: 100% !important;
  }
}
.investorDetails .editable-row {
  padding: 0px;
}
.investorDetails .table-details {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}
.investorDetails .table-details .cQvWHw {
  font-size: 16px;
  font-family: "Karla";
}

.investorDetails .investmentDetails-table {
  margin-top: 30px;
}
.table-edit-buttons {
  margin-top: 20px;
}
.investorDetailsPage .next-buttons {
  display: flex;
  display: flex;
  align-items: center;
  gap: 30px;
}

.second-step .investorDetails-first-section {
  padding-bottom: 20px;
}

.investorDetails .editable-header .editable-cell {
  padding: 10px;
}

.investorDetails .investmentDetails-table {
  margin-top: 30px;
}
.table-edit-buttons {
  margin-top: 20px;
}
.investorDetailsPage .next-buttons {
  display: flex;
  display: flex;
  align-items: center;
  gap: 30px;
}

.investorDetailsPage .second-step,
.investorDetailsPage .third-step,
.investorDetailsPage .fourth-step,
.investorDetailsPage .fifth-step,
.investorDetailsPage .tenth-step {
  display: none;
}
.second-step .investorDetails-first-section {
  padding-bottom: 20px;
}
.investorDetails .investment-team-member-addition {
  border-top: 1px dashed #000;
}
.investorDetails .add-member-div {
  display: flex;
  justify-content: space-between;
}
@media all and (max-width: 991px) {
  .investorDetails .add-member-div {
    position: absolute;
    right: 20px;
    top: 175px;
  }
}
.investorDetails .delete-button-div {
  display: flex;
  justify-content: space-between;
}
.delete-section {
  border-radius: 6px;
  border: 1px solid #d32f2f;
  background: #fff;
  height: fit-content;
  width: fit-content;
  padding: 10px 15px;
  color: #d32f2f;
  text-align: center;
  font-family: Karla;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
  margin-top: 20px;
  cursor: pointer;
}


/* .investorDetailsPage .dialog-box{
    width: 500px !important;
  } */

.investorDetailsPage .sixth .div-sixth {
  display: flex;
  width: 676px;
  max-width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 97px auto;
}

.investorDetailsPage .sixth .sixth-img {
  aspect-ratio: 1.67;
  object-fit: auto;
  object-position: center;
  width: 399px;
  max-width: 100%;
}
.investorDetailsPage .sixth .text-3 {
  align-self: stretch;
  color: #000;
  margin-top: 21px;
  font: 500 18px/156% Roboto, -apple-system, Roboto, Helvetica, sans-serif;
  text-align: center;
}

.investorDetailsPage .sixth .text-4 {
  color: rgba(102, 112, 133, 1);
  margin-top: 8px;
  width: 488px;
  font: 400 14px/20px Karla, -apple-system, Roboto, Helvetica, sans-serif;
  text-align: center;
}
.investorDetailsPage .industry-focus-input {
  width: 50% !important;
}
.css-1som0au-control {
  height: fit-content !important;
}
.mobile-div {
  position: fixed;
  display: none;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  z-index: 6;
  background-color: #1a3847;
}
.mobile-div-2 {
  background-color: #1a3847;
  display: flex;
  width: 100%;
  padding: 16px 0px;
  flex-direction: column;
}
.mobile-div-3 {
  align-self: center;
  display: flex;
  width: 100%;
  max-width: 426px;
  align-items: center;
  gap: 20px;
  font-weight: 500;
  padding: 0 20px;
}
.mobile-div-4 {
  color: #4ef79a;
  letter-spacing: 0.48px;
  align-self: stretch;
  margin: auto 0;
  font: 24px Karla, sans-serif;
}
.mobile-div-5 {
  color: #fff;
  letter-spacing: 0.4px;
  align-self: stretch;
  flex-grow: 1;
  flex-basis: auto;
  margin: auto 0;
  font: 20px Karla, sans-serif;
}
.mobile-div-6 {
  border-radius: 5px;
  background-color: rgba(23, 117, 213, 0.3);
  align-self: stretch;
  color: #00a6fb;
  letter-spacing: 0.28px;
  justify-content: center;
  padding: 9px;
  font: 14px Karla, sans-serif;
}
.mobile-div-7 {
  background-color: #fff;
  display: flex;
  margin-top: 16px;
  width: 100%;
  flex-direction: column;
  justify-content: center;
}
.mobile-div-8 {
  border-color: #00a6fb;
  border-style: solid;
  border-width: 3px;
  background-color: #00a6fb;
  min-height: 6px;
  width: 100%;
}
.investorDetails .mobile-div-text {
  color: #fff;
  font-family: Roboto;
  font-size: 22px;
  margin: 20px 0px 10px 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.44px;
}
.first-step .mobile-div-8 {
  width: 1%;
}

.first-step .progress-div {
  width: 15%;
}

.second-step .mobile-div-8 {
  width: 15%;
}

.second-step .progress-div {
  width: 30%;
}

.third-step .mobile-div-8 {
  width: 30%;
}

.third-step .progress-div {
  width: 45%;
}

.fourth-step .mobile-div-8 {
  width: 45%;
}

.fourth-step .progress-div {
  width: 60%;
}

.fifth-step .mobile-div-8 {
  width: 6cm;
}

.fifth-step .progress-div {
  width: 75%;
}
.sixth-step .mobile-div-8 {
  width: 75%;
}
.sixth-step .progress-div {
  width: 75%;
}
.seventh-step .mobile-div-8 {
  width: 90%;
}
.seventh-step .progress-div {
  width: 90%;
}
.eighth-step .mobile-div-8 {
  width: 100%;
}
.eigth-step .progress-div {
  width: 100%;
  border-color: #00cd9e;
}
.one-input-div {
  width: 67%;
}
.investorDetails .companyReview-box,
.InvestorSide .companyReview-box {
  display: flex;
  gap: 20px;
}
.investorDetails .companyReview-companyName,
.InvestorSide .companyReview-companyName {
  font-size: 24px;
  font-weight: 600;
}
.investorDetails .companyReview-comapnyDesc,
.InvestorSide .companyReview-comapnyDesc {
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  color: #696f79;
}
.fileUploads {
  justify-content: space-between;
  align-items: center;
  border-radius: 6px;
  border-color: rgba(202, 202, 202, 1);
  border-style: solid;
  border-width: 1px;
  background-color: #fff;
  display: flex;
  gap: 12px;
  padding: 12px 16px;
  width: 100%;
}
.investorDetails .fileUploads {
  margin-top: 5px;
}
.fileUploads-div-2 {
  display: flex;
  flex-direction: column;
}
.fileUploads-div-3 {
  color: #00a6fb;
  text-decoration-line: underline;
  font: 500 14px/143% Karla, -apple-system, Roboto, Helvetica, sans-serif;
  text-wrap: wrap;
}
.fileUploads-div-4 {
  color: #989692;
  font: 400 12px/167% Karla, -apple-system, Roboto, Helvetica, sans-serif;
}
.FileImg {
  height: 20px;
}
.fileUploads-img {
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 20px;
  margin: auto 0;
  z-index: 0;
}

.edit-img-btn {
  position: relative;
  top: -85px;
  cursor: pointer;
  margin-left: 10px;
}
.editImg {
  display: initial;
  vertical-align: initial;
  width: 65px;
}
.investorDetails .companyReview-stats,
.InvestorSide .companyReview-stats {
  display: flex;
  width: 100%;
  gap: 50px;
  margin-top: 30px;
  flex-wrap: wrap;
}

.investorDetails .companyReview-group,
.InvestorSide .companyReview-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.InvestorSide .companyReview-group {
  width: 200px;
}
.InvestorSide .companyReview-group-2 {
  width: 160px;
}
.companyReview-group .group-heading {
  font-weight: 500;
  font-size: 22px;
}
.companyReview-group .group-subheading {
  font-size: 16px;
  color: #696f79;
}
.team-profiles-box,
.Documents-box {
  margin-top: 20px;
  display: flex;
  gap: 30px;
  align-items: flex-start;
}
.Documents-box {
  flex-wrap: wrap;
}
.team-profiles-box {
  flex-direction: row;
  flex-wrap: wrap;
}
.team-profile-div {
  border-radius: 6px;
  border-color: rgba(202, 202, 202, 1);
  border-style: solid;
  border-width: 1px;
  background-color: #fff;
  display: flex;
  gap: 13px;
  color: #696f79;
  line-height: 150%;
  padding: 8px;
  width: 267px;
}
@media all and (max-width: 991px) {
  .team-profile-div {
    min-width: 90%;
  }
}
.team-profile-img {
  aspect-ratio: 0.84;
  object-fit: conver;
  object-position: center;
  width: 86px;
  align-self: start;
  height: 100px;
  border-radius: 10px;
}
.team-profile-div-2 {
  display: flex;
  flex-direction: column;
}
.team-profile-div-3 {
  color: #00a6fb;
  text-decoration-line: underline;
  font: 700 16px Karla, sans-serif;
}
.team-profile-div-4 {
  font: 500 14px Karla, sans-serif;
  margin-top: 5px;
}
.team-profile-div-5 {
  margin-top: 8px;
  font: 400 12px/18px Karla, -apple-system, Roboto, Helvetica, sans-serif;
}
.goLive-heading {
  font-size: 20px;
  font-weight: 700;
  color: #00a6fb;
  text-align: center;
}
.goLive-text {
  text-align: center;
  font-size: 16px;
  color: #667085;
}
.goLive-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  width: 500px;
  margin: auto;
  margin-top: 20px;
}
.eighth-step-div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.eighth-step-heading {
  font-weight: 600;
  font-size: 18px;
  margin-top: 40px;
}
.eighth-step-subheading {
  font-size: 14px;
  color: #667085;
  margin: 15px 0px 40px 0px;
  text-align: center;
  width: 85%;
}

.investorDetails-last-row-input {
  width: calc(33% - 45px);
}
.bold {
  font-weight: 600;
}
.invite-members-full-div {
  margin-top: 30px;
}
.normal-small-text {
  color: #696f79;
  font-size: 16px;
  font-weight: 500;
}
.invited-members-div {
  margin-top: 20px;
  padding: 20px;
  border-radius: 20px;
  background-color: #fff;
  width: 100%;
}
.invited-email-div {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  margin-top: 20px;
  margin-right: 20px;
  width: 45%;
}
.invited-email-div-2 {
  display: flex;
  flex-direction: column;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  padding: 0 20px 0px 0px;
  width: 70%;
}
.invited-email-div-3 {
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font: 700 16px/112.5% Karla, -apple-system, Roboto, Helvetica, sans-serif;
}
.invited-email-div-4 {
  color: #8692a6;
  font-feature-settings: "clig" off, "liga" off;
  margin-top: 4px;
  font: 500 14px Karla, sans-serif;
}
.invited-email-div-5 {
  display: flex;
  gap: 16px;
  font-size: 14px;
  color: #f4bd50;
  font-weight: 700;
  white-space: nowrap;
  text-align: center;
  letter-spacing: 0.17px;
  line-height: 150%;
  width: 30%;
}
.invited-email-div-6 {
  font-family: Karla, sans-serif;
  justify-content: center;
  border-radius: 8px;
  box-shadow: 0px 4px 40px 0px rgba(0, 166, 251, 0.04);
  padding: 11px 12px;
  font-weight: 700;
  color: #f4bd50;
  background-color: #fef9ee;
}
.invited-email-div-5 .invited-email-img {
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 24px;
  margin: auto 0;
}
.all-invited-email-div {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.fileLink {
  z-index: 0;
}

.active-email-div {
  font-family: Karla, sans-serif;
  justify-content: center;
  border-radius: 8px;
  box-shadow: 0px 4px 40px 0px rgba(0, 166, 251, 0.04);
  padding: 11px 12px;
  font-weight: 700;
  color: #00a6fb;
  background-color: #e5f5fe;
  /* height: fit-content; */
}
/* phone input */
.css-1ixds2g {
  font-size: 14px !important;
  font-family: "Karla", sans-serif !important;
}
.OrgId {
  color: #00a6fb !important;
  background-color: #e5f5fe !important;
  font-weight: 500 !important;
  cursor: text !important;
}
.css-1jqq78o-placeholder {
  color: #000 !important;
}
.exploreDeals {
  background-color: #00a6fb;
  color: #fff;
  border: 0px;
  border-radius: 10px;
  padding: 15px 25px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 20px;
}
.exploreDeals img {
  height: 18px;
  width: 18px;
}
.invited-investment-team {
  background-color: #fff;
  padding: 20px;
  margin-top: 20px;
  border-radius: 20px;
  width: 95%;
}
@media all and (max-width: 991px) {
  .invited-investment-team {
    width: 100%;
  }
}
.member-form {
  border-bottom: 1px dashed #8692a6;
  padding-bottom: 20px;
}
.investorRevoked-screen {
  /* background-image: url("../../Assets/Images/signup/investorRevoked.svg"); */
  background-color: #f6f8ff;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.investorRevoked-fundrev-logo {
  position: fixed;
  top: 20px;
  left: 20px;
}
.invalid-invite-div {
  border-radius: 34px;
  box-shadow: 0px 5px 16px 0px rgba(8, 15, 52, 0.06);
  border-color: rgba(239, 240, 246, 1);
  border-style: solid;
  border-width: 1px;
  background-color: #fff;
  display: flex;
  width: 698px;
  max-width: 90%;
  align-items: center;
  font-size: 16px;
  text-align: center;
  justify-content: center;
  padding: 36px 60px;
}

.invalid-invite-div-2 {
  display: flex;
  width: 387px;
  max-width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}
.invalid-invite-div-3 {
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font: 500 24px/146% Roboto, -apple-system, Roboto, Helvetica, sans-serif;
}
.invalid-invite-div-4 {
  color: #8692a6;
  font-family: Karla, sans-serif;
  font-weight: 400;
  line-height: 28px;
  align-self: stretch;
}

.invalid-invite-div-5 {
  font-family: Karla, sans-serif;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background-color: #00a6fb;
  margin-top: 27px;
  width: 308px;
  max-width: 100%;
  color: #fff;
  font-weight: 500;
  padding: 22px 60px;
  border: 0px;
  font-size: 16px;
  cursor: pointer;
}
.mobile-stats {
  display: none !important;
}
@media all and (max-width: 1400px) {
  .companyDetailsPage .uploadFilebtn {
    width: 250px;
  }
  .companyReview-group .group-heading {
    font-size: 20px;
  }
}
@media all and (min-width: 1400px) {
  .investorDetails {
    width: 1400px;
    margin-left: calc(50vw - 700px);
  }
}
@media all and (max-width: 991px) {
  .investorDetails-heading {
    display: none;
  }
  .investorDetails-heading-visible {
    font-size: 22px;
  }
  .details-section {
    margin-top: 60px;
  }
  .companyDetailsPage .details-section {
    margin-top: 30px;
  }
  .second-details-section {
    margin-top: 25px !important;
  }
  .investorDetailsPage {
    background-color: #fff;
  }
  .investorDetails .input-section {
    width: 100% !important;
  }
  .investorDetails {
    margin-top: 70px;
    flex-direction: column;
  }
  .investorDetails .div {
    flex-direction: row;
    width: 100%;
    display: none;
  }
  .investorDetails .input-div {
    flex-direction: column;
  }
  .investorDetails .investorDetails-row-input {
    width: 90%;
  }
  .investorDetails .investorDetails-last-row-input {
    width: 90%;
  }
  .investorDetails .editable-cell {
    width: initial !important;
  }
  .companyDetailsPage .investorDetails .editable-cell {
    width: 100px !important;
  }
  .eTMTpx {
    width: 330px;
  }
  .investorDetails .navigation-buttons {
    width: 90%;
  }
  .investorDetails {
    margin-left: 0px;
  }
  .previousStep {
    width: 130px;
    height: 40px;
    font-size: 14px;
  }
  .nextStep {
    width: 120px !important;
    height: 40px !important;
    font-size: 14px !important;
  }
  .investorDetailsPage .skip-button {
    padding: 18px 0px;
    font-size: 14px;
    height: 40px;
  }
  .investorDetails .third-step-first-row,
  .investorDetails .third-step-second-row,
  .investorDetails .third-step-third-row,
  .investorDetails .industry-focus-first-row {
    flex-direction: column;
    margin-top: 0px;
  }
  .investorDetails .investorDetails-first-section {
    padding: 10px;
  }
  .companyDetailsPage .investorDetails-first-section {
    padding: 30px 10px;
  }
  .investorDetails .ticket-size-text-div {
    width: 100%;
  }
  .investorDetails .stage-of-investment-input {
    margin-top: 20px;
    width: calc(100% - 50px);
  }
  .investorDetails .other-criteria {
    margin-top: 20px;
    width: calc(100% - 50px);
    padding-left: 0px;
  }
  .ticket-size-text-div,
  .investorDetails .coinvestment-div,
  .companyDetailsPage .investorDetails-first-section {
    margin-top: 20px;
  }
  .investorDetailsPage .industry-focus-input {
    width: 100% !important;
  }
  .investorDetails .investorDetails-first-section {
    width: 100%;
  }
  .fifth-step .investorDetails-first-section {
    padding: 20px;
  }
  .investorDetailsPage .sixth .text-4 {
    width: 75%;
  }
  .mobile-div {
    display: flex;
  }
  .investorDetailsPage .cm-menu-inner {
    margin-top: 0px;
  }
  .one-input-div {
    width: 100%;
  }
  .investorDetails .companyReview-comapnyDesc {
    font-size: 14px;
  }
  .companyReview-companyName {
    font-size: 18px;
    margin-bottom: 10px;
  }
  .companyReview-group {
    width: 40%;
  }
  .team-profile-img {
    height: 100px;
  }
  .fileUploads {
    width: 100%;
    justify-content: left;
  }
  .investorDetails .fileUploads {
    justify-content: space-between;
  }
  /* .fileUploads img {
    margin-right: 10px;
  } */
  .investorDetailsPage .landing-header {
    height: 70px;
  }
  .invited-email-div {
    width: 100%;
    flex-wrap: wrap;
  }
  .edit-img-btn {
    left: 100px;
  }
  .investorDetails .upload-btn-wrapper {
    margin-top: 0px;
  }
  .founding-member {
    align-items: flex-start !important;
  }
}
@media all and (max-width: 479px) {
  .investorDetails .ticket-size-div {
    width: 43%;
  }
  .upload-img-btn {
    width: 100%;
  }
  .investor-logo-btn {
    margin-top: 30px;
  }
  .investorDetails .navigation-buttons {
    width: 95%;
    margin: 25px 10px;
  }
  .investorDetails .investorDetails-row-input,
  .investorDetails-row-large-input,
  .investorDetails-last-row-input {
    width: 100% !important;
    margin-top: 20px;
  }
  .investorDetails .input-div {
    margin-top: 0px;
  }
  .investorDetails .investorDetails-first-section {
    padding: 30px 10px;
  }
  .sixth-step .investorDetails-first-section {
    padding: 20px;
  }
  .investorDetailsPage .group {
    width: 100%;
    position: static;
    background-color: #fff;
  }
  .investorDetailsPage .overlap-group {
    margin-top: 15px;
    background-color: #00a6fb;
    height: 35px;
    width: 105px;
  }
  .Header .text-wrapper-4 {
    top: 8px;
    color: #fff;
    left: 40px;
    font-size: 14px;
  }
  .Header .material-symbols {
    top: 5px;
    color: #fff;
    left: 15px;
  }
  .investorDetailsPage .next-buttons {
    gap: 10px;
  }
  .third-step-first-row .investorDetails-row-input,
  .third-step-second-row .investorDetails-row-input {
    margin-top: 0px;
  }
  .investorDetails .stage-of-investment-input {
    width: 100%;
  }
  .investorDetails .yes-or-no-button-div,
  .investorDetails .coinvestment-div {
    width: 100%;
  }
  .investorDetails .yes-or-no-button {
    height: 55px;
  }
  .invite-members-div {
    top: 220px;
    right: 20px;
  }
  .upload-btn-wrapper input[type="file"] {
    width: 106px;
    height: 90px;
  }
  .investorDetails .InvestorLogo {
    width: 106px;
    height: 90px;
    border-radius: 10px;
  }
  .investorDetails .table-details {
    overflow-x: auto;
  }
  .companyDetailsPage .uploadFilebtn {
    width: 100%;
    margin-top: 5px;
  }
  .mobile-stats {
    display: flex !important;
  }
  .laptop-stats {
    display: none !important;
  }
  .companyReview-group .group-subheading {
    font-size: 14px;
  }
  .goLive-section {
    width: 80%;
    height: 60vh;
  }
  .goLive-heading {
    font-size: 18px;
  }
  .goLive-text {
    font-size: 14px;
  }
}

.founding-member {
  display: flex;
  align-items: center;
  margin: 16px 0;
  justify-content: space-between;
}

.member-actions {
  display: flex;
  gap: 16px;
}

.team-deal-div-2 {
  display: flex;
  flex-direction: column;
  /* justify-content: space-evenly; */
  gap: 4px;
}

.team-deal-div-3 {
  color: #002132;
  /* text-decoration-line: underline; */
  font: 700 16px Karla, sans-serif;
}

.team-deal-div-4 {
  font: 500 14px Karla, sans-serif;
}

.team-deal-div-5 {
  font-family: Karla;
  font-size: 12px;
  font-weight: 400;
  color: #696f79;
}

.deal-team-span {
  color: #3c5168;
}
.textfield-input {
  width: 100%;
}
.investment-thesis-form-control {
  width: 100%;
  margin-right: 50px;
  width: 612.66px;
  font-family: "Karla", sans-serif !important;
}
.key-investment-form-control {
  width: 100%;
  margin-right: 50px;
  width: 279.33px;
  border: 0px !important;
}
@media all and (max-width: 991px) {
  .investment-thesis-form-control,
  .key-investment-form-control {
    width: 100%;
    margin-top: 10px;
  }
  .investorDetails .random-input {
    display: none;
  }
}

.key-investment-input {
  font-size: 12px;
  font-weight: 400;
  background: none;
  background-color: #fff;
  border-radius: 6px;
  padding: 20px;
  font-family: "Karla", sans-serif !important;
}
.key-investment-input2 {
  font-size: 12px;
  font-weight: 400;
  background: none;
  background-color: #fff;
  border-radius: 6px;
  padding: 20px;
  font-family: "Karla", sans-serif !important;
  border: 1px solid rgba(134, 146, 166, 1) !important;
}
/* Textfield inputs */
.investorDetails .css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root,
.investorDetails .css-u36398,
.account-biography .css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root {
  font-family: "Karla", sans-serif !important;
  font-size: 14px;
  border: 0px !important;
}
/* .investorDetails .css-1d3z3hw-MuiOutlinedInput-notchedOutline,
.investorDetails .css-igs3ac {
  border: 0px !important;
} */

.investor-profile-fit-input {
  width: 48%;
}
.investor-profile-industry-focus {
  width: 90%;
  padding-right: 50px;
}
.investor-profile-row {
  display: flex;
  justify-content: space-between;
}
.NDA-point {
  margin-top: 10px;
}
.NDA-heading {
  margin-bottom: 30px !important;
}
.multiple-file-uploads {
  display: flex;
  flex-wrap: wrap;
}
.stay-tuned .InvestorSide-content {
  padding: 0px 20px;
}
.stay-tuned .img-box {
  margin-top: 80px !important;
}
.upload-file-div-download {
  display: flex;
  flex-direction: row !important;
  align-items: baseline;
  height: fit-content;
  gap: 10px;
  margin-top: 10px;
  width: 100%;
  justify-content: space-between;
  border: 1px dashed #8692a6;
  padding: 10px 10px 5px 10px;
  border-radius: 6px;
}
.upload-file-div-download .label {
  margin-top: 3px;
}
.tooltip-img {
  padding: 0px;
}
.upload-file-div-download .upload-img-btn {
  margin-top: 5px;
}
.pitch-review-files {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
@media all and (max-width: 991px) {
  .random-input {
    display: none;
  }
  .pof-div {
    margin-top: 20px !important;
  }
  .founding-member {
    height: 80px;
  }
}
ol.custom-counter {
  counter-reset: section;
}

ol.custom-counter > li {
  list-style: none;
  counter-increment: section;
}

ol.custom-counter > li:before {
  content: counters(section, ".") " ";
  font-weight: bold;
}

ol.custom-counter > li > ol {
  counter-reset: sub-section;
}

ol.custom-counter > li > ol > li {
  list-style: none;
  counter-increment: sub-section;
}

ol.custom-counter > li > ol > li:before {
  content: counters(section, ".") "." counter(sub-section) " ";
  font-weight: bold;
}
.indented-info {
  margin-left: 20px;
}
.red-bin {
  color: rgba(211, 47, 47, 1);
  width: 24px;
  cursor: pointer;
}
@media all and (max-width: 991px) {
  .member-input-section {
    position: relative;
    bottom: 40px;
  }
  .member-input-section .companyDetails-business-desc-div {
    margin-top: 20px;
  }
}
.tick {
  height: 10px;
}
.next {
  padding-top: 3px;
  width: 14px;
}
.investorDetails .previous {
  width: 14px;
}
.invite-members-div img,
.post-question-btn img,
.upload-img-btn img,
.add-row img,
.reqEdit {
  width: 20px;
}
.send-invite img {
  width: 15px;
}
.delete-row img {
  width: 25px;
}
/* Delete button cell */
.editable-row .tpSxj {
  height: 22px;
}
.small-tag {
  text-wrap: wrap;
  color: var(--Primary-Text-Color);
  background: var(--light-blue-color);
  padding: 2px 20px;
  font-weight: 600;
  border-radius: 4px;
  font-size: 16px;
}
