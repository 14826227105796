.div10 {
  align-self: stretch;
  border-radius: 24px;
  background-color: rgba(0, 205, 158, 1);
  min-height: 32px;
  gap: 8px;
  width: 32px;
  height: 32px;
  padding: 0 2px;
}
.div {
  display: flex;
  max-width: 216px;
  min-width: 210px;
  flex-direction: column;
  font-family: Karla, sans-serif;
  font-weight: 400;
  position: absolute;
  top: 0;
  z-index: 3;
  display: flex;
  flex-direction: column;
  height: 100%;
  z-index: 20;
}
.div11 {
  display: flex;
  width: 70px;
  flex-direction: column;
  font-family: Karla, sans-serif;
  font-weight: 400;
  position: absolute;
  top: 0;
  z-index: 3;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.background {
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 4px 4px 12px 4px rgba(26, 26, 26, 0.04);
  display: flex;
  width: 100%;
  flex-direction: column;
  height: 100%;
}
.div2 {
  display: flex;
  margin-bottom: -88px;
  width: 100%;
  flex-direction: column;
  justify-content: start;
}
.div3 {
  align-items: center;
  border-right: 1px solid var(--Brand, #114fee);
  background-color: #d2dcff;
  display: flex;
  min-height: 64px;
  width: 100%;
  overflow: hidden;
  font-size: 16px;
  color: var(--Primary-Text, #1a1a1a);
  font-weight: 600;
  justify-content: start;
  padding: 23px 16px;
  gap: 20px;
}
.text {
  text-wrap: nowrap;
  gap: 12px;
  margin: auto 0;
  display: flex;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.div4 {
  background-color: rgba(255, 255, 255, 1);
  border-color: rgba(216, 219, 233, 1);
  border-bottom-width: 1px;
  display: flex;
  min-height: 74px;
  width: 100%;
  align-items: center;
  overflow: hidden;
  font-size: 16px;
  color: var(--Secondary-Text, #696f79);
  justify-content: space-between;
  padding: 20px 16px;
}
/* .div11 .div4,
.div11 .div3,
.div11 .div5 {
  justify-content: center;
} */
.img {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 24px;
  align-self: stretch;
  margin: auto 0;
}
.div5 {
  background-color: rgba(255, 255, 255, 1);
  border-color: rgba(216, 219, 233, 1);
  border-bottom-width: 1px;
  display: flex;
  min-height: 64px;
  width: 100%;
  gap: 20px;
  align-items: center;
  overflow: hidden;
  font-size: 16px;
  color: var(--Secondary-Text, #696f79);
  white-space: nowrap;
  justify-content: start;
  padding: 9px 16px;
  justify-content: space-between;
  cursor: pointer;
}
.img2 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 46px;
  align-self: stretch;
  margin: auto 0;
}
.div6 {
  background-color: rgba(255, 255, 255, 1);
  border-color: rgba(216, 219, 233, 1);
  border-bottom-width: 1px;
  display: flex;
  min-height: 64px;
  width: 100%;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  justify-content: start;
  padding: 9px 16px;
}
.text2 {
  align-self: stretch;
  gap: 12px;
  font-size: 16px;
  color: var(--Secondary-Text, #696f79);
  margin: auto 0;
}
.div9 {
  background-color: rgba(255, 255, 255, 1);
  border-color: rgba(216, 219, 233, 1);
  border-bottom-width: 1px;
  display: flex;
  min-height: 64px;
  width: 100%;
  align-items: center;
  gap: 9px;
  overflow: hidden;
  font-size: 16px;
  color: var(--Secondary-Text, #696f79);
  justify-content: start;
  padding: 23px 16px;
}
.innerContent {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
}

.innerContent.open {
  max-height: 500px; /* Adjust based on the height of the content */
}

.innerContent.closed {
  max-height: 0;
}

.img {
  transition: transform 0.3s ease;
}

.rotate {
  transform: rotate(180deg); /* Rotate the arrow when opened */
}
