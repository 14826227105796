/* Keep in account that it can changes css across all pages */
.fundrev-heading {
  color: var(--Primary-Text-Color);
  font-family: Roboto;
  font-size: 40px;
  font-weight: 300;
}
.fundrev-formlabel {
  color: var(--Primary-Text-Color);
  font-size: 16px;
  font-weight: 600;
}
.fundrev-subheading {
  color: var(--Secondary-Text, #696f79);
  font-size: 16px;
  font-weight: 400;
}
.phone-design {
  display: none !important;
}
.fundrev-card {
  border-radius: 4px;
  background-color: #fff;
  box-shadow: var(--whiteBg-back-shadow);
  padding: 16px 16px 24px 16px;
  gap: 24px;
}
.fundrev-card-heading {
  color: var(--Secondary-Text-Color);
  font-family: Roboto;
  font-size: 20px;
  font-weight: 300;
}
.fundrev-chip {
  border-radius: 6px;
  width: fit-content;
  padding: 10px 15px;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: 500;
  cursor: text;
}
.blue-chip {
  border: 1px solid var(--blue-color);
  color: var(--blue-color);
  background-color: #e5f5fe;
}
.success-chip {
  border: 1px solid var(--dark-green-color);
  color: var(--dark-green-color);
}
.pending-chip {
  border: 1px solid orange;
  color: orange;
}
@media all and (max-width: 576px) {
  .fundrev-heading {
    font-size: 28px;
  }
  .random-input {
    display: none !important;
  }
  .laptop-design {
    display: none !important;
  }
  .phone-design {
    display: flex !important;
  }
}
