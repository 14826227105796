.investorRegisterWithSignUpText {
  width: 60%;
  display: flex;
  flex-direction: column;
}
.signInWithHeader {
  width: 100%;
}
.full-width-input {
  width: 100% !important;
  max-width: 600px;
  margin-top: 20px;
}
.investorRegister .forget-your-password {
  margin-top: 15px;
  color: #8692a6;
  text-align: right;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
  cursor: pointer;
}
.investorRegister .sign-in-btn {
  margin-top: 30px;
}
.tabs-div {
  border-radius: 8px;
  background-color: #ecf0ff;
  display: flex;
  max-width: 443px;
  gap: 0px;
  font-size: 16px;
  white-space: nowrap;
  text-align: center;
  cursor: pointer;
}
.tabs-div-2 {
  font-family: Karla, sans-serif;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: #e5f5fe;
  margin: 4px 4px 4px 0px;
  color: #8692a6;
  font-weight: 700;
  flex: 1;
  padding: 8px 20px;
}
.tabs-div-3 {
  font-family: Karla, sans-serif;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: #e5f5fe;
  margin: 4px 4px 4px 0px;
  color: #8692a6;
  font-weight: 400;
  flex: 1;
  padding: 8px 20px;
}
.active-tab {
  background-color: #00a6fb;
  color: #fff;
}
.signIn-div .text,
.signUp-div .text {
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  font-family: "Karla", sans-serif !important;
  color: rgba(134, 146, 166, 1);
  margin: auto;
  margin-top: 20px;
  margin-bottom: 0px;
  /* position: absolute; */
  /* bottom: 20px;
    right: calc(25% - 130px);
    margin-top: 100px; */
}
.code-container {
  width: 80%;
  margin: auto;
}
.code-character {
  background: transparent !important;
  border: 1px solid rgba(134, 146, 166, 1) !important;
  height: 55px !important;
  width: 55px !important;
  border-radius: 6px;
  font-size: 28px !important;
}
.code-time {
  padding: 20px 30px;
  background-color: rgba(246, 246, 246, 1);
  color: rgba(107, 114, 128, 1);
  border-radius: 6px;
  width: 150px;
  height: 65px;
  text-align: center;
  margin-left: 20px !important;
}
.time-up-send-again-box {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.proceed-btn {
  background-color: #00a6fb !important;
  height: 50px;
  margin-top: 20px !important;
  color: #fff !important;
}
.proceed-btn:hover {
  background-color: #185ea5 !important;
}
.dialog-title {
  color: #000;
  text-align: center;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 35px;
  margin-top: 20px !important;
}
.dialogcontent-forget-password {
  padding-top: 0px !important;
}
.email-address-input {
  height: 64px;
  min-width: 100% !important;
  font-size: 14px !important;
  background: none !important;
  border: 1px solid rgba(134, 146, 166, 1) !important;
  border-radius: 6px !important;
  padding: 20px !important;
  font-family: "Karla", sans-serif !important;
}
.dialog-box-enter-email-text {
  color: #8692a6;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}
.email-address-label {
  color: #696f79 !important;
  font-size: 16px !important;
  font-weight: 500;
}
.password-reset-success-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  margin: 50px 10px;
}
.password-reset-success-box img {
  width: 157px;
}
.password-reset-success-box p {
  color: #000;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  line-height: 35px;
}
.confirm-email-btn {
  border: 0px;
  border-radius: 10px;
  color: #fff;
  background-color: #00a6fb;
  margin-top: 5px !important;
  padding: 7px 15px;
  cursor: pointer;
}
.password-label {
  color: #696f79 !important;
}
.signIn-dialogcontenttext {
  width: 80%;
  margin: auto !important;
}
.signUp-div .textContent {
  text-align: center;
}
@media all and (min-width: 1400px) {
  /* .signIn-div .text,
  .signUp-div .text {
     width: 70%; 
  } */
  .signIn-div .textContent {
    max-width: 600px;
    text-align: center;
  }
  .signUp-div .textContent {
    /* max-width: 600px; */
    text-align: center;
  }
}
@media all and (max-width: 991px) {
  .signIn-div .text,
  .signUp-div .text {
    top: initial;
    width: 90%;
    text-align: center;
    padding: 20px 0px;
  }
  .signIn-div .form {
    margin-top: 40px;
  }
  .signIn-div .form-control {
    margin-bottom: 0px;
    margin-top: 35px;
  }
  .dialogcontent {
    padding: 10px;
  }
  .dialog-box-enter-email-text {
    font-size: 14px;
  }
  .dialog-title {
    font-size: 16px;
  }
  .investorRegisterWithSignUpText {
    width: 100%;
  }
  .signIn-dialogcontenttext {
    width: 100%;
  }
}
