.setInnerHTML ol {
  list-style-type: auto;
}
.setInnerHTML ul {
  list-style-type: disc;
}
.setInnerHTML li {
  margin-bottom: 8px;
}
.setInnerHTML a {
  color: #00a6fb;
}
.setInnerHTML a:hover {
  text-decoration: underline;
}
.setInnerHTML h1 {
  font-size: 24px !important;
}
.setInnerHTML h2 {
  font-size: 20px !important;
}
.setInnerHTML h3 {
  font-size: 18px !important;
}
.ql-snow .ql-tooltip {
  transform: translate(120px, 10px);
  z-index: 100;
}
.setInnerHTML {
  padding: 0px;
  font-size: 14px;
  line-height: 1.6;
}
.ql-editor ol,
.ql-editor ul {
  padding: 0px;
}
