/* 
  don't remove */

.box {
  background-color: #fff;
  border-radius: 5px;
  width: 100%;
}
.wrapper {
  width: 45%;
}
.validated,
.not-validated {
  color: #696f79 !important;
  font-size: 12px;
  font-weight: 400;
  font-family: "Karla-Medium", Helvetica;
}
.wrapper .heading {
  color: #000;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0px;
  font-family: "Karla-Medium", Helvetica;
}
.input-with-icon-div {
  display: flex;
}

.custom-input {
  width: 95%;
  border: none;
  outline: none;
}

.icon-span {
  position: absolute;
  cursor: pointer;
  right: 20px;
  /* top: 20px; */
  bottom: 23px;
}

.tracker-box {
  background-color: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  font-size: 14px;
  color: #000;
  letter-spacing: 0.09em;
  padding: 15px;
  border-radius: 5px;
  margin-top: 5px;
  transition: max-height 0.3s ease;
  position: absolute;
  z-index: 20;
  padding: 10px 20px;
}
.focused .tracker-box {
  max-height: 100px; /* Adjust the height as per your requirement */
}
.tracker-box div {
  margin: 5px 0;
}

.tracker-box .validated {
  color: #000;
}

.list-icon {
  margin-right: 8px;
}

.list-icon.green {
  color: #0f0;
}
.passwordvalidator-input {
  width: 100% !important;
}
@media all and (max-width: 991px) {
  .wrapper {
    width: 100%;
    margin-bottom: 20px;
  }
}
