.reset-password {
  min-height: 100vh;
  width: 100vw;
  background-color: #f6f8ff;
}

.reset-password-header {
  width: 100%;
  padding: 40px 0px 0px 40px;
  /* padding: 20px 0px 0px 40px; */
}
.reset-password-header img {
  height: 24px;
}

.reset-password-block,
.new-password-block {
  margin: 16px auto 0px auto;
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding-bottom: 80px;
}
.enter-otp-block {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.reset-password-block .heading-block,
.new-password-block .heading-block,
.enter-otp-block .heading-block {
  text-align: center;
}
.reset-password-block .middle-block,
.new-password-block .middle-block {
  margin: auto;
  width: 448px;
  display: flex;
  padding: 32px;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  border-radius: 24px;
  background: #fff;
  box-shadow: 4px 4px 16px 0px rgba(161, 161, 161, 0.12);
  max-width: 90%;
}
.reset-password-input-fields {
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  gap: 24px;
}
.reset-password-input-fields .first-section {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.reset-password-btn-section {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.vi__container input {
  height: 48px; /* Adjust input height */
}

.vi__container {
  gap: 12px; /* You can now remove !important */
}
.otp-block .timer {
  display: flex;
  height: 48px;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background: #ecf0fa;
  color: #696f79;
  font-weight: 600;
  width: 120px;
}
.emailVerification-block .send-again-div {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
}
.emailVerification-block .send-again-div p {
  color: #3c82f6;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
}
.otp-block .otp-block-first {
  display: flex;
  flex-direction: row;
  gap: 40px;
}
.otp-block .btn-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
}
.enter-otp-block .middle-block {
  width: fit-content;
  margin: auto;
  display: flex;
  padding: 32px;
  flex-direction: column;
  align-items: center;
  border-radius: 24px;
  background: #fff;
  box-shadow: 4px 4px 16px 0px rgba(161, 161, 161, 0.12);
  max-width: 90%;
}

.enter-otp-block .code-input {
  justify-content: space-evenly;
  height: 100px;
}
.enter-otp-block .vi__character {
  border-radius: 6px;
  border: 1px solid #8692a6;
  background: #fff;
  display: flex;
  justify-content: center; /* Aligns characters in the center */
  align-items: center;
  font-size: 28px;
}
.enter-otp-block .otp-block {
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  margin-top: 40px;
  height: 100%;
  align-items: center;
}
.enter-otp-block .send-again-div {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
}
.enter-otp-block .send-again-div p {
  color: #3c82f6;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
}
@media all and (max-width: 576px) {
  .reset-password-header {
    padding: 16px 0px 0px 16px;
  }
  .reset-password-heading {
    font-size: 20px;
    display: flex;
    justify-content: left;
    margin-top: 8px;
  }
  .reset-password-block {
    width: 90%;
    gap: 16px;
  }
  .reset-password-block .middle-block,
  .new-password-block .middle-block,
  .enter-otp-block .middle-block {
    max-width: 100%;
    padding: 32px 16px;
    gap: 24px;
  }
  .enter-otp-block .middle-block {
    width: 100%;
  }
  .reset-password-btn-section p {
    color: #002132;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
  }
  .reset-password-btn-section {
    position: fixed;
    display: flex;
    flex-direction: column;
    bottom: 0px;
    left: 0px;
    align-items: center;
    padding: 16px;
    gap: 16px;
    background: #f6f8ff;
  }
  .enter-otp-block {
    width: 90%;
  }
  .enter-otp-block .middle-block {
    gap: 0px;
  }
  .enter-otp-block .send-again-div {
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  .enter-otp-block {
    margin: 16px auto 0px auto;
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding-bottom: 80px;
  }
  .enter-otp-block .heading-block {
    text-align: center;
  }
  .new-password-block {
    width: 90%;
  }
}
