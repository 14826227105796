.header {
  background-color: #002132;
  height: 100vh;
  max-height: 1000px;
  overflow: hidden;
  width: 100vw;
  background: #002132;
}
@media all and (max-width: 1320px) and (min-width: 991px) {
  .header {
    height: 700px;
  }
}
:root {
  --button-large-font-family: "Karla", sans-serif;
  --button-large-font-size: 16px;
  --button-large-font-style: normal;
  --button-large-font-weight: 500;
  --button-large-letter-spacing: -0.64px;
  --button-large-line-height: normal;
  --button-large-text-align: center;
  --button-large-text-transform: none;
  --button-large-text-decoration: none;
  --button-large-text-shadow: none;
}
:root {
  --body-1-font-family: "Karla", sans-serif;
  --body-1-font-size: 16px;
  --body-1-font-style: normal;
  --body-1-font-weight: 400;
  --body-1-letter-spacing: -0.64px;
  --body-1-line-height: normal;
}

.get-started-link {
  color: #fff;
  text-decoration: none;
}
.elevate-experience-link {
  color: #fff;
  text-decoration: none;
}
.header .overlap {
  height: 1348px;
  position: relative;
  margin: 0px auto;
  width: 100%;
  max-width: 1500px;
}

.header .geo-wave {
  height: 640px;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 957px;
  top: 260px;
}
.header .geoWave2 {
  z-index: 100;
  margin-top: 20px;
}
.header .group {
  height: 660px;
  position: relative;
  width: 997px;
}

.header .layer {
  height: 660px;
}

.header .overlap-group {
  height: 649px;
  left: 17px;
  position: relative;
  top: 12px;
  width: 965px;
}

.header .group-wrapper {
  height: 649px;
  left: 0;
  position: absolute;
  top: 0;
  width: 965px;
}

.header .rectangle-wrapper {
  height: 649px;
  position: relative;
}

.header .rectangle {
  height: 649px;
  left: 209px;
  position: absolute;
  top: 148px;
  width: 965px;
}

.header .img {
  height: 249px;
  left: 4px;
  position: absolute;
  top: 0px;
  width: 957px;
}

.header .div-wrapper {
  height: 792px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 556px;
  transform: rotate(180deg);
  width: 1184px;
}

.header .layer-wrapper {
  height: 848px;
  left: -53px;
  position: relative;
  top: -51px;
  width: 1335px;
}

.header .overlap-group-wrapper {
  height: 848px;
}

.header .left-bottom-corner {
  height: 803px;
  left: 48px;
  position: relative;
  width: 1234px;
  background-color: #002132;
}

.header .group-2 {
  height: 803px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1195px;
}

.header .img-wrapper {
  height: 803px;
  position: relative;
}

.header .rectangle-2 {
  height: 803px;
  left: 618px;
  position: absolute;
  top: -408px;
  width: 1195px;
}

.header .group-3 {
  height: 420px;
  left: 457px;
  position: absolute;
  top: 198px;
  width: 777px;
}

.header .ellipse {
  background-color: #ffffff;
  border-radius: 115.5px;
  filter: blur(400px);
  height: 231px;
  left: 636px;
  opacity: 0.6;
  position: absolute;
  top: 397px;
  width: 231px;
}

.header .frame {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 24px;
  margin-left: 60px;
  margin-top: 180px;
  width: 60%;
}
@media all and (max-width: 1600px) {
  .header .frame {
    margin-left: 60px;
  }
}
.header .p {
  color: rgba(255, 255, 255, 0.6);
  font-family: Karla;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: -0.96px;
  width: 90%;
}
@media all and (min-width: 1600px) {
  .header .frame {
    margin-top: 160px !important;
  }
}
.header .group-4 {
  height: 1426px;
  position: relative;
  width: 40%;
}
.frame-and-frame-4 {
  display: flex;
}
@media all and (min-width: 1700px) {
  .frame-and-frame-4 {
    width: 1400px;
  }
  .header .frame {
    width: 45%;
  }
  .header .group-4 {
    width: 50%;
  }
}
.header .frame-2 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
}

.header .frame-3 {
  align-items: center;
  border: 1px solid;
  border-color: #ffffff1f;
  border-radius: 100px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  padding: 12px 24px;
  position: relative;
  display: none;
}
/* For proper spacing */

.header .text-wrapper {
  color: #ffffff;
  font-family: Karla;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1.28px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.header .typography {
  color: #fff;
  font-family: Roboto;
  font-size: 80px;
  font-weight: 500;
  line-height: 94px;
  letter-spacing: -1.6px;
  flex: 0 0 auto;
  position: relative;
  z-index: 5;
}

.header .button {
  all: unset;
  align-items: center;
  background-color: #00a6fb;
  border-radius: 100px;
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  padding: 12px 20px 12px 26px;
  position: relative;
  cursor: pointer;
}
.header .button:hover {
  background-color: #175ea5;
}
.landing-page-get-started-btn {
  font-size: 24px;
}
@media all and (max-width: 1320px) {
  .header .typography {
    font-size: 60px;
    line-height: 70px;
  }
  .header .geo-wave {
    top: 180px;
  }
  .header .frame {
    margin-top: 150px;
    gap: 24px;
  }
}
@media all and (max-width: 1025px) and (min-height: 900px) {
  .header .typography {
    font-size: 40px;
  }
}
@media all and (max-width: 991px) {
  .frame-and-frame-4 {
    flex-direction: column;
  }
  .header .frame {
    width: 80%;
    text-align: center;
    margin-bottom: 50px;
    align-items: center;
  }
  .header .group-4 {
    width: 100%;
  }
  .header .button,
  .header .p {
    margin: auto;
  }
  .header .typography {
    font-size: 40px;
  }
  .header .frame {
    z-index: 1;
  }
  .header .button {
    padding: 12px 22px 12px 24px;
  }
  .header .text-wrapper-2 {
    font-size: 20px !important;
    font-weight: 400;
  }
}
@media all and (max-width: 576px) {
  .landing-page-footer-fundrev-logo {
    display: none;
  }
  .header .text-wrapper-2 {
    font-size: 16px !important;
  }
  .header .frame-2 {
    align-items: center;
    width: 100%;
  }
  .header .typography {
    text-align: center;
    font-size: 38px;
    line-height: 50px;
  }
  .header .p {
    text-align: center;
    width: 90% !important;
    font-size: 16px !important;
    line-height: 20px !important;
  }
  .header {
    background-image: url("../../Assets/Images/LandingPage/frontbgphone.svg"); /* The image used */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 850px;
  }
  .header .frame {
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: 120px auto 0px auto;
    gap: 24px;
  }
  .header .group-4 {
    display: none;
  }
}

.header .content {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: flex-end;
  position: relative;
}

.header .text-wrapper-2 {
  color: #ffffff;
  font-family: Karla;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.96px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.header .heroicons-outline {
  height: 24px;
  position: relative;
  width: 24px;
}

.header .frame-4 {
  height: 900px;
  overflow: hidden;
  top: 269px;
  width: 740px;
}

.header .frame-5 {
  background-color: #00324b;
  border: 2px solid;
  border-color: #1f3c4b;
  border-radius: 16px;
  height: 384px;
  left: -2px;
  overflow: hidden;
  position: absolute;
  top: 426px;
  width: 284px;
}

.header .frame-wrapper {
  align-items: flex-start;
  background-color: #ffffff14;
  display: flex;
  flex-direction: column;
  gap: 10px;
  left: 0;
  padding: 16px 24px;
  position: absolute;
  top: 320px;
  width: 280px;
}

.header .frame-6 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  position: relative;
  width: 100%;
}

.header .rectangle-3 {
  align-self: stretch;
  background-color: #ffffff33;
  border-radius: 100px;
  height: 10px;
  position: relative;
  width: 100%;
}

.header .rectangle-4 {
  background-color: #ffffff33;
  border-radius: 100px;
  height: 10px;
  position: relative;
  width: 173px;
}

.header .frame-7 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 8px;
  left: 24px;
  position: absolute;
  top: 76px;
  width: 232px;
}

.header .rectangle-5 {
  align-self: stretch;
  background-color: #ffffff14;
  border-radius: 100px;
  height: 10px;
  position: relative;
  width: 100%;
}

.header .rectangle-6 {
  background-color: #ffffff14;
  border-radius: 100px;
  height: 10px;
  position: relative;
  width: 173px;
}

.header .frame-8 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 8px;
  left: 24px;
  position: absolute;
  top: 126px;
  width: 232px;
}

.header .frame-9 {
  background-color: #ffffff14;
  border-radius: 4px;
  height: 64px;
  left: 24px;
  position: absolute;
  top: 176px;
  width: 232px;
}

.header .group-5 {
  height: 48px;
  left: 166px;
  position: absolute;
  top: 8px;
  width: 48px;
}

.header .ellipse-wrapper {
  background-image: url(https://c.animaapp.com/QVU6VtW4/img/ellipse-724-5.svg);
  background-size: 100% 100%;
  height: 48px;
  position: relative;
}

.header .ellipse-2 {
  height: 48px;
  left: 10px;
  position: absolute;
  top: 0;
  width: 38px;
}

.header .frame-10 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  height: 40px;
  justify-content: space-between;
  left: 18px;
  position: absolute;
  top: 12px;
}

.header .rectangle-7 {
  background-color: #ffffff33;
  border-radius: 100px;
  height: 8px;
  position: relative;
  width: 129px;
}

.header .rectangle-8 {
  background-color: #ffffff33;
  border-radius: 100px;
  height: 8px;
  position: relative;
  width: 91px;
}

.header .rectangle-9 {
  background-color: #ffffff33;
  border-radius: 100px;
  height: 8px;
  position: relative;
  width: 110px;
}

.header .frame-11 {
  align-items: flex-start;
  display: inline-flex;
  gap: 8px;
  left: 24px;
  position: absolute;
  top: 248px;
}

.header .frame-12 {
  background-color: #ffffff14;
  border-radius: 4px;
  height: 40px;
  position: relative;
  width: 112px;
}

.header .group-6 {
  height: 10px;
  left: 12px;
  position: relative;
  top: 15px;
  width: 90px;
}

.header .overlap-group-2 {
  background-color: #ffffff66;
  height: 10px;
  position: relative;
  width: 88px;
}

.header .rectangle-10 {
  background-color: #00a6fb;
  height: 10px;
  left: 0;
  position: absolute;
  top: 0;
  width: 50px;
}

.header .cell {
  color: #ffffff;
  font-family: Karla;
  font-size: 8px;
  font-weight: 500;
  left: 18px;
  letter-spacing: -0.32px;
  line-height: normal;
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 14px;
}

.header .frame-13 {
  background-color: #ffffff14;
  border: 1px solid;
  border-color: #00a6fb80;
  border-radius: 4px;
  height: 40px;
  position: relative;
  width: 112px;
}

.header .group-7 {
  height: 10px;
  left: 12px;
  position: absolute;
  top: 15px;
  width: 90px;
}

.header .rectangle-11 {
  background-color: var(--green-color);
  height: 10px;
  left: 0;
  position: absolute;
  top: 0;
  width: 73px;
}

.header .cell-2 {
  color: #00324b;
  font-family: Karla;
  font-size: 8px;
  font-weight: 500;
  left: 29px;
  letter-spacing: -0.32px;
  line-height: normal;
  position: absolute;
  top: 1px;
  white-space: nowrap;
}

.header .heroicons-solid {
  height: 16px;
  left: -39px;
  position: absolute;
  top: -218px;
  width: 16px;
}

.header .overlap-2 {
  height: 13px;
  left: 2px;
  position: relative;
  top: 2px;
  width: 13px;
}

.header .ellipse-3 {
  background-color: #ffffff;
  border-radius: 5.33px;
  height: 11px;
  left: 1px;
  position: absolute;
  top: 1px;
  width: 11px;
}

.header .subtract {
  height: 13px;
  left: 0;
  position: absolute;
  top: 0;
  width: 13px;
}

.header .typography-wrapper {
  align-items: center;
  display: inline-flex;
  gap: 43px;
  left: 24px;
  position: absolute;
  top: 27px;
}

.header .typography-2 {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(
    180deg,
    rgb(1, 64, 237) 4.58%,
    rgb(78, 247, 154) 100%
  );
  background-clip: text;
  color: transparent;
  font-family: Karla;
  font-size: 19.2px;
  font-weight: 500;
  letter-spacing: -0.77px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.header .typography-3 {
  color: #ffffff;
  font-family: Karla;
  font-size: 24px;
  font-weight: 500;
  left: 169px;
  letter-spacing: -0.96px;
  line-height: normal;
  position: absolute;
  top: 24px;
  white-space: nowrap;
}

.header .frame-14 {
  background-color: #ffffff0a;
  border: 2px solid;
  border-color: #ffffff1f;
  border-radius: 16px;
  height: 384px;
  left: -2px;
  opacity: 0.6;
  overflow: hidden;
  position: absolute;
  top: 854px;
  width: 284px;
}

.header .group-8 {
  background-color: #ffffff66;
  height: 10px;
  left: 12px;
  position: relative;
  top: 15px;
  width: 88px;
}

.header .rectangle-12 {
  background-color: var(--green-color);
  height: 10px;
  width: 50px;
}

.header .frame-15 {
  align-items: center;
  display: flex;
  justify-content: space-between;
  left: 24px;
  position: absolute;
  top: 24px;
  width: 232px;
}

.header .typography-4 {
  color: #ffffff;
  font-family: Karla;
  font-size: 19.2px;
  font-weight: 500;
  letter-spacing: -0.77px;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.header .frame-16 {
  background-color: #00324b;
  border: 2px solid;
  border-color: #ffffff1f;
  border-radius: 16px;
  height: 384px;
  left: 326px;
  overflow: hidden;
  position: absolute;
  top: 616px;
  width: 284px;
}

.header .rectangle-13 {
  background-color: #00a6fb;
  height: 10px;
  left: 0;
  position: absolute;
  top: 0;
  width: 45px;
}

.header .cell-3 {
  color: #ffffff;
  font-family: Karla;
  font-size: 8px;
  font-weight: 500;
  left: 18px;
  letter-spacing: -0.32px;
  line-height: normal;
  position: absolute;
  top: 0;
  width: 21px;
}

.header .rectangle-14 {
  background-color: var(--green-color);
  height: 10px;
  width: 73px;
}

.header .overlap-3 {
  height: 28px;
  left: 24px;
  position: absolute;
  top: 24px;
  width: 232px;
}

.header .frame-17 {
  align-items: center;
  display: flex;
  justify-content: space-between;
  left: 0;
  position: absolute;
  top: 0;
  width: 232px;
}

.header .overlap-wrapper {
  height: 16px;
  left: 106px;
  position: absolute;
  top: 6px;
  width: 16px;
}

.header .frame-18 {
  background-color: #ffffff0a;
  border: 2px solid;
  border-color: #ffffff1f;
  border-radius: 16px;
  height: 384px;
  left: 654px;
  opacity: 0.3;
  overflow: hidden;
  position: absolute;
  top: 411px;
  width: 284px;
}

.header .group-9 {
  background-image: url(https://c.animaapp.com/QVU6VtW4/img/ellipse-725-7@2x.png);
  background-size: 100% 100%;
  height: 48px;
  left: -1221px;
  position: relative;
  top: -429px;
}

.header .ellipse-4 {
  height: 48px;
  left: -1221px;
  position: absolute;
  top: -429px;
  width: 48px;
}

.header .frame-19 {
  background-color: #ffffff0a;
  border: 2px solid;
  border-color: #ffffff1f;
  border-radius: 16px;
  height: 384px;
  left: 654px;
  opacity: 0.3;
  overflow: hidden;
  position: absolute;
  top: 839px;
  width: 284px;
}

.header .group-10 {
  background-image: url(https://c.animaapp.com/QVU6VtW4/img/ellipse-725-7@2x.png);
  background-size: 100% 100%;
  height: 48px;
  left: -1221px;
  position: relative;
  top: -857px;
}

.header .ellipse-5 {
  height: 48px;
  left: -1221px;
  position: absolute;
  top: -857px;
  width: 48px;
}

.header .frame-20 {
  background-color: #ffffff0a;
  border: 2px solid;
  border-color: #ffffff1f;
  border-radius: 16px;
  height: 384px;
  left: 326px;
  opacity: 0.5;
  overflow: hidden;
  position: absolute;
  top: 188px;
  width: 284px;
}

.header .frame-21 {
  background-color: #ffffff0a;
  border: 2px solid;
  border-color: #ffffff1f;
  border-radius: 16px;
  height: 384px;
  left: 326px;
  opacity: 0.3;
  overflow: hidden;
  position: absolute;
  top: 1044px;
  width: 284px;
}

.header .group-11 {
  background-image: url(https://c.animaapp.com/QVU6VtW4/img/ellipse-725-7@2x.png);
  background-size: 100% 100%;
  height: 48px;
  left: -893px;
  position: relative;
  top: -1062px;
}

.header .ellipse-6 {
  height: 48px;
  left: -893px;
  position: absolute;
  top: -1062px;
  width: 48px;
}

.header .frame-22 {
  background-color: #ffffff0a;
  border: 2px solid;
  border-color: #ffffff1f;
  border-radius: 16px;
  height: 384px;
  left: -2px;
  opacity: 0.3;
  overflow: hidden;
  position: absolute;
  top: -2px;
  width: 284px;
}

.header .group-12 {
  background-image: url(https://c.animaapp.com/QVU6VtW4/img/ellipse-725-7@2x.png);
  background-size: 100% 100%;
  height: 48px;
  left: -565px;
  position: relative;
  top: -16px;
}

.header .ellipse-7 {
  height: 48px;
  left: -565px;
  position: absolute;
  top: -16px;
  width: 48px;
}

.header .top-bar {
  height: 100px;
  left: 407px;
  position: absolute;
  top: 169px;
  width: 1440px;
}

.header .element-FUNDREV-LOGO {
  height: 32px;
  left: 80px;
  position: absolute;
  top: 34px;
  width: 147px;
}

.header .content-wrapper {
  all: unset;
  align-items: center;
  background-color: #ffffff;
  border-radius: 100px;
  box-sizing: border-box;
  display: inline-flex;
  gap: 8px;
  left: 1250px;
  padding: 8px 16px;
  position: absolute;
  top: 30px;
}

.header .text-wrapper-3 {
  color: #3c5168;
  font-family: var(--button-large-font-family);
  font-size: var(--button-large-font-size);
  font-style: var(--button-large-font-style);
  font-weight: var(--button-large-font-weight);
  letter-spacing: var(--button-large-letter-spacing);
  line-height: var(--button-large-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.header .frame-23 {
  align-items: flex-start;
  display: inline-flex;
  gap: 40px;
  left: 580px;
  position: absolute;
  top: 30px;
}

.header .button-2 {
  all: unset;
  align-items: center;
  border-radius: 4px;
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  padding: 8px 16px;
  position: relative;
}

.header .text-wrapper-4 {
  color: #ffffff;
  font-family: var(--button-large-font-family);
  font-size: var(--button-large-font-size);
  font-style: var(--button-large-font-style);
  font-weight: var(--button-large-font-weight);
  letter-spacing: var(--button-large-letter-spacing);
  line-height: var(--button-large-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}
.onetotwentymillion {
  /* background: linear-gradient(30deg, #0140ed, #4ef79a); */
  background: linear-gradient(
    35deg,
    rgb(1, 64, 237, 0.6),
    rgb(78, 247, 154, 1)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Roboto;
}
.middle-professionalism {
  display: flex;
  flex-direction: column;
}
.LandingPageMid {
  display: flex;
  justify-content: space-between;
  width: 90vw;
  margin: 0vw 5vw;
  padding-bottom: 20px;
}
@media all and (min-width: 991px) {
  .LandingPageMid {
    gap: 140px;
  }
}
.LandingPageMid .img-5 {
  height: 24px;
}
@media all and (min-width: 1400px) {
  .LandingPageMid {
    width: 1350px;
    margin: 0px calc(50vw - 675px);
  }
  :root {
    --body-1-font-size: 20px;
  }
}
@media all and (min-width: 1600px) {
  .LandingPageMid {
    width: 1400px;
    margin: 0px calc(50vw - 700px);
  }
  :root {
    --body-1-font-size: 20px;
  }
}
.landing-page .overlap-6 {
  height: 100%;
  width: 100%;
}
.landing-page .text-wrapper-6 {
  color: #ffffff;
  font-family: "Karla", sans-serif;
  font-size: 16px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
  font-weight: 500;
}
.landing-page .frame-75 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
  /* width: 590px; */
}
.landing-page .frame-47 {
  align-items: flex-start;
  display: inline-flex;
  gap: 16px;
}
.landing-page .frame-36 {
  height: 1867px;
  left: 0;
  position: absolute;
  top: 1250px;
}

.landing-page .frame-37 {
  border-radius: 32px;
  height: 700px;
  top: 160px;
  width: 510px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.landing-page .img-frame-9 {
  border-radius: 32px;
}
.landing-page .frame-73 {
  height: 700px;
  overflow: hidden;
  top: 160px;
  width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.landing-page .frame-48 {
  align-items: center;
  border-radius: 100px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  position: relative;
}
.landing-page .typography-23 {
  align-self: stretch;
  color: var(--Primary-Text-Color) 33;
  font-family: Roboto;
  font-size: 80px;
  font-weight: 500;
  letter-spacing: -1.6px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-decoration: line-through;
}
.LetsFundrev {
  width: 100%;
  align-items: center;
}
.Lets {
  background: rgba(0, 33, 50, 0.6);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto;
  font-size: 80px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -1.6px;
  text-decoration: none;
}
.deal-making {
  font-family: Roboto;
  background: linear-gradient(10deg, #0140ed 10%, #4ef79a 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.Fundrev {
  background: linear-gradient(99deg, #0140ed 26.33%, #4ef79a 106.32%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Roboto;
  font-size: 80px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -1.6px;
  text-decoration: none;
}
.landing-page .text-wrapper-10 {
  color: rgba(0, 33, 50, 0.6);
  font-family: "Karla", sans-serif;
  font-weight: 400;
  letter-spacing: -0.64px;
  line-height: 22.4px;
  width: 460px;
}

.landing-page .frame-74 {
  display: inline-flex;
  flex-direction: column;
  gap: 40px;
  left: 70px;
  top: 80px;
}

.landing-page .frame-38 {
  background-color: #ffffff;
  border-radius: 16px;
  height: 415px;
  left: 0;
  overflow: hidden;
  position: relative;
  top: 122px;
  width: 396px;
}

.landing-page .frame-45 {
  -webkit-backdrop-filter: blur(5.66px) brightness(100%);
  backdrop-filter: blur(5.66px) brightness(100%);
  background-color: #ffffffcc;
  border: 1.84px solid;
  border-color: transparent;
  border-image: linear-gradient(
      to bottom,
      rgb(255, 255, 255),
      rgba(255, 255, 255, 0)
    )
    1;
  border-radius: 14.68px;
  box-shadow: 0px 24px 80px var(--Primary-Text-Color) 1f;
  height: 296px;
  left: 174px;
  overflow: hidden;
  position: relative;
  top: 0;
  width: 256px;
}
.landing-page .frame-41 {
  align-items: center;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: var(--Primary-Text-Color) 14;
  display: flex;
  flex: 0 0 auto;
  gap: 16px;
  padding: 16px 0px;
  position: relative;
  width: 356px;
}
.Check-div {
  display: flex;
  width: 50px;
  padding: 6px 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 16px;
  background: rgba(46, 125, 50, 0.08);
}
.check {
  height: 15px;
}
.Cross-div {
  display: flex;
  width: 50px;
  padding: 6px 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 16px;
  background: rgba(211, 47, 47, 0.08);
}
.IOI-div {
  display: flex;
  width: 50px;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 16px;
  background: rgba(46, 125, 50, 0.08);
  font-size: 15px;
  font-weight: 600;
}
.landing-page .founders-img {
  display: none;
}

@media all and (max-width: 1025px) {
  .landing-page .frame-73 {
    width: 500px;
  }
  .landing-page .frame-37 {
    width: 400px;
  }
}

@media all and (max-width: 991px) {
  .LandingPageMid {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .landing-page .overlap-6 {
    top: initial;
    margin-top: 60px;
  }
  .landing-page .frame-47 {
    top: 1300px;
  }
  .landing-page .frame-36 {
    top: 1300px;
  }
  .landing-page .frame-73 {
    margin-bottom: 50px;
    padding: 30px 0px;
    height: initial;
  }
  .landing-page .frame-37 {
    height: initial;
    width: 500px;
  }
}
@media all and (max-width: 576px) {
  .landing-page .frame-73,
  .landing-page .frame-37 {
    width: 90vw;
  }
  .landing-page .typography-23 {
    font-size: 60px;
  }
  .Lets {
    font-size: 60px;
  }
  .Fundrev {
    font-size: 60px;
  }
  .landing-page .text-wrapper-10 {
    width: 100%;
  }
  .landing-page .frame-74 {
    gap: 30px;
    margin: auto;
  }
  .landing-page .frame-36 {
    top: 1150px;
  }
  .landing-page .frame-47 {
    top: 1220px;
  }
  .frame-37 {
    padding-bottom: 30px;
  }

  .landing-page .frame-45 {
    left: 150px;
  }
}
@media all and (max-width: 576px) {
  .LandingPageMid {
    width: 100vw;
    margin: 0px 0px;
  }
  .landing-page .typography-23 {
    font-size: 40px;
  }
  .Lets,
  .Fundrev {
    font-size: 38px;
  }
  .landing-page .frame-36 {
    top: 1000px;
  }
  .landing-page .frame-47 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 1190px;
  }
  .landing-page .frame-38 {
    left: 20px;
  }
  .landing-page .frame-41 {
    width: 300px;
  }
  .IOI-div {
    display: none;
  }
  .landing-page .frame-38 {
    width: 330px;
    left: 50px;
  }
  .landing-page .group-6 {
    left: 50px;
  }
  .landing-page .frame-45 {
    left: 120px;
  }
  .landing-page .frame-37 {
    height: initial;
    padding-bottom: 50px;
    width: 100vw;
  }
  .landing-page .img-frame-9 {
    border-radius: 0px;
  }
  .landing-page .founders-img {
    display: block;
    width: 40%;
    border-radius: 20px;
  }
}
.landing-page .frame-72 {
  align-items: flex-start;
  display: inline-flex;
  gap: 16px;
  position: absolute;
  margin-top: 30px;
}

.landing-page .frame-49 {
  background-color: #f8f9fa;
  border-radius: 32px;
  height: 767px;
  overflow: hidden;
  top: 940px;
  width: 500px;
  display: flex;
  justify-content: center;
}

.landing-page .overlap-7 {
  height: 605px;
  position: absolute;
  width: 432px;
  margin-top: 90px;
}

.landing-page .frame-65 {
  -webkit-backdrop-filter: blur(6.48px) brightness(100%);
  backdrop-filter: blur(6.48px) brightness(100%);
  background-color: #ffffffcc;
  border-radius: 18px;
  box-shadow: 0px 18px 60px var(--Primary-Text-Color) 1f;
  height: 262px;
  left: 171px;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 261px;
}
.landing-page .frame-50 {
  -webkit-backdrop-filter: blur(7.78px) brightness(100%);
  backdrop-filter: blur(7.78px) brightness(100%);
  background-color: #ffffff;
  border-radius: 21.6px;
  height: 507px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 98px;
  width: 360px;
}

.landing-page .frame-77 {
  height: 767px;
  overflow: hidden;
  top: 100px;
  width: 600px;
  display: flex;
  align-items: center;
}

.landing-page .text-wrapper-11 {
  color: rgba(0, 33, 50, 0.6);
  font-weight: 400;
  letter-spacing: -0.64px;
  line-height: 22.4px;
  position: relative;
  width: 510px;
}
.landing-page .button-3 {
  all: unset;
  width: fit-content;
  align-items: center;
  border-radius: 100px;
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  padding: 8px 16px;
  position: relative;
  cursor: pointer;
  border-radius: 100px;
  background: linear-gradient(0deg, #002132 0%, #002132 100%),
    linear-gradient(98deg, #0140ed -13.28%, #4ef79a 120.85%);
}
.landing-page .button-3:hover {
  background-color: #175ea5;
}
.landing-page .typography-35 {
  align-self: stretch;
  color: var(--Primary-Text-Color) de;
  font-family: Roboto;
  font-size: 32px;
  font-weight: 500;
  letter-spacing: -0.64px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
}
.elevateExperience {
  width: 100vw;
  max-width: 1400px;
  position: absolute;
}
.elevate-experience-text-div {
  position: absolute;
  width: 100%;
  z-index: 5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  bottom: 600px;
  /* backdrop-filter: blur(2px); */
}
.elevate-experience-text {
  /* background: linear-gradient(180deg, #fff 0%, #5efca1 41.5%); */
  background: #fff;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -1.92px;
  z-index: 5;
  width: 920px;
  max-width: 600px;
  margin: auto;
  /* margin-left: calc(50vw - 460px); */
  /* font-size: 7vw; */
  /* margin-top: 5vw; */
  margin-top: 0px;
  font-size: 70px;
  /* text-shadow: 2px 2px 10px hsl(145, 28%, 68%); */
}
.elevate-experience-lower-div {
  position: relative;
  top: 190px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 45%;
  max-width: 700px;
  align-items: center;
}
/* .FoundersPage .elevate-experience-text {
  width: 850px;
  margin-left: calc(50vw - 425px);
} */
.elevate-experience-small-text {
  color: #fff;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
  letter-spacing: -0.64px;
  z-index: 5;
  max-width: 100%;
  margin-top: 20px;
}
.landing-page .frame-92 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 40px;
  left: 0;
  padding: 32px 80px 64px 80px;
  width: 100vw;
  max-width: 1400px;
}
.social-links {
  width: 100px;
  display: flex;
  justify-content: space-between;
}
@media all and (max-width: 450px) {
  .social-links {
    width: 80px;
    display: flex;
    justify-content: space-between;
  }
}
.landing-page .text-wrapper-15 {
  color: var(--Primary-Text-Color);
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.landing-page .text-wrapper-14 {
  color: var(--Primary-Text-Color);
  font-style: var(--body-1-font-style);
  font-weight: var(--body-1-font-weight);
  line-height: var(--body-1-line-height);
  position: relative;
  width: 330px;
  margin-top: 24px;
}
.landing-page-footer-fundrev-logo {
  height: 32px;
}
.landing-page .text-wrapper-17 {
  color: var(--Primary-Text-Color);
  font-family: var(--body-1-font-family);
  font-style: var(--body-1-font-style);
  font-weight: var(--body-1-font-weight);
  line-height: var(--body-1-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
  cursor: pointer;
}

.landing-page .text-wrapper-18 {
  color: var(--Primary-Text-Color);
  font-family: var(--body-1-font-family);

  font-style: var(--body-1-font-style);
  font-weight: var(--body-1-font-weight);
  line-height: var(--body-1-line-height);
  position: relative;
  width: 204px;
}
.landing-page .frame-95 {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  position: relative;
  width: 100%;
  max-width: 850px;
  gap: 70px;
}
@media all and (max-width: 1400px) {
  .landing-page .frame-95 {
    gap: 20px;
  }
  .elevate-experience-small-text {
    margin-top: 8px;
    font-size: 18px;
  }
}
.landing-page .frame-97 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
}
.landing-page .frame-98 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  /* width: 85vw; */
}
.landing-page .frame-99 {
  display: flex;
  flex-direction: row;
  width: 300px;
  justify-content: space-around;
}

.landing-page .frame-93 {
  display: flex;
  flex: 0 0 auto;
  /* gap: 110px; */
  position: relative;
  justify-content: space-between;
  /* width: 1210px; */
  width: 100%;
  margin: auto;
}

.landing-page .frame-96 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
  padding: 20px;
}

.landing-page .frame-94 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  padding-top: 20px;
}

.landing-page .text-wrapper-16 {
  color: var(--Primary-Text-Color);
  font-style: var(--body-1-font-style);
  font-weight: var(--body-1-font-weight);

  line-height: var(--body-1-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
  cursor: pointer;
}
.landing-page .text-wrapper-19 {
  color: var(--Primary-Text-Color);
  font-style: var(--body-1-font-style);
  font-weight: var(--body-1-font-weight);

  line-height: var(--body-1-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}
.button {
  all: unset;
  align-items: center;
  background-color: #3c5168;
  border-radius: 100px;
  box-sizing: border-box;
  display: inline-flex;
  gap: 8px;
  padding: 12px 20px 12px 26px;
  position: relative;
}
.elevate-experience-text-div .button {
  top: 270px;
}
.button .content {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: flex-end;
  position: relative;
}
.ElevateExperience-section .button {
  margin-top: 270px;
  cursor: pointer;
}
.ElevateExperience-section .button .text-wrapper {
  color: #ffffff;
  display: flex;
  font-family: "Karla-Medium", Helvetica;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: -0.96px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.ElevateExperience-section .button .heroicons-outline {
  height: 24px;
  position: relative;
  width: 24px;
}
.landing-page .typography-36 {
  color: rgba(0, 33, 50, 0.6);
  flex: 1;
  font-family: Roboto !important;
  font-size: 80px;
  font-weight: 500;
  letter-spacing: -2px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
  margin-top: 80px;
}
.landing-page .frame-120 {
  background-color: #fafafa;
  padding-bottom: 100px;
  left: 0;
  width: 100vw;
  max-width: 1400px;
}
.landing-page .frame-121 {
  align-items: center;
  display: flex;
  gap: 10px;
  justify-content: center;
  position: absolute;
  width: 100%;
  max-width: 1400px;
}
.landing-page .frame-123 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  gap: 40px;
  position: relative;
  width: 100%;
}
.landing-page .frame-125 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 75%;
}
.landing-page .frame-127 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 30px;
  margin-bottom: -22px;
  position: relative;
  width: 100%;
}
.frames {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 600px;
  gap: 32px;
  margin: 250px auto 0px auto;
}

.arrow-btn {
  display: flex;
  padding: 8px;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  cursor: pointer;
}
.arrow-left {
  transform: rotate(180deg);
}
.landing-page .text-wrapper-21 {
  align-self: stretch;
  color: var(--Primary-Text-Color);
  font-family: "Karla", sans-serif;
  font-weight: 400;
  letter-spacing: -0.64px;
  line-height: 22.4px;
  position: relative;
}

.landing-page .frame-122 {
  align-items: flex-start;
  background-color: #ffffff;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: hidden;
  padding: 24px;
  top: 378px;
  margin: auto;
}

.landing-page .frame-124 {
  height: 64px;
  position: relative;
  width: 64px;
  max-width: none;
}
.frame124block {
  display: flex;
  padding: 18px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
}
.library {
  background: #e5f5fe;
}
.shield {
  background: #e8f6ef;
}
.headset {
  background: #eef2ff;
}
.landing-page .typography-37 {
  align-self: stretch;
  color: var(--Primary-Text-Color) de;
  font-family: Roboto;
  font-size: 26px;
  font-weight: 500;
  letter-spacing: -0.64px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
}
.landingPageMid4 {
  width: 100vw;
  margin: 50px 0px 0px 0px;
}
.frame-101 {
  display: inline-flex;
  /* margin: 50px 0px 50px 30px; */
  margin: 150px auto 50px auto;
  width: 90vw;
  margin: 50px 5vw;
  max-width: 1350px;
  display: flex;
  justify-content: space-between;
}
@media all and (min-width: 1400px) {
  .frame-101 {
    width: 1350px;
    margin: 150px auto 50px auto;
  }
  .landing-page .frame-95 {
    gap: 100px;
  }
}
.typography-32 {
  color: var(--Primary-Text-Color) de;
  font-family: Roboto !important;
  font-size: 80px;
  font-weight: 500;
  letter-spacing: -1.6px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
  text-align: left;
}
.gradient-color {
  background: linear-gradient(99deg, #0140ed 26.33%, #4ef79a 106.32%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Roboto;
}

.frame-102 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 24px;
  position: relative;
}
.typography-33 {
  font-size: 32px;
}
.mobile-deals,
.mobile-social-links {
  display: none;
}
.mobile-arrow {
  display: none;
}
.footer-disclosure {
  border-top: 1px solid #e6e6e6;
  padding-top: 40px;
  max-width: 1350px;
  font-size: 12px;
}
.terms-conditions {
  border-top: 1px solid #e6e6e6;
  width: 82%;
  padding-top: 20px;
  margin: auto;
}
.social-links .img-6 {
  height: 24px;
  width: 24px;
  cursor: pointer;
  margin-top: 24px;
}
.arrow-link {
  display: none !important;
}
#phase1,
#phase2,
#phase3,
#phase4,
#fundraise,
#investorreporting {
  scroll-margin-top: 100px;
}
@media all and (max-width: 991px) {
  .frames {
    width: 100%;
  }
}
@media all and (min-width: 1400px) {
  .landing-page .frame-126 {
    height: 350px;
  }
  .landing-page .frame-128 {
    height: 350px;
  }
  .landing-page .frame-120 {
    max-width: initial;
  }
  .landing-page .frame-121 {
    max-width: initial;
  }
  .landingPageMid4 {
    width: 100vw;
    margin: 50px 0px 0px 0px;
    max-width: initial;
  }
}
@media all and (max-width: 1024px) {
  .landing-page .frame-95 {
    justify-content: space-between;
  }
}
@media all and (max-width: 991px) {
  .landing-page .frame-126 {
    margin-top: initial;
    height: 300px;
  }
  .landing-page .frame-128 {
    margin-top: 30px;
    height: 300px;
  }
  .landing-page .frame-122 {
    margin-bottom: 30px;
    height: 300px;
  }
  .landing-page .typography-36 {
    font-size: 50px;
  }
  .arrow-link {
    display: block !important;
  }
}

@media all and (max-width: 576px) {
  .landingPageMid4 {
    margin-top: 0px;
  }
  .landing-page .frame-120 {
    background-color: #fff;
    padding-bottom: 20px;
  }
  .landing-page .frame-121 {
    width: 80vw;
    margin: 0px 10vw;
  }
  .landing-page .typography-36 {
    margin-top: 40px;
    font-size: 38px;
  }
  .mobile-deals {
    display: block;
  }
  .laptop-deals {
    display: none;
  }
  .first-landingPageMid {
    margin-bottom: 0px !important;
  }
  .deal-making {
    font-family: "Roboto" !important;
    background: linear-gradient(99deg, #0140ed 26.33%, #4ef79a 106.32%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 500;
  }
  .frames {
    margin-top: 170px;
  }
  .landing-page .frame-126,
  .landing-page .frame-128,
  .landing-page .frame-122 {
    width: 360px;
    padding: 30px;
    height: fit-content;
    border-radius: 24px;
    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.1);
  }
  .landing-page .frame-126 {
    padding-bottom: 50px;
  }
  .landing-page .frame-124 {
    height: 64px;
    width: 64px;
  }
  .landing-page .frame124block {
    height: 100px;
    width: 100px;
  }
  .landing-page .typography-37 {
    font-size: 28px;
  }
  .landing-page .frame-123 {
    flex-direction: column;
  }
  .landing-page .frame-125 {
    width: 100%;
  }
}
@media all and (min-width: 1400px) {
  .landing-page .frame-92 {
    max-width: initial;
    padding-left: calc(50vw - 675px);
  }
  .footer-landing-page {
    margin: auto !important;
  }
  .landing-page .frame-93,
  .terms-conditions {
    margin: initial;
    max-width: 1350px;
  }
  /* .landing-page .frame-93 {
    width: initial;
  } */
  .terms-conditions {
    width: 100%;
  }
}
@media all and (max-width: 1400px) {
  .landing-page .frame-93,
  .terms-conditions {
    width: 100%;
  }
}
@media all and (max-width: 991px) {
  .landing-page .frame-93 {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }
  .landing-page .frame-94 {
    width: 100%;
    padding: 0px 20px 40px 20px;
  }
  .landing-page .frame-98 {
    display: flex;
    flex-direction: column;
  }
}

@media all and (max-width: 576px) {
  .landing-page .frame-93 {
    gap: 30px;
    width: 100%;
  }
  .landing-page .frame-95 {
    flex-direction: column;
  }
  .landing-page .frame-96 {
    padding: 20px 10px;
  }
  .landing-page .frame-92 {
    padding: 0px;
    gap: 20px;
  }
}
@media all and (max-width: 1400px) {
  .ElevateExperience-section {
    height: 83vw;
  }
}

/* @media all and (max-width: 1533px) {
  .elevate-experience-text {
    margin-left: calc(50vw - 30%);
  }
  .FoundersPage .elevate-experience-text {
    margin-left: calc(50vw - 30%);
  }
} */
@media all and (max-width: 991px) {
  .elevateExperience {
    display: none;
  }
  .ElevateExperience-section {
    width: 100vw;
    height: 1000px;
    /* background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../../Assets/Images/LandingPage/elevateExperience.svg"); */
  }
  .elevate-experience-text {
    font-size: 60px;
    max-width: initial;
    width: 100%;
    margin: initial;
    width: 50%;
  }
  .FoundersPage .elevate-experience-text {
    width: 100%;
    margin: initial;
    width: 50%;
  }
  .elevate-experience-text-div {
    bottom: 550px;
  }
  .ElevateExperience-section .button {
    margin-top: 260px;
  }
}

@media all and (max-width: 768px) {
  .elevate-experience-text {
    width: 70%;
  }
  .FoundersPage .elevate-experience-text {
    width: 70%;
  }
}

/* @media all and (max-width:576px) {
  .elevate-experience-text{
    font-size: 36px;
    margin-top: 50px;
  }
  .elevate-experience-small-text{
    font-size: 12px;
  }
  
} */
@media all and (min-width: 1400px) {
  /* .elevate-experience-text {
    font-size: 96px;
  } */
  .ElevateExperience-section {
    background-color: #00a6fb;
    width: 100vw;
    height: 1167px;
  }
  .elevateExperience {
    margin: auto calc(50vw - 700px);
  }
}

@media all and (max-width: 991px) {
  .landing-page .frame-49 {
    margin-bottom: 50px;
    width: 90vw;
  }
  .landing-page .group-6 {
    display: none;
  }
  .landing-page .frame-77 {
    margin-bottom: 30px;
  }
  .LandingPageMid2 {
    flex-direction: column-reverse;
  }
}
@media all and (max-width: 576px) {
  .landing-page .text-wrapper-11 {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.64px;
    line-height: 22.4px;
    position: relative;
    width: 100%;
  }
  .elevate-experience-small-text {
    margin-top: 30px;
  }
  .landing-page .frame-77 {
    width: 90vw;
  }
  .landing-page .frame-49 {
    width: 100vw;
  }
}
@media all and (max-width: 576px) {
  .landing-page .frame-72 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .landing-page .frame-73 {
    border: initial;
    padding: 0px;
  }
  .landing-page .frame-65 {
    left: 50px;
  }
  .landing-page .overlap-7 {
    left: 40px;
    margin-top: 160px;
  }
  .landing-page .frame-50 {
    width: 80vw;
  }
  .landing-page .frame-49 {
    height: initial;
  }
  .landing-page .frame-77 {
    height: initial;
    border: 0px;
  }

  /* .ElevateExperience-section {
    background-image: url("../../Assets/Images/LandingPage/elevateExperienceMobile.svg");
  } */
  .landing-page .frame-95 {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two columns */
    grid-template-rows: repeat(2, auto); /* Two rows */
    gap: 10px; /* Adjust the gap between grid items */
    padding: 0px 15px;
  }
  .landing-page .frame-92 {
    align-items: center;
  }
  .landing-page .frame-93 {
    gap: 10px;
  }
  .landing-page .frame-97 {
    gap: 24px;
  }
  .landing-page .text-wrapper-15 {
    font-size: 14px;
  }
  .landing-page .text-wrapper-16,
  .landing-page .text-wrapper-17 {
    font-size: 14px;
    color: #3c5168;
  }
  .landing-page .text-wrapper-14 {
    color: #3c5168;
    font-size: 14px;
    width: 100%;
  }
  .logo-social-links {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }
  .laptop-social-links {
    display: none;
  }
  .mobile-social-links {
    display: flex;
  }
  .landing-page .frame-98 {
    flex-direction: column-reverse;
    align-items: center;
  }
  .landing-page .frame-99 {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
  .landing-page .copyright {
    font-size: 14px;
    margin: 20px;
    text-wrap: wrap;
    text-align: center;
    color: #3c5168;
  }
  .landing-page .element-FUNDREV-LOGO-3 {
    width: 117px;
    height: 25px;
  }
  .landing-page .social-links-div {
    border-bottom: 1px solid #e6e6e6;
    padding-bottom: 20px;
  }
}
@media all and (max-width: 400px) and (min-height: 700px) {
  .header .frame {
    width: 90%;
  }
}
@media all and (max-width: 400px) and (max-height: 700px) {
  .header .frame {
    margin-top: 70px;
    gap: 25px;
  }
  .header .typography {
    font-size: 30px;
  }
}
.professionalism {
  background-color: #3c5168;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #ffffff80;
  height: 1353px;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 160px 80px;
}
@media all and (min-width: 1400px) {
  .professionalism {
    width: 100vw;
    padding: 160px calc(50vw - 700px);
  }
  .ElevateExperience-section {
    background-color: #00a6fb;
    height: 1167px;
    width: 100vw;
  }
}

@media (max-width: 1400px) {
  .ElevateExperience-section {
    height: 83vw;
  }
}

.ElevateExperience-section .button {
  margin-top: 270px;
}
.ElevateExperience-section .button .text-wrapper {
  color: #ffffff;
  font-family: "Karla-Medium", Helvetica;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: -0.96px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.ElevateExperience-section .button .heroicons-outline {
  height: 24px;
  position: relative;
  width: 24px;
}
@media all and (min-width: 1400px) {
  .elevate-experience-text {
    margin-top: 12vw;
    margin-top: 70px;
  }
  .FoundersPage .elevate-experience-text {
    margin-top: 12vw;
    margin-top: 70px;
  }
  .ElevateExperience-section .button {
    margin-top: 290px;
  }
}
@media all and (max-width: 1400px) {
  .ElevateExperience-section .button {
    margin-top: 21vw;
  }
}
@media all and (max-width: 991px) {
  .elevateExperience {
    display: none;
  }
  .elevate-experience-small-text {
    font-size: 18px;
  }
  .ElevateExperience-section .button {
    margin-top: 260px;
  }
}
@media all and (max-width: 576px) {
  .elevate-experience-text {
    font-size: 48px;
    width: 80%;
  }
  .FoundersPage .elevate-experience-text {
    font-size: 48px;
    width: 80%;
  }
  .ElevateExperience-section .button {
    margin-top: 280px;
    padding: 12px 20px 12px 18px;
  }
  .button {
    gap: 3px;
  }
}
@media all and (max-width: 576px) {
  .elevate-experience-text {
    letter-spacing: -0.76px;
    font-size: 38px;
    width: 100%;
  }
  .FoundersPage .elevate-experience-text {
    letter-spacing: -0.76px;
    font-size: 38px;
    width: 100%;
  }
  .elevate-experience-text-div {
    margin-top: 100px;
  }
  .ElevateExperience-section .button {
    margin-top: 30px;
    color: #000;
    background-color: #fff;
  }
  .ElevateExperience-section .button .text-wrapper .elevate-experience-link {
    color: #000;
    font-size: 16px;
    font-weight: 600;
  }
  .ElevateExperience-section .button .heroicons-outline {
    color: #000;
    height: 18px;
  }
  .mobile-arrow {
    display: block;
  }
  .laptop-arrow {
    display: none;
  }
}
@media all and (max-width: 576px) {
  .ElevateExperience-section .button {
    top: 330px;
  }
  .footer-disclosure {
    padding: 20px 30px;
  }
  .footer-disclosure p {
    color: #3c5168;
    font-family: var(--body-1-font-family);
    font-style: var(--body-1-font-style);
    font-weight: var(--body-1-font-weight);

    line-height: var(--body-1-line-height);
  }
}
@media all and (max-width: 400px) {
  .elevate-experience-small-text {
    margin-top: 10px;
  }
  .ElevateExperience-section .button {
    margin-top: 10px;
    padding: 12px 22px 12px 26px;
  }
  .step .frame-2,
  .step .div-wrapper {
    padding: 12px 20px !important;
  }
  .landing-page .frame-101 {
    margin-bottom: 20px !important;
  }
  .elevate-experience-text {
    font-size: 36px;
    width: 100%;
  }
  .FoundersPage .elevate-experience-text {
    font-size: 36px;
    width: 100%;
  }
  .elevate-experience-small-text,
  .ElevateExperience-section .button {
    margin-top: 30px;
  }
}
@media all and (min-width: 1536px) {
  .text-wrapper-14 {
    font-size: 20px;
  }
  .text-wrapper-15 {
    font-size: 25px;
  }
  .text-wrapper-16 {
    font-size: 17.5px;
  }
  .footer-disclosure,
  .text-wrapper-19,
  .frame-99 {
    font-size: 17.5px;
  }
  .social-links {
    width: 125px;
  }
}
