.timeline {
  background-color: #fff;
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: 0 auto;
  padding: 16px 16px 0px 16px;
  flex: 1;
  height: fit-content;
  width: 240px;
  border-radius: 4px;
  box-shadow: var(--whiteBg-back-shadow);
}

.timeline-padding {
  padding: 33px 30px;
}

.timeline .line {
  border-left: 2px solid #5db18a;
  position: absolute;
  left: -7px;
  top: 35px;
  height: 52px;
  margin: 0px 0px 8px 20px;
  z-index: 0;
}
/* .company-timeline .line {
  height: 50px;
} */

.timeline .details-div {
  display: flex;
  gap: 12px;
}

@media (max-width: 991px) {
  .timeline {
    margin-top: 40px;
    padding: 0 20px;
  }
}
.timeline .div-2 {
  color: var(--Primary-Text-Color);
  font: 500 20px Karla, sans-serif;
  margin-bottom: 18px;
}
.timeline .div-3 {
  display: flex;
  flex-direction: column-reverse;
  /* margin-top: 35px; */
  text-align: center;
  position: relative;
  min-height: 36px;
  margin-bottom: 25px;
}
.timeline .img {
  object-position: center;
  width: 28px;
  height: 28px;
  z-index: 5;
}
.timeline .div-4 {
  display: flex;
  flex-direction: column;
}
.timeline .div-5 {
  color: #696f79;
  font: 500 14px/150% Karla, -apple-system, Roboto, Helvetica, sans-serif;
}
.timeline .div-6 {
  color: #465668;
  margin-top: 5px;
  font: 500 14px Karla, sans-serif;
}

.timeline .completed-text {
  color: var(--Primary-Text-Color);
  font-weight: 500;
}
.view-details {
  font-size: 12px;
  cursor: pointer;
  color: var(--dark-green-color);
  /* background-color: #e6faf2; */
  border-radius: 16px;
  padding: 4px;
  position: relative;
  top: 0px;
  width: fit-content;
  height: 0px;
}
