.dataroom-text {
  font-size: 14px;
}
.dataroom-first-div {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  justify-content: space-between;
}
.dataroom-breadcrumbs {
  display: flex;
  flex-direction: row;
}
.dataroom-btns {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}
.dataroom-btns-left {
  display: flex;
  gap: 10px;
}
.dataroom-btn {
  background-color: #fff;
  color: #667085;
  border-radius: 6px;
  padding: 3px 12px;
  font-size: 14px;
  cursor: pointer;
  border-color: transparent;
  box-shadow: 0px 0px 2px 0px rgba(12, 26, 75, 0.24),
    0px 3px 8px -1px rgba(50, 50, 71, 0.05);
}
.dataroom-btn:hover {
  background-color: #f5f5f5;
}

.dataroom-div {
  padding: 16px;
  border-radius: 4px;
  box-shadow: var(--whiteBg-back-shadow);
  background: #fff;
}
.dataroom-header {
  background-color: #eaebec;
  color: #696f79;
  font-size: 14px;
  height: 44px;
}
.dataroom-row {
  height: 40px;
}
.folder-cell {
  display: flex;
  align-items: center;
  gap: 10px;
}
.dataroom-action-btns {
  display: flex;
  gap: 20px;
  height: 72px;
  align-items: center;
}
.admin-folder-name {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  height: 30px;
}

.admin-file-icon {
  width: 40px !important;
}
.admin-dataroom-rename {
  padding: 10px 20px;
  border: 0px;
  border-radius: 6px;
  color: #fff;
  background-color: #00a6fb;
}
.admin-qna-section {
  display: flex;
  align-items: center;
  gap: 25px;
  margin-top: 20px;
}
.admin-rename-input {
  width: 280px;
}
.dataroom-btn-div {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}
.dataroom-table-header {
  background-color: #eaebeb;
  color: #696f79;
  font-weight: 300;
  font-size: 14px;
}
.dataroom-folder {
  height: 30px;
  font-size: 14px;
  color: (--Primary-Text-Color);
}
.dataroom-folder-img {
  width: 18px;
}
.active-dataroom-btn {
  color: #00a6fb;
  background-color: #e5f5fe;
}
.active-dataroom-btn:hover {
  background-color: #d6f0fd;
}
