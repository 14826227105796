.Notify-div {
    padding-bottom: 80px;
    background-color: #fff;
    display: flex;
    max-width: 480px;
    width: 100%;
    flex-direction: column;
    color: #5a6474;
    font-weight: 500;
    margin: 0 auto;
    position: absolute;
    right: 0px;
    height: 100%;
  }
  .Notify-div-2 {
    justify-content: center;
    box-shadow: 0px -1px 0px 0px #e4e7eb inset;
    background-color: rgba(255, 255, 255, 0);
    display: flex;
    width: 100%;
    flex-direction: column;
    font-size: 20px;
    color: #000;
    white-space: nowrap;
    line-height: 120%;
    padding: 24px 19px 24px 16px;
  }
  .Notify-div-3 {
    justify-content: space-between;
    display: flex;
    gap: 20px;
  }
  .Notify-div-4 {
    font-family: Karla, sans-serif;
  }
  .img {
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: 12px;
    margin: auto 0;
  }
  .Notify-div-5 {
    display: flex;
    gap: 12px;
    padding: 16px 17px;
  }
  .Notify-img-2 {
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: 40px;
    margin: auto 0;
  }
  .Notify-div-6 {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    flex-basis: 0;
    width: 100%;
    justify-content: space-between;
  }
  .Notify-div-7 {
    display: flex;
    align-items: center;
    gap: 20px;
    font-size: 13px;
    color: #222529;
    white-space: nowrap;
    line-height: 154%;
    justify-content: space-between;
    padding: 0 1px;
  }
  .Notify-div-8 {
    font-family: Karla, sans-serif;
  }
  .Notify-img-3 {
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: 24px;
    margin-top: 16px;
  }
  .Notify-div-9 {
    display: flex;
    gap: 20px;
    font-size: 11px;
    line-height: 164%;
    justify-content: space-between;
    padding: 0 1px;
  }
  .Notify-div-10 {
    font-family: Karla, sans-serif;
  }
  .Notify-div-11 {
    border-radius: 4px;
    background-color: #d32f2f;
    align-self: start;
    width: 8px;
    height: 8px;
  }
  .Notify-div-12 {
    font: 400 10px/160% Karla, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  .No-new-notifications-text{
    padding-left: 10px;
    padding-top: 10px;
  }