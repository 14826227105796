.dashBoard-state-card {
  width: 100%;
}
.dashBoard-state-card .deal-status {
  display: flex;
  height: 22px;
  padding: 6px 8px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 4px;
  border: 1px solid #20bd5c;
  background: #fff;
  color: #20bd5c;
  font-size: 14px;
  font-weight: 500;
}
.inner-content-div {
  background: var(--linear-gradient-blue);
  padding: 12px;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  gap: 16px;
}
.dealId-div {
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  border: 0.5px dashed var(--Stroke);
  background: #fff;
  width: fit-content;
  font-size: 14px;
}
.roundType-text {
  font-size: 14px;
  color: var(--Primary-Text-Color);
  font-weight: 500;
}
.dashBoard-state-card .middle-div {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.dashBoard-state-card .roundSize-text {
  color: var(--Primary-Text-Color);
  font-size: 14px;
}
.dashBoard-state-card span {
  color: var(--Secondary-Text-Color);
}
.dashBoard-state-card .button-div {
  display: flex;
  gap: 12px;
  align-items: center;
}
.dashBoard-state-card .button-div p {
  color: var(--Secondary-Text-Color);
  font-size: 14px;
}
.dashBoard-state-card .remaining-days {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  gap: 6px;
  border-radius: 4px;
  padding: 2px 4px;
  background: #ffe4e4;
  font-weight: 500;
}
