.activity-page {
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  gap: 12px;
}
.activity-right-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.activity-deal-documents {
  width: 100%;
}
.activity-deal-documents .heading {
  color: #000;
  font-weight: 700;
}
.dealFile {
  border-radius: 4px;
  border: 0px solid #b9bbbd;
  background: #fafafa;
  display: flex;
  flex-direction: row;
  height: 38px;
  padding: 16px;
  align-items: center;
  gap: 8px;
}
.dealFile img {
  width: 24px;
  height: 24px;
}
.dealFile-name {
  color: var(--blue-color);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}
.updownicon {
  display: inline-block;
  transition: transform 0.3s ease-in-out;
}
.updownicon.rotate {
  transform: rotate(180deg);
}
.qna-question-text {
  color: var(--Secondary-Text-Color);
  font-weight: 400;
}
.QnA {
  border-bottom: 1px solid #000;
}
.Q-text {
  color: var(--Primary-Text-Color);
  font-weight: 400;
}
