/* The following css are applied to both no name page and named page */
.noNamePreview-page .InvestorSide-box {
  overflow-y: hidden !important;
}
.NoNamePreview {
  display: flex;
  flex-direction: row;
  gap: 12px;
  margin-top: 16px;
}

.NoNamePreview-left {
  width: 28%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 80px;
}
.NoNamePreview-right {
  width: 68%;
  padding-right: 1%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: calc(100vh - 100px);
  overflow: scroll;
  padding-bottom: 100px;
}
.NoName-heading-block {
  display: flex;
  flex-direction: column;
  padding: 16px;
  align-items: flex-start;
  gap: 18px;
  border-radius: 4px;
  box-shadow: var(--whiteBg-back-shadow);
  background: #fff;
}
.noName-heading {
  color: var(--Primary-Text-Color);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
}
.noName-subheading {
  color: var(--Secondary-Text-Color);
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
}
.noName-navigation {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 4px;
  box-shadow: var(--whiteBg-back-shadow);
  background: #fff;
}
.noName-nav-btn {
  display: flex;
  padding: 8px 16px;
  width: 100%;
  cursor: pointer;
}
.noName-nav-btn p {
  color: #696f79;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}
.selected-nav-btn {
  background: #fafafa;
}
.selected-nav-btn p {
  font-weight: 600;
  color: var(--Primary-Text-Color);
}
.noNameDetails-block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.noNameDetails-block .details {
  color: var(--Primary-Text-Color);
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
}

/* detailed page css */
.detailed-page-img-block img {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 5px;
  border-radius: 4px;
  height: 60px;
  width: 60px;
}
.detailed-page-img-block {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
}
.schedule-meeting-btn {
  color: #00a6fb;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.dataroom-right-section-pending-access {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
