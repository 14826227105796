@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import url("https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,100..800;1,200..800&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200..800;1,200..800&family=Roboto:wght@100;300;400;500;700;900&display=swap");
/* @import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap"); */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  font-family: "Satoshi", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root {
  --color-1: 0 100% 63%;
  --color-2: 270 100% 63%;
  --color-3: 210 100% 63%;
  --color-4: 195 100% 63%;
  --color-5: 90 100% 63%;

  --Primary-Text-Color: #1a1a1a;
  --Secondary-Text-Color: #696f79;
  --link-color: #3c82f6;
  --brand-color: #114fee;
  --background: #f6f8ff;
  --border: #d4d4d4;

  --green-color: #c2f970;
  --dark-green-color: #5db18a;
  --timeline-color: #20bd7e;
  --light-green-color: #cdeee6;
  --blue-color: #00a6fb;
  --light-blue-color: #d7f2ff;
  --pink-color: #ffe7e7;
  --Stroke: #8692a6;
  --CTA: #407bff;
  --linear-gradient-blue: linear-gradient(
    180deg,
    #00a6fb4d -252.33%,
    #e5f5fe00 160.63%
  );
  --whiteBg-back-shadow: 4px 4px 16px 0px rgba(161, 161, 161, 0.12);
  --box-shadow-1: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  --box-shadow-2: 4px 0px 16px 0px rgba(99, 99, 99, 0.05);
}
/* For Chrome, Edge, and Safari */
/* Targeting the scrollbar */
/* For Chrome, Edge, and Safari */

.custom-container {
  width: calc(100% - 32px - 32px - 56px);
  max-width: 1320px;
  margin: 0 auto;
}
@media (min-width: 1440px) {
  .custom-container {
    margin-left: calc((100% - 56px - 1320px) / 2 + 56px);
  }
}
* {
  scrollbar-width: thin; /* Firefox scrollbar */
  scrollbar-color: #f5f5f5 transparent; /* Firefox scrollbar color */
}
*::-webkit-scrollbar {
  width: 0; /* Initially hide the scrollbar */
}
*::-webkit-scrollbar-track {
  background: #f1f1f1; /* Light gray background */
  border-radius: 10px; /* Rounded corners */
}
*::-webkit-scrollbar-thumb {
  background-color: #f5f5f5; /* Scrollbar thumb color */
  border-radius: 10px; /* Rounded scrollbar */
}

*::-webkit-scrollbar-track {
  background: transparent; /* Scrollbar track background */
}

/* Show scrollbar when hovering over a scrollable element */
*::-webkit-scrollbar:hover {
  width: 12px; /* Set visible width when hovered */
}

/* For Internet Explorer 10+ */
body {
  -ms-overflow-style: -ms-autohiding-scrollbar; /* Auto-hide scrollbar for IE */
}
