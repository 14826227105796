.cdetails-div {
  display: flex;
  flex-direction: column;
  padding: 0px 20px;
}

.cdetails-div-2 {
  color: #002132;
  margin-top: 5px;
  width: 100%;
  font: 700 26px Karla, sans-serif;
}
@media (max-width: 991px) {
  .cdetails-div-2 {
    max-width: 100%;
  }
}
.cdetails-div-3 {
  align-self: start;
  display: flex;
  margin-top: 27px;
  font-size: 22px;
  font-weight: 700;
  color: #3c5168;
}
@media (max-width: 991px) {
  .cdetails-div-3 {
    flex-wrap: wrap;
  }
}
.cdetails-div-4 {
  font-family: Karla, sans-serif;
}
.cdetails-div-5 {
  color: #3c5168;
  font-family: Karla, sans-serif;
  flex-grow: 1;
  flex-basis: auto;
}
@media (max-width: 991px) {
  .cdetails-div-5 {
    max-width: 100%;
  }
}

.cardTags-row-div {
  align-self: start;
  display: flex;
  gap: 10px;
  font-size: 18px;
  color: #3c5168;
  font-weight: 700;
  line-height: 89%;
  display: flex;
  flex-wrap: wrap;
}
.cardTags-div {
  display: flex;
  padding: 4px 8px;
  font-size: 16px;
  justify-content: center;
  border-radius: 4px;
  background-color: #fafafa;
  color: #00a6fb;
}
@media (max-width: 991px) {
  .cdetails-div-8 {
    padding: 0 20px;
  }
}

.cdetails-div-11 {
  border-color: rgba(229, 229, 229, 1);
  border-style: solid;
  border-width: 1px;
  background-color: #e5e5e5;
  min-height: 1px;
  margin-top: 29px;
  width: 100%;
}
@media (max-width: 991px) {
  .cdetails-div-11 {
    max-width: 100%;
  }
}
.cdetails-div-12 {
  color: #3c5168;
  margin-top: 30px;
  width: 100%;
  font: 700 20px Karla, sans-serif;
}
@media (max-width: 991px) {
  .cdetails-div-12 {
    max-width: 100%;
  }
}
.cdetails-div-13 {
  margin-top: 16px;
  width: 100%;
  padding: 7px 0;
}
@media (max-width: 991px) {
  .cdetails-div-13 {
    max-width: 100%;
  }
}
.cdetails-div-14 {
  gap: 20px;
  display: flex;
}
@media (max-width: 991px) {
  .cdetails-div-14 {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
}
.column {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 100%;
  margin-left: 0px;
}
@media (max-width: 991px) {
  .column {
    width: 100%;
  }
}
.cdetails-div-15 {
  color: #696f79;
  font: 500 18px/27px Karla, -apple-system, Roboto, Helvetica, sans-serif;
}
.key-highlights-col {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px 35px;
}
@media (max-width: 991px) {
  .cdetails-div-15 {
    max-width: 100%;
    margin-top: 40px;
  }
}
.column-2 {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 50%;
  margin-left: 20px;
}
@media (max-width: 991px) {
  .column-2 {
    width: 100%;
  }
}
.cdetails-div-16 {
  color: #696f79;
  font: 500 18px/27px Karla, -apple-system, Roboto, Helvetica, sans-serif;
}
@media (max-width: 991px) {
  .cdetails-div-16 {
    max-width: 100%;
    margin-top: 40px;
  }
}
.cdetails-div-18 {
  display: flex;
  padding: 16px 24px 24px 24px;
  gap: 10px;
  width: 100%;
  border-radius: 4px;
  box-shadow: var(--whiteBg-back-shadow);
  border-bottom: 0px;
  background: #fff;
}
@media (max-width: 991px) {
  .cdetails-div-18 {
    max-width: 100%;
  }
}
.cdetails-div-19 {
  gap: 20px;
  display: flex;
  width: 100%;
  display: flex;
  flex-direction: row;
  line-height: normal;
  width: 100%;
  margin-left: 0px;
}
@media (max-width: 991px) {
  .cdetails-div-19 {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
}

@media (max-width: 991px) {
  .column-3 {
    width: 100%;
  }
}
.cdetails-div-20 {
  display: flex;
  flex-grow: 1;
  gap: 19px;
}
@media (max-width: 991px) {
  .cdetails-div-20 {
    margin-top: 40px;
    flex-wrap: wrap;
  }
}
.cdetails-div-21 {
  color: #696f79;
  font: 400 20px Karla, sans-serif;
  align-self: stretch;
}
.cdetails-div-22 {
  border-radius: 4px;
  box-shadow: 0px 3px 8px -1px rgba(50, 50, 71, 0.05),
    0px 0px 1px 0px rgba(12, 26, 75, 0.24);
  border-color: rgba(202, 202, 202, 1);
  border-style: solid;
  border-width: 1px;
  background-color: #fff;
  display: flex;
  margin-top: 8px;
  flex-direction: column;
  justify-content: center;
  padding: 8px 16px;
  background-color: #fafafa;
}
@media (max-width: 991px) {
  .cdetails-div-22 {
    padding: 0 20px;
  }
}
.cdetails-div-23 {
  display: flex;
  flex-direction: column;
}
.cdetails-div-24 {
  display: flex;
  white-space: nowrap;
  justify-content: space-between;
  border-bottom: 1px solid #e5e5e5;
  padding: 16px 0px;
}
@media (max-width: 991px) {
  .cdetails-div-24 {
    white-space: initial;
  }
}
.cdetails-div-27 {
  display: flex;
  white-space: nowrap;
  justify-content: space-between;
  border-bottom: 1px solid #e5e5e5;
  padding: 16px 0px;
}

@media (max-width: 991px) {
  .cdetails-div-27 {
    white-space: initial;
  }
}
.cdetails-div-25 {
  color: var(--Secondary-Text-Color);
  letter-spacing: -0.72px;
  margin: auto 0;
  font: 400 14px Karla, sans-serif;
}
.cdetails-div-26 {
  color: var(--Primary-Text-Color);
  letter-spacing: -0.88px;
  font: 700 16px Karla, sans-serif;
  text-align: end;
  text-wrap: wrap;
}

.cdetails-div-37 {
  display: flex;
  margin-top: 4px;
  flex-direction: column;
  align-items: end;
  border-left: 2px dashed #e5e5e5;
  border-right: 2px dashed #e5e5e5;
}
.current-scenario {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.cdetails-img {
  aspect-ratio: 0.98;
  object-fit: auto;
  object-position: center;
  width: 194px;
  max-width: 100%;
  margin-top: 20px;
}
@media (max-width: 991px) {
  .cdetails-img {
    margin-right: 10px;
  }
}
.cdetails-div-39 {
  display: flex;
  gap: 20px;
  text-align: justify;
  line-height: 150%;
  margin-top: 30px;
}
.cdetails-div-40 {
  color: #161616;
  font: 700 22px Karla, sans-serif;
}
.cdetails-div-41 {
  color: rgba(22, 22, 22, 0.6);
  margin-top: 8px;
  font: 400 18px Karla, sans-serif;
}
.cdetails-img-2 {
  aspect-ratio: 12.5;
  object-fit: auto;
  object-position: center;
  width: 72px;
  margin: auto 0;
}
.cdetails-div-42 {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
}
@media (max-width: 991px) {
  .cdetails-div-42 {
    white-space: initial;
  }
}
.cdetails-div-43 {
  color: #161616;
  align-self: center;
  font: 700 22px Karla, sans-serif;
}
.cdetails-div-44 {
  color: rgba(22, 22, 22, 0.6);
  margin-top: 8px;
  font: 400 18px Karla, sans-serif;
}
.cdetails-div-45 {
  display: flex;
  flex-grow: 1;
  gap: 18px;
}
@media (max-width: 991px) {
  .cdetails-div-45 {
    margin-top: 40px;
  }
}

.cdetails-div-47 {
  align-self: start;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0;
  width: fit-content;
  width: 100%;
}
.cdetails-div-48 {
  color: #3c5168;
  font: 700 20px Karla, sans-serif;
}

.cdetails-div-60 {
  color: #3c5168;
  margin-top: 30px;
  width: 100%;
  font: 700 20px Karla, sans-serif;
}
@media (max-width: 991px) {
  .cdetails-div-60 {
    max-width: 100%;
  }
}
.cdetails-div-61 {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  padding-right: 75px;
  gap: 20px;
  font-weight: 400;
  line-height: 130%;
}
@media (max-width: 991px) {
  .cdetails-div-61 {
    flex-wrap: wrap;
    padding-right: 20px;
  }
}
.cdetails-div-62 {
  border-radius: 6px;
  box-shadow: 0px 4px 3px 0px rgba(50, 50, 71, 0.05);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 14px 16px;
}
.cdetails-div-63 {
  display: flex;
  gap: 10px;
  height: fit-content;
}
.cdetails-img-3 {
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 19px;
  align-self: start;
}
.cdetails-div-64 {
  color: rgba(0, 0, 0, 0.6);
  font: 18px Karla, sans-serif;
}
.cdetails-div-65 {
  color: #00a6fb;
  margin-top: 7px;
  font: 16px Karla, sans-serif;
}
.finanicials,
.cdetails-div-45 {
  width: 33%;
}
.cdetails-div-37 {
  width: 34%;
}
.cdetails-circular-progress-bar {
  width: 180px;
  margin-top: 30px;
}
.cdetails-circular-progress-bar2 {
  width: 180px;
  margin-top: 129.5px;
}
.cdetails-circular-progress-bar .progress-bar-div {
  width: 130px;
  height: 130px;
  display: flex;
  justify-content: center;
  position: relative;
  top: -155px;
  padding: 28px;
}
.cdetails-circular-progress-bar .progress-bar-div-2 {
  font-size: 26px;
}
.cdetails-circular-progress-bar .progress-bar-div-3 {
  font-size: 18px;
}
.showInterestButtons-div {
  position: fixed;
  background-color: #fff;
  gap: 20px;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.32px;
  padding: 8px 35px 16px 35px;
  bottom: 0px;
  z-index: 2;
  width: 100%;
  left: 0px;
}
.btns-insideContent {
  width: 95%;
  max-width: 1500px;
  left: 70px;
  /* padding: 0px 30px; */
  display: flex;
  margin-left: 70px;
  justify-content: center;
  gap: 16px;
}

.showInterestButtons-div-2 {
  font-family: Karla, sans-serif;
  justify-content: center;
  border-radius: 6px;
  border-color: rgba(211, 47, 47, 1);
  border-style: solid;
  border-width: 1px;
  background-color: rgba(211, 47, 47, 0.1);
  color: #d32f2f;
  padding: 17px 30px;
  cursor: pointer;
}

.showInterestButtons-div-3 {
  font-family: Karla, sans-serif;
  justify-content: center;
  border-radius: 6px;
  background-color: #00a6fb;
  color: #fff;
  padding: 17px 30px;
  cursor: pointer;
}
@media (min-width: 1730px) {
  .showInterestButtons-div {
    width: 100%;
    margin: auto;
  }
  .btns-insideContent {
    margin-left: calc((100% - 1500px) / 2);
    padding: 0px 30px;
  }
}
.add-to-wishlist {
  border-radius: 6px;
  background-color: #e5f5fe;
  display: flex;
  gap: 15px;
  font-size: 16px;
  color: #00a6fb;
  font-weight: 500;
  letter-spacing: 0.32px;
  padding: 15px 46px 15px 21px;
  border: 0px;
  width: fit-content;
  text-wrap: nowrap;
  align-items: center;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin-right: 30px;
}
.add-to-wishlist:hover {
  background-color: #d6f0fd;
}
.cdetails-first-div {
  display: flex;
  justify-content: space-between;
}
.reason-box {
  align-items: flex-end;
  justify-content: flex-start;
}
.question-box {
  align-items: flex-start;
}
@media all and (max-width: 991px) {
  .ask-question-modal {
    display: none;
  }
}
@media all and (max-width: 1300px) {
  .reason-box {
    zoom: 75%;
  }
}
.reasons-box {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  width: 100%;
}
.reasons-col-1,
.reasons-col-2 {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.reason-checkbox {
  font-weight: 600;
  font-size: 18px;
}
.reason-submit {
  height: 40px;
  background-color: rgba(0, 166, 251, 1);
  color: rgba(255, 255, 255, 1);
  border-radius: 6px;
  font-family: "Karla", sans-serif;
  font-size: 16px;
  font-weight: 500;
  max-width: 600px;
  width: 120px;
  border: 0px;
  cursor: pointer;
  margin-top: auto;
}
.IOI-bid-submit {
  margin-top: 0px;
  max-width: initial;
}
.reason-box-first-div {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.text-box {
  width: 100%;
  display: block;
  text-align: center;
}
.text-box .heading {
  color: var(--Primary-Text-Color);
  font-weight: 700;
}
.text-box .subheading {
  margin-top: 20px;
}

.btn-box {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 30px;
  gap: 20px;
}

.btn-box .cancel {
  color: #8692a6;
  background: none;
  border: 1px solid #8692a6;
}

.btn-box .pass-deal {
  background: #d32f2f;
}

.stage-display-btn {
  display: inline-flex;
  padding: 15px 21px;
  align-items: center;
  gap: 15px;
  border-radius: 6px;
  border: 0px;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  text-wrap: nowrap;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  height: fit-content;
}
.req-pending {
  background-color: #f5f0e5;
  color: #f4bd50;
}
.req-accepted {
  background-color: #e9faf0;
  color: #00cd9e;
}
.ask-question {
  background-color: #e5f5fe;
  color: #00a6fb;
  border: 1px solid #00a6fb;
  text-transform: capitalize;
  cursor: pointer;
}
.qna-question {
  background-color: #e5f5fe;
  color: #00a6fb;
  border: 1px solid #00a6fb;
  text-transform: capitalize;
  cursor: pointer;
  padding: 12px 16px;
  font-size: 12px;
}
.cdetails-div-66 {
  font-size: 12px;
  color: #54667a;
  margin-top: 10px;
}
.round-size {
  font-size: 26px;
}
.round-size-text {
  font-size: 18px;
  color: #425466;
  bottom: -27px;
}
.InvestorSide .FundingDetails {
  margin-top: 20px;
  padding-bottom: 25px;
  border-bottom: 1px dashed #8692a6;
}
.InvestorSide .PGnwW:focus {
  outline: none;
}
.InvestorSide .table-details {
  margin-top: 30px;
}
.InvestorSide .dealTeam {
  margin-bottom: 100px;
}
.showInterestButtons-right {
  display: flex;
  gap: 20px;
}
.qna-center {
  width: 100%;
  height: 100%;
  max-height: 450px;
  overflow: scroll;
  padding: 0px 15px 0px 5px;
}
.qna-section-text {
  font-size: 20px !important;
  color: var(--Primary-Text-Color) !important;
  font-weight: 500 !important;
}
.add-folder-text {
  font-size: 24px !important;
  color: var(--Primary-Text-Color) !important;
  font-weight: 500 !important;
  margin: auto;
  font-family: "Roboto";
}
.question-input {
  margin-top: 20px;
  width: 80%;
  font-family: "Karla", sans-serif !important;
  height: 50px;
}
.post-question-btn {
  border: 0px;
  border-radius: 10px;
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 10px 15px;
  height: 40px;
}
.post-question-btn p {
  color: #fff;
  font-size: 14px;
  cursor: pointer;
}
.qna-section {
  display: flex;
  align-items: flex-end;
  gap: 20px;
  width: 100%;
}
.asked-questions-display {
  margin-top: 20px;
}
.asked-questions-display li {
  margin-left: 20px;
  margin-top: 8px;
}
.IOI-bid-title {
  font-weight: 500 !important;
  color: var(--Primary-Text-Color) !important;
  font-size: 24px !important;
}
.IOI-bid-form {
  display: flex;
  gap: 20px;
  margin-top: 20px;
  width: 100%;
}
.IOI-bid-form-left,
.IOI-bid-form-right {
  width: 50%;
}
.IOI-bid-form-label {
  font-family: "Karla", sans-serif !important;
  font-size: 16px !important;
  color: #696f79 !important;
}
.IOI-bid-datepicker {
  width: 100%;
  border-radius: 6px;
  border: 1px solid #cdd7e1 !important;
  font-size: 16px;
  height: 70px;
}
.IOI-bid-input {
  height: 64px;
  width: 100%;
}
.IOI-bid-form-input {
  height: 130px;
}
.IOI-bid-form .css-hoq2ao {
  margin-bottom: 0px;
}
.IOI-bid-form .css-160unip {
  margin-bottom: 0px;
  padding-top: 0px;
}
.IOI-bid-form .css-1bn53lx {
  height: 100%;
}
.IOI-bid-form .fileUploads {
  width: 100% !important;
}
/* .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  height: 68px;
} */
.css-4jnixx-MuiStack-root {
  padding-top: 0px !important;
}
.IOI-bid-inputs-input {
  height: 64px;
}
.TimeLine-div {
  border-bottom: 1px dashed #8692a6;
  padding-bottom: 30px;
}
.TimeLine-text {
  color: #696f79;
  font-size: 20px;
  font-weight: 500;
}
.tm-div {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}
.tm-div-2 {
  display: flex;
  gap: 6px;
  align-items: center;
}

.last-tr td {
  border-bottom: 0px;
}

.dataroom-action-btn {
  height: 31px;
}
@media (max-width: 991px) {
  .tm-div-2 {
    flex-wrap: wrap;
  }
}
@media (max-width: 991px) {
  .tm-div-2 {
    flex-wrap: wrap;
  }
}
.tm-div-3,
.tm-div-8 {
  display: flex;
  flex-direction: column;
  text-align: center;
  position: relative;
  width: 120px;
}
.tm-div-4 {
  text-wrap: nowrap;
  color: #696f79;
  font: 500 12px/150% Karla, -apple-system, Roboto, Helvetica, sans-serif;
}
.tm-img {
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 42px;
  align-self: center;
  margin-top: 15px;
}
.tm-div-5 {
  color: #465668;
  margin-top: 10px;
  font: 500 14px Karla, sans-serif;
}

.tm-div-5-filled {
  color: #00a6fb;
  margin-top: 10px;
  font: 700 14px Karla, sans-serif;
}

.tm-div-6 {
  width: 35px;
  text-wrap: nowrap;
  margin: auto 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  padding-bottom: 10px;
  color: #00a6fb;
}
@media all and (min-width: 1600px) {
  .tm-div-6 {
    padding-bottom: 0px;
  }
}
.tm-div-7 {
  color: #465668;
  text-align: center;
  margin-top: 10px;
  font: 500 14px Karla, sans-serif;
}
.not-visible {
  visibility: hidden;
}
/* .tm-div-3::after,
.tm-div-8::after {
  content: ". . . . . .";
  position: relative;
  left: 60px;
} */
.tm-div-3::after {
  color: #00a6fb;
}
.last-tm-div::after {
  visibility: hidden;
}
.tm-div-9 {
  text-wrap: nowrap;
  position: absolute;
  bottom: -25px;
  color: #114fee;
  text-align: center;
  text-decoration-line: underline;
  align-self: center;
  cursor: pointer;
  font-weight: 700;
}
.company-activity-div {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 100px;
}
.all-file-uploads {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}
.QnA-question {
  font-weight: 500;
  font-size: 18px;
  margin: 10px 0px;
  display: flex;
  align-items: center;
}
.QnA-answer {
  font-size: 18px;
  color: #54667a;
  margin-bottom: 10px;
}
.QnA-div {
  margin-top: 30px;
}
.qna-middle-section {
  padding: 0px 15px;
  border: 1px solid #e0dad9;
  border-radius: 10px;
  margin-top: 20px;
}
.qna-middle-section-qa p {
  font-size: 14px;
  color: #494949;
  border-bottom: 1px dashed #e0dad9;
  padding: 15px 0;
}
.last-qa p {
  border-bottom: 0px;
}
.analytics-container {
  margin-bottom: 100px;
}
.request-to-edit-btn {
  color: #00a6fb;
  border: 1px solid #00a6fb;
  text-transform: capitalize;
  cursor: pointer;
  padding: 12px 16px;
  font-size: 14px;
  margin-top: 20px;
  text-wrap: nowrap;
  border-radius: 10px;
  display: flex;
  align-items: center;
  height: fit-content;
}
.companyOnBoarding-comment-box {
  background-color: #f6f6f6 !important;
  position: absolute !important;
  bottom: 0px;
  width: 92.5%;
  left: 2.5%;
  bottom: 20px;
  margin: auto;
  height: 100px;
  padding: 10px 15px !important;
  font-size: 14px;
  color: #67727e;
}
.companyOnBoarding-add-msg-btn {
  bottom: 30px;
  right: 30px;
  position: absolute;
  display: flex;
}
.messages-box {
  height: calc(100vh - 200px);
  overflow-y: scroll;
  padding-top: 20px;
}
.companyOnboarding-message-div {
  width: 95%;
  margin: auto;
}
.comments-div {
  display: flex;
  flex-wrap: nowrap;
  text-wrap: nowrap;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: var(--Primary-Text-Color);
  border-radius: 6px;
  padding: 9px 15px;
  cursor: pointer;
  height: fit-content;
}
.comments-div p {
  display: flex;
  align-items: center;
}
.IOI-bid-form .css-b62m3t-container {
  width: 100%;
}
.info-icon {
  width: 15px;
  padding-bottom: 7px;
}
.investment-type-text-div {
  display: flex;
  align-items: center;
  gap: 5px;
}
.investment-type-info {
  position: absolute;
  z-index: 5;
  top: -40px;
  left: 150px;
  width: 300px;
  transition: opacity 0.3s ease-in-out;
  pointer-events: none;
}
.form-green-text {
  color: #00cd9e !important;
  font-size: 14px !important;
}
.input-below-text {
  margin-top: 10px !important;
}
.opdiv-ask-question-button {
  border: 0px;
  padding: 10px 30px;
  margin: auto;
  background-color: transparent;
  color: #fff !important;
  border-radius: 6px;
  font: 500 16px Roboto, sans-serif;
  cursor: pointer;
  transition: all 0.3s;
  background: linear-gradient(#0d2c3c, #0d2c3c) padding-box,
    linear-gradient(to right, #1051ee, #5bf9a3) border-box;
  border: 2px solid transparent;
}
.opdiv-ask-question-button:hover {
  background: linear-gradient(#002132, #002132) padding-box,
    linear-gradient(to right, #1051ee, #5bf9a3) border-box;
}
.investorDetailsPage-header {
  display: flex;
  position: relative;
}
.investorDetailsPage-header .cdetails-first-div {
  width: 100%;
}
.answer-question-div {
  border: 1px solid var(--Stroke);
  border-radius: 10px;
  width: 100%;
  padding: 10px;
  margin: 20px 0px;
}
.answer-question-div .QnA-question {
  margin-top: 0px;
  padding-bottom: 10px;
  font-weight: 400 !important;
  border-bottom: 1px dashed #e0dad9;
}
.opimg-3 {
  transition: transform 0.3s ease;
}

.company-stats {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}
.company-info-stats {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  flex-direction: column;
}
.comments-div-img {
  width: 20px;
}
.comments-div img {
  width: 20px;
}
/* No Name preview page */
@media all and (max-width: 991px) {
  .noNameDeal .cdetails-div {
    margin: 0px 0px 40px 0px !important;
    background-color: #fff;
    padding: 10px;
  }
  .noNameDeal .company-logo {
    top: 25px;
  }
  .noNameDeal .InvestorSide-box {
    margin-top: 70px;
  }
  .noNameDeal .cdetails-div-2 {
    font-size: 24px;
  }
  .noNameDeal .mobile-stats {
    display: flex !important ;
    flex-direction: column;
    gap: 20px;
  }
  .noNameDeal .cdetails-subheading {
    font-size: 18px;
    font-weight: 500;
  }
  .noNameDeal .cdetails-div-15 {
    font-size: 16px;
  }
  .noNameDeal .cdetails-div-8 {
    padding: 10px 15px;
    font-size: 16px;
  }
  .noNameDeal .key-highlights-col {
    grid-template-columns: 1fr;
    margin-top: 0px;
    gap: 10px;
  }
  .noNameDeal .cdetails-div-12 {
    font-size: 18px;
  }
  .noNameDeal .finanicials,
  .noNameDeal .cdetails-div-37 {
    width: 100%;
    border: 0px;
  }
  .noNameDeal .column-3,
  .noNameDeal .cdetails-div-20 {
    margin-top: 0px !important;
  }
  .noNameDeal .cdetails-div-22 {
    padding: 20px;
  }
  .noNameDeal .cdetails-div-21 {
    margin-left: 0px !important;
  }
  .noNameDeal .cdetails-div-47,
  .noNameDeal .cdetails-div-23 {
    padding: 0px;
  }
  .noNameDeal .cdetails-div-45 {
    margin-top: 20px;
  }
}

.details-li-tag {
  color: #696f79;
  font-size: 16px;
  font-weight: 400;
}
.DetailedCardFinancials-heading {
  color: #696f79;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 100;
  margin-bottom: 5px;
}
.DetailedCardFinancials-subheading {
  color: #696f79;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 300;
}
.DetailedCardFinancials-tableText {
  color: #696f79;
  font-family: Karla;
  font-size: 14px;
  font-weight: 300;
}
.DetailedCardFinancials-tableText-value {
  color: #1a1a1a;
  font-family: Karla;
  font-size: 16px;
  font-weight: 600;
}
