/* .signUp-div {
  display: flex;
  min-height: 100vh;
  background-color: #fff;
} */
.signUp-page {
  min-height: 100vh;
  width: 100vw;
  background-color: #f6f8ff;
}
.signUp-header {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 40px 0px 0px 0px;
}
.signUp-header img {
  height: 40px;
}
.signUp-middle {
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: fit-content;
  max-width: 1100px;
  text-align: center;
  margin-top: 160px;
}
.signUp-middle-box {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  border-radius: 24px;
  background: #fff;
  box-shadow: var(--whiteBg-back-shadow);
  max-width: 90%;
  margin: auto;
}
.signUp-middle-box .upper-section {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.signUp-middle-box .upper-section .text-heading {
  color: var(--Primary-Text-Color);
  font-size: 20px;
  font-weight: 600;
}
.signUp-middle-box .upper-section .text-subheading {
  color: var(--Secondary-Text-Color);
  font-size: 16px;
  font-weight: 400;
}
.signUp-middle-box .main-section {
  display: flex;
  flex-direction: row;
  gap: 40px;
  justify-content: space-between;
}
.signUp-middle-box .main-section .main-section-box {
  display: flex;
  height: 98px;
  padding: 12px 16px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 8px;
  border: 0.5px solid var(--Stroke);
  box-shadow: var(--whiteBg-back-shadow);
}
.signUp-middle-box .main-section .user-statement-div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.user-statement-div .text-heading {
  color: var(--Primary-Text-Color);
  font-size: 20px;
  font-weight: 400;
}
.user-statement-div .text-subheading {
  color: var(--Secondary-Text-Color);
  font-size: 16px;
  font-weight: 400;
  text-align: left;
}
.signUp-lower-section p {
  color: var(--Primary-Text-Color);
  font-family: Karla;
  font-size: 16px;
  font-weight: 400;
}
.link-blue-text {
  cursor: pointer;
  color: var(--link-color);
  font-size: 16px;
  font-weight: 600;
}
.signUp-middle-box .main-section-box:hover {
  border: 0.5px solid var(--Brand, #114fee);
  background: #f6f8ff;
  box-shadow: 0px 4px 4px 1px rgba(26, 26, 26, 0.08);
}
@media all and (max-width: 576px) {
  .signUp-middle-box .main-section {
    flex-direction: column;
  }
  .signUp-middle-box {
    padding: 16px;
  }
  .signUp-middle-box .main-section .main-section-box {
    height: fit-content;
  }
  .signUp-lower-section {
    position: absolute;
    bottom: 24px;
    text-align: center;
    width: 100%;
  }
  .signUp-header {
    justify-content: left;
    padding-left: 16px;
  }
  .signUp-header img {
    height: 16px;
  }
  .signUp-middle {
    margin-top: 60px;
  }
  .signUp-middle-box .upper-section .text-heading {
    font-size: 16px;
  }
  .signUp-middle-box .upper-section .text-subheading {
    font-size: 14px;
  }
  .user-statement-div .text-heading {
    font-size: 16px;
  }
  .user-statement-div .text-subheading {
    font-size: 12px;
  }
}
