.signNDA-first-div {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.finalize-nda-btn {
  border-radius: 6px;
  background-color: var(--blue-color);
  color: #fff;
  padding: 17px 30px;
  cursor: pointer;
  font-size: 18px;
  border: 0px;
}
.NDA-document {
  background-color: #f7f7f7;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 16px;
  border: 1px solid #e6e7ea;
  border-radius: 10px;
  margin-top: 16px;
  align-items: center;
}
.NDA-document-left {
  display: flex;
  gap: 8px;
  margin-left: 16px;
  align-items: center;
}
.NDA-document-name {
  font-size: 16px;
  color: #3c5168;
  font-weight: 600;
  text-wrap: nowrap;
}
.NDA-document-right {
  margin-right: 30px;
}
.message-div {
  border-radius: 8px;
  border-color: rgba(229, 229, 229, 1);
  border-style: solid;
  border-width: 2px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 16px;
  position: relative;
  margin-bottom: 20px;
}
@media (max-width: 991px) {
  .message-div {
    padding: 0 20px;
  }
}
.message-div-2 {
  align-items: center;
  align-self: start;
  display: flex;
  gap: 15px;
  justify-content: space-between;
}
.message-img {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  align-self: stretch;
}
.message-div-3 {
  color: var(--Primary-Text-Color);
  margin: auto 0;
  font: 700 16px Karla, sans-serif;
}
.message-div-4 {
  color: #67727e;
  align-self: stretch;
  font: 400 12px/24px Karla, -apple-system, Roboto, Helvetica, sans-serif;
}
.message-div-5 {
  color: var(--Secondary-Text-Color);
  margin-top: 8px;
  font: 400 14px/24px Karla, -apple-system, Roboto, Helvetica, sans-serif;
}
.message-div-6 {
  font-size: 10px;
  color: var(--Secondary-Text-Color);
}
.activity-text {
  font-size: 20px;
  color: var(--Primary-Text-Color);
  font-weight: 500;
  margin: 12px 0px;
}
@media (max-width: 991px) {
  .message-div-5 {
    max-width: 100%;
  }
}
.message-div .edit-option {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  gap: 5px;
  display: flex;
}
.message-div .edit-option img {
  margin: 0px 7px;
}
.add-comment-div {
  border-radius: 8px;
  border-color: rgba(229, 229, 229, 1);
  border-style: solid;
  border-width: 1px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 16px;
  position: fixed;
  bottom: 16px;
  width: calc(100% - 370px);
  max-width: 1200px;
}
@media (max-width: 991px) {
  .add-comment-div {
    padding: 0 20px;
  }
}
@media (min-width: 1760px) {
}
.add-comment-div-2 {
  color: #67727e;
  font-family: "Karla", sans-serif !important;
  border: 0px !important;
  background-color: #fff !important;
  outline: none !important;
  box-shadow: none !important;
  --Textarea-focusedHighlight: #fff !important;
}
@media (max-width: 991px) {
  .add-comment-div-2 {
    max-width: 100%;
  }
}
.add-comment-div-3 {
  align-self: end;
  display: flex;
  margin-top: 13px;
  gap: 20px;
  justify-content: space-between;
}
.add-comment-img {
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 15px;
  margin: auto 0;
  cursor: pointer;
}
.add-comment-img-2 {
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  cursor: pointer;
  width: 29px;
}

.attach-file-delete {
  cursor: pointer;
  width: 10px;
}
.replying-to {
  font-size: 16px;
  color: var(--Primary-Text-Color);
  font-weight: 500;
}
.message-div-1 {
  padding: 16px 0px;
  border-top: 1px solid #e5e5e5;
}
.message-div-first {
  padding-bottom: 16px;
}
.message-div-last {
  padding-bottom: 0px;
}
.you-btn {
  background-color: #ccedfe;
  color: var(--blue-color);
  padding: 3px 8px 5px 8px;
  border-radius: 5px;
  border: 0px;
  font-size: 14px;
}
.replying-to-div {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.replying-to-cross {
  cursor: pointer;
  margin-right: 30px;
  width: 10px;
}
.message-div-2-left {
  display: flex;
  gap: 8px;
}
.edit-cross {
  cursor: pointer;
  width: 25px;
  position: absolute;
  right: 40px;
  top: 5px;
}
.NDA-filter-box {
  background-color: #fff;
  padding: 8px 0px;
  width: fit-content;
  position: absolute;
  z-index: 10;
  right: 0px;
  top: 55px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  border: 0.5px solid #eee;
  box-shadow: var(--whiteBg-back-shadow);
}
.filter-box-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-top: 12px;
  gap: 12px;
}
.filter-box-radio {
  border-bottom: 1px solid #cecdd1;
  width: 100%;
}
.resolvedBy-div {
  display: flex;
  align-items: center;
  gap: 6px;
  flex-direction: row;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 2px 0px rgba(12, 26, 75, 0.24),
    0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  padding: 10px 12px;
  font-size: 12px;
  color: var(--blue-color);
}
.resolved-by {
  font-style: italic;
}
.nda-bold-text {
  font-weight: 600;
}
.resolved-div {
  background-color: #f2f6f8;
  border-color: #91d6f9;
}
.MessagePage-first-div {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.MessagePage-stage {
  font-size: 30px;
  color: var(--Primary-Text-Color);
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  display: flex;
}
.messagePage-btns {
  display: flex;
  gap: 8px;
  align-items: center;
}
.IOI-bids-details {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: #fafafa;
  padding: 12px 24px;
  border-radius: 10px;
  border: 1px solid #e5e5e5;
}
.IOI-bid-details-left,
.IOI-bid-details-right {
  width: calc(50% - 5px);
  display: flex;
  flex-direction: column;
}
.IOI-bid-details-left {
  border-right: 1px solid #b9bbbd;
}
.IOI-bid-details-right {
  padding-left: 20px;
}
.IOI-bid-details-row {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}
.IOI-bid-details-row-first {
  font-size: 14px;
  width: 60%;
  color: var(--Secondary-Text-Color);
  font-weight: 300;
}
.IOI-bid-details-row-second {
  width: 40%;
  font-size: 16px;
  color: var(--Primary-Text-Color);
}
.IOI-bid-document {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #f7f7f7;
  border: 2px solid #e5e5e5;
  border-radius: 10px;
  padding: 10px 15px;
  justify-content: space-between;
}
.IOI-bid-document-name {
  font-size: 14px;
  color: #3c5168;
  font-weight: 600;
}
.IOI-bid-doument-left {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
@media all and (max-width: 1300px) {
  .css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
    top: 185px !important;
  }
}
.version-text {
  font-size: 14px;
  color: #696f79;
}
.IOI-bid-document-upload-time {
  margin-top: 5px;
  font-size: 10px;
  color: #67727e;
}
.view-only-btn {
  background-color: #e5f5fe;
  border: 1px solid var(--blue-color);
  color: var(--blue-color);
  padding: 15px 25px;
  border-radius: 5px;
  font-size: 20px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 10px;
}
.upload-file-div {
  border-radius: 8px;
  border-color: var(--stroke);
  border-style: dashed;
  border-width: 1px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 80px;
}

.upload-file-div-2 {
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  background-color: #e5f5fe;
  display: flex;
  width: 58px;
  height: 58px;
  padding: 0 9px;
}
.upload-file-img {
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 100%;
  cursor: pointer;
}
.upload-file-div-3 {
  justify-content: center;
  display: flex;
  margin-top: 12px;
  gap: 4px;
  font-size: 18px;
  padding: 0 15px;
}
.upload-file-div-4 {
  color: var(--blue-color);
  font-family: Karla, sans-serif;
  font-weight: 500;
  cursor: pointer;
}
.upload-file-div-5 {
  color: var(--Secondary-Text-Color);
  font-family: Karla, sans-serif;
  font-weight: 400;
}
.upload-spa-div-6 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.upload-spa-div {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
  justify-content: flex-end;
}
.upload-spa-div button:hover {
  background-color: var(--blue-color);
  color: #fff;
  border-color: var(--blue-color);
}
.upload-modal-spa-div {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.SPA-modal-upload {
  width: 100%;
  margin-top: 30px;
  height: 270px;
}
.SPA-modal-upload .upload-file-div {
  height: 100%;
  display: flex;
  justify-content: center;
}
.SPA-modal-btns {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  margin-top: 20px;
}
.SPA-modal-cancel-btn {
  background-color: #fff;
  color: #8692a6;
  border: 1px solid #8692a6;
  padding: 15px 25px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 20px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 10px;
}
.spa-modal-submit-btn {
  background-color: var(--blue-color);
  color: #fff;
  padding: 15px 25px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 20px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 10px;
  border: 0px;
}
.SPA-document {
  margin-top: 0px;
}
.file-time-stamp {
  color: #67727e;
  font-size: 12px;
}
.stage-status {
  display: flex;
  gap: 20px;
  display: flex;
  align-items: center;
}
.upload-modal-spa-div {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.upload-modal-spa-img {
  width: 66px;
}
.editRequest-file-upload {
  width: 92.5%;
  margin: 10px 0% 50px 2.5% !important;
}
.agreement-right-section {
  width: 82%;
  height: fit-content;
}
.spa-negotiation-status {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}
.stage-status-btn {
  padding: 6px;
  border-radius: 4px;
  font-size: 14px;
  color: var(--blue-color);
  border: 1px solid var(--blue-color);
}
.deal-closed-status {
  padding: 6px;
  border-radius: 4px;
  font-size: 14px;
  color: var(--dark-green-color);
  border: 1px solid var(--dark-green-color);
}
.agreement-action-btns-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
