.Card-div {
  justify-content: center;
  border-radius: 8px;
  box-shadow: 0px 0px 1px 0px rgba(12, 26, 75, 0.24),
    0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  background-color: #fff;
  display: flex;
  width: 336px;
  flex-direction: column;
  padding: 8px;
  position: relative;
}
.first-card-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Card-div-2 {
  font-feature-settings: "clig" off, "liga" off;
  justify-content: center;
  border-radius: 2px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
  background-color: #00cd9e;
  align-self: start;
  color: #fff;
  white-space: nowrap;
  padding: 2px 10px;
  font: 700 12px/117% Karla, -apple-system, Roboto, Helvetica, sans-serif;
}
/* .card-section-1 {
  display: flex;
  padding: 8px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 4px;
  background: var(--linear-gradient-blue);
} */
.Card-div-3 {
  color: var(--Primary-Text-Color);
  font: 600 16px Karla, -apple-system, Karla, Helvetica, sans-serif;
  line-height: 136.023%;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
}
.Card-div-4 {
  color: var(--Primary-Text-Color);
  font-feature-settings: "salt" on;
  font-family: Karla;
  font-size: 14px;
  font-weight: 400;
  line-height: 23px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.Card-div-5 {
  display: flex;
  align-items: center;
  margin: 8px 0px;
  gap: 8px;
  font-size: 12px;
  color: #3c5168;
  font-weight: 700;
  line-height: 133%;
  flex-wrap: wrap;
  width: 100%;
}
.Card-div-6 {
  font-family: Karla, sans-serif;
  border-radius: 4px;
  background-color: #fff;
  padding: 4px 8px;
  width: fit-content;
  text-wrap: nowrap;
}
.Card-section-2 {
  padding: 8px 16px;
  margin-top: 8px;
  border-radius: 4px;
  border: 0.5px dashed rgb(0, 166, 251, 0.5);
}
.Card-div-9 {
  display: flex;
  justify-content: space-between;
  padding: 0 2px;
}
.Card-div-10 {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
}
.Card-div-11 {
  color: var(--Primary-Text-color);
  font-size: 16px;
  font-weight: 500;
}
.Card-div-12 {
  color: var(--Primary-Text-color);
  font-family: Karla;
  font-size: 11px;
  font-weight: 400;
}

.Card-div-21 {
  color: rgba(0, 0, 0, 0.6);
  font-feature-settings: "clig" off, "liga" off;
  letter-spacing: -0.48px;
  font: 400 12px Karla, sans-serif;
}
.card-section-3 {
  display: flex;
  margin-top: 8px;
  padding: 0 1px;
  width: 100%;
  position: relative;
}
.Card-div-22 {
  padding: 12px 16px;
  gap: 8px;
  background: var(--linear-gradient-blue);
  border-radius: 4px;
}
.Card-div-23 {
  display: flex;
  flex-direction: column;
  margin: auto 0;
  width: 60%;
}
.circular-progress-bar {
  width: 100px;
  height: 100px;
}
.Card-div-24 {
  padding: 4px 8px;
  font-family: Karla;
  font-size: 12px;
  font-weight: 600;
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
}
.Card-div-25 {
  display: flex;
  margin-top: 26px;
  gap: 10px;
  padding: 0 1px;
}
.Card-div-26 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Card-div-27 {
  color: #161616;
  font-feature-settings: "clig" off, "liga" off;
  letter-spacing: -0.72px;
  font: 700 18px Karla, sans-serif;
}
.Card-div-28 {
  color: rgba(0, 0, 0, 0.6);
  font-feature-settings: "clig" off, "liga" off;
  letter-spacing: -0.48px;
  font: 400 12px Karla, sans-serif;
}
.Card-img {
  aspect-ratio: 12.5;
  object-fit: auto;
  object-position: center;
  width: 49px;
  margin: auto 0;
}
.Card-div-29 {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
}
.Card-div-30 {
  color: #161616;
  font-feature-settings: "clig" off, "liga" off;
  letter-spacing: -0.72px;
  align-self: center;
  font: 700 18px Karla, sans-serif;
}
.Card-div-31 {
  color: rgba(0, 0, 0, 0.6);
  font-feature-settings: "clig" off, "liga" off;
  letter-spacing: -0.48px;
  font: 400 12px Karla, sans-serif;
}
.Card-div-32 {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.Card-div-33 {
  display: flex;
  gap: 5px;
  flex-direction: row;
}
.Card-div-34 {
  font-size: 14px;
  color: var(--Primary-Text-color);
  font-weight: 500;
}
.Card-div-35 {
  font-size: 14px;
  font-weight: 600;
}
.Card-img-2 {
  aspect-ratio: 0.95;
  object-fit: auto;
  object-position: center;
  width: 104px;
  max-width: 100%;
}
.progress-bar-div {
  background-color: #e5f5fe;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 72px;
  height: 72px;
  padding: 18px 12px;
}
.progress-bar-div-2 {
  color: #2e2e30;
  font: 700 16px Karla, sans-serif;
}
.progress-bar-div-3 {
  color: #425466;
  font: 500 10px Karla, sans-serif;
}
.progress-bar-div-4 {
  position: absolute;
  bottom: -12px;
  color: #2e2e30;
  font-family: "Karla";
  font-size: 16px;
  font-weight: 700;
  line-height: 140%;
}
.progress-bar-div-5 {
  position: absolute;
  bottom: -20px;
  color: #425466;
  font-family: "Karla";
  font-size: 10px;
  font-weight: 700;
  line-height: 140%;
}
.card-star {
  height: 24px;
  position: absolute;
  right: 24px;
}
.card-raised-text {
  font-family: Karla !important;
  font-size: 10px !important;
  font-weight: 500 !important;
}
.analyst-tag {
  position: absolute;
  right: 8px;
  display: inline-flex;
  padding: 4px 8px;
  justify-content: center;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  align-items: center;
  background: linear-gradient(-90deg, #59ae87 0%, #2e7d32 100%);
}
.analyst-tag p {
  color: white;
  font-size: 10px;
  font-weight: 600;
}
.deals-box {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding-top: 20px;
  gap: 24px 0px;
}
.Card-div-36 {
  color: var(--Secondary-Text-Color);
  font-size: 12px;
  font-weight: 400;
  margin-top: 0px !important;
}
.Card-div-37 {
  color: var(--Primary-Text-Color);
  font-size: 12px;
  font-weight: 500;
}
.Card-div-38 {
  background-color: #fff;
  padding: 2px 4px;
  border-radius: 4px;
  width: fit-content;
  border: 1px dashed var(--Stroke);
}
@media all and (max-width: 1400px) {
  .deals-box {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding-top: 20px;
    gap: 24px 0px;
  }
}
@media all and (max-width: 1100px) {
  .deals-box {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-top: 20px;
    gap: 24px 0px;
  }
}
.pending-profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  gap: 24px;
  transition: height 0.3s ease, opacity 0.3s ease;
  height: 0px;
}
.pending-profile p {
  color: #ff9e2c;
  font-size: 16px;
  text-align: center;
}
.card-div-img-section {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}
.card-div-img-section img {
  width: 40px;
  height: 40px;
  border-radius: 8px;
}
.card-div-img-section p {
  font-size: 16px;
  color: var(--Primary-Text-Color);
}
