.table-container {
  margin: 20px 0px;
  overflow-x: auto;
}
.dealDetailsEdit {
  background-color: var(--background);
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #d9d9d9;
}
.col_container {
  display: flex;
  align-items: center;
}

.table-container td {
  min-width: 200px;
}

th {
  background-color: #f2f2f2;
}

.toggleStatusbtn {
  padding: 6px 10px;
  border: none;
  border-radius: 4px;
  background-color: #00a6fb;
  color: #fff;
  cursor: pointer;
}

.toggleStatusbtn:hover {
  background-color: #0056b3;
}
.Approved-btn {
  color: #00cc9e;
  background-color: #d4fff5;
}
.Approved-btn:hover {
  color: #fff;
  background-color: #00cc9e;
}
.Pending-btn {
  color: #f4bd50;
  background-color: #fef9ee;
}
.Pending-btn:hover {
  color: #fff;
  background-color: #f4bd50;
}

.download_btn {
  background-color: #e5f5fe;
  border: 1px solid #00a6fb;
  padding: 6px 10px;
  border-radius: 4px;
  cursor: pointer;
  color: #00a6fb;
}
.download_btn:hover {
  background-color: #00a6fb;
  color: white;
}
.file-remove {
  font-size: 15px !important;
  color: red;
  margin-left: 10px;
}

.industry_focus {
  height: 500px;
  overflow-y: scroll;
}
.admin-panel-page {
  padding: 30px;
}
/* .dealDetailsEdit .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: 0px;
} */
.dealDetailsEdit-box {
  margin-top: 80px;
  margin-left: 280px;
  height: calc(100vh - 80px);
  overflow: scroll;
}
.admin-Funding-details-div {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.admin-Funding-details-div,
.noName-details-div,
.calculated-fields-div,
.DataAvailabilityWithFundrev-div {
  margin-top: 40px;
}
.hightlights-div {
  margin-top: 20px;
  margin-left: 20px;
}
.admin-dealdetailsedit-box {
  height: 70vh;
  overflow: scroll;
  padding: 16px;
}
@media all and (min-width: 1700px) {
  .admin-dealdetailsedit-box {
    height: 80vh;
  }
  .dealDetailsEdit-box {
    max-width: 1500px;
    margin-left: calc(50vw - 750px + 130px);
  }
}
.dealDetailsEdit .next-buttons {
  gap: 10px;
  display: flex;
  align-items: center;
}
.dealDetailsEdit .save-button {
  padding: 18px 30px;
  font-size: 14px;
  height: 40px;
  display: flex;
  align-items: center;
  color: #00a6fb;
  border: 1px solid #00a6fb;
  border-radius: 6px;
  background-color: #fff;
}
.dealDetailsEdit .nextStep {
  width: 120px !important;
  height: 40px !important;
  font-size: 14px !important;
  background-color: #00a6fb;
  border-radius: 6px;
  color: #fff;
  border: 0px;
}
.dealDetailsEdit .navigation-buttons {
  margin: 10px 0px 20px 0px;
}
.DataAvailability-child-div {
  margin-right: 30px;
}
.foundingTeamDetailsForm .investorDetails-first-section {
  margin: 0px;
  width: calc(100% - 240px);
}
.foundingTeamDetailsForm {
  margin: 0px;
}
.foundingTeamDetails-nav-btns {
  display: flex;
  justify-content: space-between;
  max-width: 1400px;
  margin: 0px 50px;
}
@media all and (max-width: 1600px) {
  .foundingTeamDetailsForm {
    max-height: 600px;
    overflow: scroll;
  }
}
@media all and (min-width: 1600px) {
  .foundingTeamDetailsForm {
    max-height: 80vh;
    overflow: scroll;
  }
  .foundingTeamDetailsForm .investorDetails-first-section {
    width: 100%;
  }
}
@media all and (max-width: 1600px) {
  .adminSide .InvestorSide-box {
    margin-left: 260px !important;
  }
}
