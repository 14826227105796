.sign-in-page {
  min-height: 100vh;
  width: 100vw;
  background-color: #f6f8ff;
}

.sign-in-page-header {
  width: 100%;
  padding: 40px 0px 0px 40px;
  /* padding: 20px 0px 0px 40px; */
}
.sign-in-page-header img {
  height: 24px;
}

.sign-in-block {
  margin: 16px auto 0px auto;
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding-bottom: 80px;
}
.sign-in-block .heading-block {
  text-align: center;
}
.sign-in-block .middle-block {
  margin: auto;
  width: 448px;
  display: flex;
  padding: 32px;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  border-radius: 24px;
  background: #fff;
  box-shadow: 4px 4px 16px 0px rgba(161, 161, 161, 0.12);
  max-width: 90%;
}
.sign-in-input-fields {
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  gap: 24px;
}
.sign-in-input-fields .first-section {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.sign-in-btn-section {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}
.forgot-password p {
  cursor: pointer;
  color: #696f79;
  font-size: 14px;
  font-weight: 400;
  text-decoration-line: underline;
}
@media all and (max-width: 576px) {
  .sign-in-page-header {
    padding: 16px 0px 0px 16px;
  }
  .sign-in-block {
    margin-top: 24px;
    gap: 16px;
    width: 90%;
  }
  .sign-in-block .middle-block {
    flex-direction: column;
    position: relative;
    margin-bottom: 40px;
    box-shadow: none;
    background-color: transparent;
    padding: 0px;
    max-width: 100%;
  }
  .sign-in-input-fields {
    border-radius: 24px;
    padding: 16px;
    background: #fff;
    box-shadow: 4px 4px 16px 0px rgba(161, 161, 161, 0.12);
  }
  .sign-in-btn-section {
    position: fixed;
    bottom: 0px;
    left: 0px;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    padding: 16px;
    gap: 16px;
    background: #f6f8ff;
  }
}
