.modal-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 700px;
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(239, 240, 246, 1);
  box-shadow: 0px 5px 16px 0px rgba(8, 15, 52, 0.06);
  padding: 32px;
  border-radius: 34px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

#modal-modal-title {
  font-size: 24px;
  font-weight: 700;
  margin: 20px 0px;
  text-align: center;
}

#modal-modal-description {
  font-size: 18px;
  color: rgba(134, 146, 166, 1);
  text-align: center;
  font-family: "Karla", sans-serif;
  margin: 0px 0px 20px 0px;
}
.modal-box .calendly-badge-widget {
  margin: 20px 0px 0px 35px;
}
.modal-button {
  width: 80%;
  height: 64px;
  background-color: rgba(0, 166, 251, 1) !important;
  font-weight: 500 !important;
  font-family: "Karla", sans-serif !important;
  font-size: 16px;
}
.modal-heading {
  font-size: 20px;
  font-family: Roboto;
  columns: var(--Primary-Text-Color);
  text-align: center;
}
.modal-content {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.modal-left-section {
  width: 0%;
}
.modal-right-section {
  width: 100%;
}
.IOI-first-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px 60px;
  width: 100%;
}
.IOI-first-section .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input,
.dealDetails .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input,
.company-sign-up-page-2nd-page
  .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  height: 16px !important;
}
.IOI-first-section .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-color: #8692a6 !important;
}
.IOI-first-section .css-q70vsn-MuiButtonBase-root {
  justify-content: flex-start;
}
.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: #3c82f6 !important;
}
.ioi-endDecorator {
  color: #05c168;
  font-size: 12px;
  width: 100%;
}
.helper-text-input {
  color: var(--Secondary-Text-Color);
  font-size: 14px;
  margin: 0px;
  height: 0px;
}
.IOI-bid-modal-heading-section {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 24px;
}
.IOI-bid-modal-heading-section p {
  color: var(--Secondary-Text-Color);
  font-size: 20px;
  font-weight: 300;
}
