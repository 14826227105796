@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;800&family=Poppins:wght@300;400;500;600;700&display=swap");

:root {
  --sidebar-color: #fff;
  --primary-color: #f4f2f2;
  --primary-color-light: #f4f2f2;
  --toggle-color: #ddd;
  --text-color: #fff;
  --dark-color: #000;
  --font-size: 16px;
  --big-font-size: 20px;
  --trans-02: all 0.2s ease;
  --trans-03: all 0.3s ease;
  --trans-04: all 0.4s ease;
  --trans-04: all 0.5s ease;
}

body {
  background-color: var(--body-color);
  transition: var(--trans-02);
}

body.dark {
  --body-color: #181914;
  --sidebar-color: #242526;
  --primary-color: #34383c;
  --primary-color-light: #383a3c;
  --toggle-color: #fff;
  --text-color: #ccc;
  --dark-color: #fff;
}
/* Reusable CSS */
.sidebar {
  position: absolute;
  z-index: 10;
}
.sidebar .text {
  font-size: var(--font-size);
  font-weight: 500;
  color: var(--text-color);
  /* transition: var(--trans-04); */
  white-space: unset;
  opacity: 1;
}

.sidebar .image {
  min-width: 60px;
  display: flex;
  align-items: center;
}

/* Sidebar */
.sidebar {
  position: fixed;
  top: 0;
  left: 0px;
  height: 100%;
  width: 250px;
  background-color: #2a4660;
  padding: 10px var(--font-size);
  transition: var(--trans-02);
}

.sidebar header {
  position: relative;
}
.sidebar .image-text img {
  width: 40px;
  height: 40px;
  border-radius: 10px;
}

.sidebar header .image-text {
  display: flex;
  align-items: center;
}

.header .image-text .header-text {
  display: flex;
  flex-direction: column;
}

.header-text .main {
  font-weight: 600;
}

.header-text .sub {
  margin-top: -2px;
}

.sidebar header .toggle {
  position: absolute;
  top: 50%;
  right: -25px;
  transform: translateY(-50%) rotate(180deg);
  height: 25px;
  width: 25px;
  background-color: var(--dark-color);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: var(--sidebar-color);
  font-size: 22px;
}

.sidebar li {
  height: 50px;
  /* background-color: red; */
  margin-top: 10px;
  list-style: none;
  display: flex;
  align-items: center;
}

.sidebar li .icons {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 60px;
  font-size: 20px;
}
.sidebar li .icons,
.sidebar li .text {
  color: var(--text-color);
  transition: var(--trans-03);
}

.sidebar .search-bar input {
  width: 100%;
  height: 100%;
  outline: none;
  border: none;
  border-radius: 6px;
  background-color: var(--primary-color-light);
}
.sidebar .search-bar {
  background-color: var(--primary-color-light);
}

.sidebar li a {
  height: 100%;
  width: 100%;
  text-decoration: none;
  display: flex;
  align-items: center;
  padding-right: 50px;
  border-radius: 6px;
  transition: var(--trans-04);
  text-align: left;
}

.sidebar li a:hover {
  background: var(--primary-color);
}
.sidebar li a:hover .sidebar-icon,
.sidebar li a:hover .text {
  color: #000;
}

.sidebar .menu-bar {
  height: 100%;
  height: calc(100% - 50px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.menu-bar .mode {
  position: relative;
  border-radius: 6px;
  background: var(--primary-color-light);
}

.menu-bar .mode .moon-sun {
  height: 50px;
  width: 60px;
  display: flex;
  align-items: center;
}
.menu-bar .mode i {
  position: absolute;
  transition: var(--trans-03);
}

.menu-bar .mode i.sun {
  opacity: 0;
}
body.dark .menu-bar .mode i.sun {
  opacity: 1;
}
body.dark .menu-bar .mode i.moon {
  opacity: 0;
}
.menu-bar .mode .toggle-switch {
  position: absolute;
  /* left: 0; */
  right: -10px;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  min-width: 60px;
  background: var(--primary-color-light);

  /* background: red; */
  cursor: pointer;
}

.toggle-switch .switch {
  position: relative;
  height: 22px;
  width: 44px;
  background-color: var(--toggle-color);
  border-radius: 25px;
}

.switch::before {
  content: "";
  position: absolute;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
  background-color: var(--sidebar-color);
}

body.dark .switch::before {
  left: 25px;
}

body.dark .sidebar .image img {
  background-color: #fff;
  /* border-color: 2px solid #000; */
}

.sidebar.close {
  width: 78px;
}

.sidebar.close .text {
  opacity: 0;
}

.sidebar.close .search-bar {
  background-color: var(--sidebar-color);
}

body.dark .search-bar input {
  color: white;
}

.sidebar.close .toggle {
  transform: translateY(-50%);
}

.sidebar-logo {
  height: 30px !important;
  margin-left: 18px;
}
.sidebar-icon {
  width: 25px;
  height: 25px;
  margin-right: 20px;
  margin-left: 10px;
  color: #fff;
}
.Investor-signup-inputs {
  margin-bottom: 20px;
}
.Investor-signup-div {
  margin-left: 280px;
  min-height: 100vh;
}
.Welcome-message {
  font-size: 36px;
  padding-top: 50px;
  font-family: "Karla-Medium", Helvetica;
}
.Investor-details {
  display: flex;
}
.steps-heading {
  font-size: 24px;
  margin-bottom: 20px;
}
.Investor-details-plus-text {
  width: 100%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 25px;
  margin-top: 25px;
  border-radius: 20px;
}
.circular-input {
  text-align: center;
  font-size: 24px;
  width: 170px;
  height: 170px;
  border-radius: 50%;
  background-color: #e5f6fe;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  margin-top: 25px;
}
.input {
  width: 200px;
  height: 38px;
}
.Investment-details {
  font-size: var(--font-size);
  margin-bottom: 10px;
}
.mutliple-inputs {
  margin-right: 25px;
}
.Investment-details-values {
  display: flex;
  flex-direction: row;
}
.Investment-deatils-div {
  display: flex;
  flex-direction: row;
  width: 340px;
  justify-content: space-between;
}
.Investment-deatils-val-text {
  font-size: var(--font-size);
  margin-right: 20px;
  width: 50px;
}
.plain-input {
  background-color: transparent;
  border: 0px solid;
  height: 20px;
  width: 50px;
  margin-right: 20px;
}
.plain-input::placeholder {
  font-size: var(--font-size);
}
.Investment-details-val-input {
  width: 280px;
  display: flex;
}
.Investment-column {
  display: flex;
  flex-direction: column;
  height: 155px;
  justify-content: space-around;
}
.Investment-details-text-div {
  background-color: #e5f6fe;
  padding: 15px;
  height: fit-content;
  border-radius: 10px;
}
.Investment-details-simple-text {
  font-size: var(--big-font-size);
  margin-bottom: 10px;
}
.business-description-input {
  margin-left: 400px;
  height: 60px !important;
  margin-top: 20px !important;
  width: 95% !important;
  display: flex;
  padding-top: 10px;
  align-items: start;
}
.investor-inputs-1 {
  display: flex;
  flex-direction: row;
}
.business-description {
  margin-top: 20px;
}
.add-photo {
  width: 120px;
  height: 120px;
}
.Investment-team-addition {
  display: flex;
  width: 1000px;
  max-width: 73.5vw;
  overflow-x: scroll;
  padding-bottom: 20px;
}
.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.upload-file-btn {
  border: 2px solid #00a6fb;
  color: #00a6fb;
  background-color: white;
  padding: 8px 10px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
}

.upload-btn-wrapper input[type="file"] {
  font-size: 16px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
@media all and (min-width: 1400px) {
  .Investment-team-addition {
    width: 100%;
  }
}
.Investment-team-row {
  display: flex;
  justify-content: space-between;
}
.add-more-btn {
  height: 30px;
  margin-left: 90vw;
  font-size: var(--font-size) !important;
  padding: 8px 16px !important;
}
.mobile-business-description {
  display: none;
}
.input-select {
  font-size: var(--font-size) !important;
}
.css-1wmuzea-JoySelect-listbox {
  font-size: var(--font-size) !important;
}
.row-inputs {
  display: flex;
}
.ticket-size-inputs {
  display: flex;
}
/* .investment-focus{
  width: 1050px;
  max-width: 73.5vw;
} */
.ticket-input {
  width: 100px !important;
  font-size: var(--font-size) !important;
  margin-right: 10px;
}
.slider {
  margin-left: 20px;
}
.react-tags-wrapper {
  background-color: #f3f3f3;
  width: 330px;
  padding: 15px;
}
.ReactTags__tag {
  background-color: #fff;
  padding: 5px;
  border-radius: 2px;
  margin-right: 10px;
}
.ReactTags__tagInput {
  margin-top: 10px;
}
.ReactTags__tagInputField {
  border: 1px solid #cdd7e1;
  padding: 9px 7px;
  border-radius: 10px;
}
.ReactTags__remove {
  border: 0px;
  font-size: 20px;
  margin-left: 5px;
  padding-top: 3px;
  background-color: #fff;
}
.Investment-focus-all-columns {
  display: flex;
}
.Investment-focus-column-1 {
  margin-right: 20px;
}
.css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
  font-size: var(--font-size);
}
.other-criteria-input {
  height: 80px;
  width: 300px !important;
  display: flex;
  padding-top: 10px;
  align-items: start;
}
.Industry-focus-all-columns {
  display: flex;
  justify-content: space-between;
  max-width: 1300px;
}
.Industry-focus-react-wrapper {
  margin-right: 30px;
}
.indutry-focus-asset-class {
  margin-right: 20px;
}
.indutry-focus-asset-class {
  width: 310px;
}
.indutry-focus-asset-endMarket-class {
  margin-top: 30px;
}
.indutry-focus-asset-endMarket-class .react-tags-wrapper {
  width: 98%;
}
.Investment-focus-column-2 .revenue-input {
  width: 90% !important;
}
.end-market-select {
  width: 98%;
}
.Save-details {
  font-size: var(--font-size) !important;
  margin-bottom: 20px !important;
  padding: 8px 16px !important;
}
.investor-onboarding-btn {
  background-color: #00a6fb !important;
}
.css-1gw9vc6-JoyInput-input,
.css-yafthl-MuiSlider-markLabel,
.limit-shower,
.css-1gw9vc6-JoyInput-input::placeholder,
.css-yafthl-MuiSlider-markLabel::placeholder,
.css-13cymwt-control,
.simple-text {
  font-size: var(--font-size) !important;
}
.limit-shower {
  padding-top: 2px;
}
.Financial-criteria,
.Asset-class {
  margin-top: 20px;
}
.startup-onboarding-input {
  width: 200px !important;
}
.Current-round-inputs {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.funding-details-input {
  width: 250px !important;
  margin-bottom: 10px;
  margin-right: 10px;
}
.Current-round-inputs-col {
  display: flex;
  flex-direction: row;
}
.upload-Capitalization-table {
  border: 1px solid #cdd7e1;
  color: #7b7f84;
  background-color: white;
  padding: 8px 10px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  width: 510px !important;
  display: flex;
  justify-content: space-between;
}
.upload-file-icon {
  width: 20px;
  height: 20px;
}
.funding-details-column-2 .Investment-column {
  height: 180px;
}
.funding-details-column-2 .plain-input {
  width: 60px;
}
.funding-details-column-2 .Investment-deatils-div {
  width: 380px;
}
.upload-data-input {
  border: 1px solid #cdd7e1;
  color: #7b7f84;
  background-color: white;
  padding: 8px 10px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  width: 200px !important;
  display: flex;
  justify-content: space-between;
  margin-right: 20px;
  margin-bottom: 10px;
}
@media all and (max-width: 991px) {
  .Investor-details,
  .investor-inputs-1 {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .circular-input {
    margin-right: 0px;
  }
  .row-inputs {
    width: 100%;
    flex-direction: column;
  }
  .investor-inputs,
  .investor-details-inputs {
    width: 100%;
  }
  .input,
  .startup-onboarding-input {
    width: 100% !important;
  }
  .Investment-deatils-div {
    width: 100%;
  }
  .Investor-signup-comapany-logo {
    margin-bottom: 30px;
  }
  .multiple-inputs-1,
  .multiple-inputs-2 {
    margin-bottom: 18px;
    width: 100%;
  }

  .business-description-input {
    margin-top: 0px !important;
    margin-bottom: 18px !important;
  }
  .mutliple-inputs {
    margin-right: 0px;
  }
  .mobile-business-description {
    display: block;
  }
  .laptop-business-description {
    display: none;
  }
  .Investment-team-addition {
    width: 90%;
    max-width: initial;
  }
  .Investment-team-row {
    flex-direction: column;
  }
  .add-more-btn {
    width: fit-content;
    margin-bottom: 20px !important;
  }
  .investment-team-addition-div {
    margin-right: 30px;
    padding-right: 0px;
  }
  .Investment-focus-all-columns,
  .Industry-focus-all-columns {
    flex-direction: column;
  }
  .Investment-focus-column-2 {
    width: 100% !important;
    margin-bottom: 20px;
  }
  .Investment-focus-column-2 .revenue-input {
    width: 100% !important;
  }
  .Investment-focus-column-1 {
    margin-right: 0px;
  }
  .Investment-deatils-div {
    width: 330px;
  }
  .indutry-focus-asset-class {
    width: 100% !important;
  }
}
@media all and (max-width: 676px) {
  .Investment-deatils-div {
    width: 300px;
  }
  .sidebar {
    width: 180px;
  }
  .Investor-signup-div {
    margin-left: 200px;
  }
  .css-1v5z18m {
    width: 90% !important;
  }
  .Investment-deatils-div {
    flex-direction: column;
  }
  .ticket-size-inputs {
    margin-bottom: 10px;
  }
}
@media all and (max-width: 479px) {
  .investor-details-inputs {
    width: 100%;
  }
  .business-description-input {
    margin-top: 5px !important;
    width: 100% !important;
  }
  .row-inputs {
    flex-direction: column;
    width: 100%;
  }
  .Investor-signup-div {
    margin-right: 30px;
  }
  .Investor-details-plus-text,
  .Investor-details-plus-text {
    width: 67vw;
  }
  .investor-inputs {
    width: 100%;
  }
  .Investment-deatils-div {
    overflow-x: scroll;
    width: initial;
  }
  .Investment-column {
    margin-right: 20px;
  }
  .Investment-team-addition {
    width: 100%;
  }
}
@media all and (max-width: 1300px) {
  :root {
    --font-size: 14px;
    --big-font-size: 20px;
  }
  .input {
    width: 160px;
  }
  .circular-input {
    width: 150px;
    height: 150px;
  }
  .Investor-details-plus-text {
    width: 98%;
  }
  .Investment-focus-column-2 {
    width: 25%;
  }
  .other-criteria-input {
    width: 100% !important;
  }
}
@media all and (min-width: 1600px) {
  :root {
    /* --font-size:24px; */
    --big-font-size: 28px;
  }
  .sidebar {
    width: 350px;
  }
  .Investor-signup-div {
    margin-left: 380px;
  }
  /* .input{
    width: 250px;
    height: 50px;
  } */
  .startup-onboarding-input {
    width: 350px !important;
  }
  .upload-Capitalization-table {
    width: 710px !important;
  }
  .upload-data-input {
    width: 400px !important;
  }
  .Investment-deatils-div {
    width: 500px;
  }
  .Investment-column {
    height: 220px;
  }
  .plain-input {
    height: 25px;
    width: 80px;
  }
  .business-description-input {
    height: 100px !important;
  }
  .steps-heading {
    font-size: 36px;
  }
  .Welcome-message {
    font-size: 60px;
  }
  .Investment-team-addition {
    width: initial;
    max-width: initial;
  }
  .investment-focus {
    padding: 25px 65px 25px 25px;
  }
  /* Select Option component */
  .css-13cymwt-control {
    height: 50px;
  }
  .Investor-signup-div {
    margin-left: 380px;
  }
  .css-qbdosj-Input {
    font-size: var(--font-size);
  }
}
