.InvestorSide-content-text-1 {
  color: #000;
  font-family: "Karla", sans-serif;
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 10px;
}
.InvestorSide-content-text-2 {
  color: #667085;
  font-family: "Karla", sans-serif;
  font-size: 22px;
  margin-bottom: 20px;
  font-weight: 500;
}

/* Investor ongoing deals css */
.onGoingDeals-col {
  width: 320px;
}
.onGoingDeals-section .Card-div {
  width: 300px;
}
.onGoingDeals-div {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  overflow: hidden;
}
@media all and (max-width: 1410px) {
  .onGoingDeals-div {
    overflow-x: scroll !important;
  }
}
.onGoingDeals-col {
  width: 320px;
}
.onGoingDeals-section .Card-div {
  width: 300px;
}
.onGoingDeals-div {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  overflow: hidden;
}
.onGoingDeals-types-div-3 {
  display: flex;
  flex-direction: row;
  gap: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  margin: 0px 20px 10px 0px;
  background-color: #fff;
  border-radius: 4px;
}
.onGoingDeals-types-div-4 {
  color: var(--Secondary-Text-Color);
  font-size: 14px;
  font-weight: 600;
}
.onGoingDeals-section .onGoingDeals-div {
  margin-left: 10px;
  margin-top: 10px;
  width: 98%;
  height: 95%;
  overflow: hidden;
}
.company-ongoing-deals .onGoingDeals-div {
  background-color: #fff;
  padding: 16px 16px 32px 16px;
  box-shadow: var(--whiteBg-back-shadow);
  border-radius: 8px;
}
.company-ongoing-deals .onGoingDeals-types-div-3 {
  justify-content: flex-start;
  padding: 0px;
  margin-left: 8px;
  justify-content: space-between;
}
.company-ongoing-deals .card-content {
  border-radius: 8px;
  border: 1px solid #d8d8d8;
}
.company-ongoing-deals .onGoingDeals-types-div {
  border-right: 1px solid #e5e5e5;
}
.company-ongoing-deals .onGoingDeals-types-div-4 {
  color: var(--Primary-Text-Color);
  font-size: 20px;
}
.company-ongoing-deals .onGoingDeals-types-div-5 {
  background-color: #fff;
  color: var(--Secondary-Text-Color);
}
.company-ongoing-deals .onGoingDeals-div {
  height: calc(100vh - 230px);
}
.onGoingDeals-allCards {
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow: scroll;
  width: 320px;
  height: calc(100vh - 160px);
  overflow-y: scroll;
}
.onGoingDeals-types-div-5 {
  color: #fff;
  background-color: var(--Secondary-Text-Color);
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.company-ongoing-deals .view-type-btn {
  display: flex;
  width: 36px;
  height: 36px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  border: 1px solid rgba(26, 26, 26, 0.4);
  background: #fff;
}
.company-ongoing-deals .active-view-type-button {
  background: #114fee;
}
.active-view-type-button-img {
  color: #fff;
}
