.noRoutes-div {
  justify-content: center;
  align-items: center;
  background-color: #f9f9f9;
  display: flex;
  padding: 80px 60px;
  overflow-y: hidden;
  min-height: 100vh;
}
@media (max-width: 991px) {
  .noRoutes-div {
    padding: 0 20px;
  }
}
.noRoutes-div-2 {
  display: flex;
  margin-top: 20px;
  width: 598px;
  max-width: 100%;
  flex-direction: column;
}
.noRoutes-img {
  aspect-ratio: 1;
  width: 60%;
  margin: auto;
  max-width: 560px;
  max-width: 100%;
}
.noRoutes-div-3 {
  color: #111;
  text-align: center;
  margin-top: 22px;
  font: 500 30px Roboto, sans-serif;
}
@media (max-width: 991px) {
  .noRoutes-div-3 {
    max-width: 100%;
  }
}
.noRoutes-div-4 {
  color: #838282;
  text-align: center;
  align-self: center;
  margin-top: 25px;
  font: 400 20px Karla, sans-serif;
}
@media (max-width: 991px) {
  .noRoutes-div-4 {
    max-width: 100%;
  }
}
.noRoutes-div-5 {
  border-radius: 6px;
  background-color: #00a6fb;
  align-self: center;
  margin-top: 35px;
  color: #fff;
  letter-spacing: 0.4px;
  justify-content: center;
  padding: 20px 22px;
  font: 500 18px Karla, sans-serif;
}
@media (max-width: 991px) {
  .noRoutes-div-5 {
    padding: 0 20px;
  }
}
