*,
:after,
:before {
  box-sizing: border-box;
}

.clear-all:after,
.clear-all:before {
  content: "";
  display: table;
  clear: both;
}
.landing-header {
  height: 80px;
  background-color: transparent;
  position: fixed;
  width: 100%;
  z-index: 11;
  top: 0px;
}
.cm-header-wrap {
  position: fixed;
  top: 0;
  width: 100%;
  max-width: 1400px;
  margin: auto;
  padding: 14px 15px;
}

.level2 {
  flex-direction: column;
}

.cm-menu-inner ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.cm-menu-inner > ul {
  display: block;
}

.cm-menu-inner > ul > li {
  float: left;
  padding: 0 10px;
}

.cm-menu-inner > ul li a {
  display: block;
  font-weight: 500;
  color: #fff;
  padding: 10px;
  text-decoration: none;
  font-family: "Karla", sans-serif;
}
.links {
  font-size: 16px;
}
.company-logo {
  position: fixed;
  z-index: 100;
  width: 150px;
  margin-left: 60px;
  top: 25px;
}
.investorDetailsPage .company-logo {
  top: 35px;
}
.menu-ul {
  width: 60vw;
}
.bluearrowright {
  height: 20px;
}
.bluearrowright,
.whitearrowright {
  display: none;
}
.phone-header {
  display: none !important;
}
.laptop-header {
  display: flex !important;
}
@media all and (min-width: 1400px) {
  .menu-ul {
    width: 800px;
  }
  .company-logo {
    margin-left: calc(50% - 670px);
  }
  .cm-header-wrap {
    margin: 0px calc(50vw - 700px);
  }
}
@media all and (min-width: 1600px) {
  .company-logo {
    margin-left: calc(50% - 685px);
  }
}
.child-menu-btn {
  display: none;
}
.investorDetailsPage .cm-header-wrap {
  margin-top: 35px;
}
@media (min-width: 992px) {
  .cm-menu-inner ul ul {
    position: absolute;
    top: 100%;
    left: 0;
    background: #f6f8f9;
    min-width: 130px;
    opacity: 0;
    visibility: hidden;
    transition: all 300ms linear;
  }

  .cm-menu-inner ul li.has-child {
    position: relative;
  }

  .cm-menu-inner ul li.has-child:hover > ul {
    opacity: 1;
    visibility: visible;
  }

  .cm-menu-inner ul ul ul {
    left: 100%;
    top: 0;
  }

  .cm-menu-inner {
    display: flex;
    justify-content: flex-end;
  }

  .cm-menu-inner {
    display: flex !important;
  }

  .cm-menu-inner ul {
    display: flex;
  }

  .cm-menu-btn,
  .child-menu-btn {
    display: none;
  }
}

@media (max-width: 991px) {
  .menu-ul {
    width: 100%;
  }
  .cm-header-wrap {
    height: 55px;
  }
  .company-logo {
    width: 120px;
    top: 25px;
  }
  .cm-menu-inner {
    position: absolute;
    background-color: #fff;
    width: 100%;
    left: 0;
    top: 100%;
    display: none;
    padding: 0px 20px;
  }

  .cm-menu-inner > ul > li {
    float: none;
    padding: 0;
    border-bottom: 1px solid #e0e0e0;
    display: flex;
    justify-content: space-between;
  }

  .cm-menu-inner ul ul > li > a {
    padding-left: 25px;
  }
  .cm-menu-inner > ul li a {
    padding: 15px 10px;
  }
  .cm-menu-inner ul ul ul > li > a {
    padding-left: 35px;
  }

  .cm-menu-btn {
    position: absolute;
    top: 25px;
    right: 30px;
    font-size: 25px;
    color: #000;
    cursor: pointer;
  }

  li.has-child {
    position: relative;
  }

  .child-menu-btn {
    color: #000;
    position: absolute;
    right: 0;
    line-height: 40px;
    border-left: 1px solid #fff;
    width: 40px;
    text-align: center;
    cursor: pointer;
    top: 0;
  }

  .cm-menu-inner ul ul {
    display: none;
  }
  .bluearrowright,
  .whitearrowright {
    display: block;
  }
  .get-started {
    font-size: 16px !important;
    margin: 15px 0px;
  }
  .get-started .text-wrapper {
    display: flex;
  }
  .whitearrowright {
    height: 20px;
    margin-left: 10px;
  }
  .investorDetailsPage .cm-header-wrap {
    margin-top: 0px;
  }
  .laptop-header {
    display: none !important;
  }
  .phone-header {
    display: flex !important;
  }
}

/* Banner*/

.cm-header-wrap {
  z-index: 11;
}

.banner {
  position: relative;
  z-index: 10;
  height: 100vh;
  background-size: cover;
  background-position: center;
}

.banner h1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 40px;
  text-align: center;
  background: #f6f8f9;
  padding: 16px;
  border-radius: 5px;
  color: #000;
  font-family: "Karla", sans-serif;
  letter-spacing: 1px;
  width: 700px;
  text-transform: capitalize;
}

.banner h1 a {
  color: #000;
  text-decoration: none;
  text-decoration: underline;
  display: inline-block;
  padding-top: 20px;
  font-size: 45px;
  text-shadow: 9px -1px 6px #000;
  transition: all 300ms ease;
}

.banner h1 a:hover {
  text-shadow: 5px -1px 14px #000;
}
.get-started {
  padding: 8px 18px;
  background-color: #00a6fb;
  color: #fff;
  border: none;
  border-radius: 30px;
  font-size: 16px;
  position: absolute;
  right: 30px;
}
.get-started:hover {
  background-color: #175ea5;
}
@media all and (max-width: 991px) {
  .get-started {
    position: initial;
  }
}
.get-started-sidebar {
  padding: 10px 30px;
  background-color: #25cd71;
  color: #fff;
  border: none;
  border-radius: 5px;
  margin-top: 20px;
  width: 170px;
}
@media (max-width: 767px) {
  .banner h1 {
    max-width: 90%;
    width: 100%;
  }
}
.landing-CTA-btns {
  position: absolute;
  right: 30px;
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-top: 5px;
}
@media all and (max-width: 991px) {
  .landing-CTA-btns {
    position: inherit;
    margin: 10px auto;
  }
}
.landing-login-btn {
  z-index: 5;
  padding: 8px 16px;
  border-radius: 100px;
  border: 1px solid #fff;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
}
.landing-signin-btn {
  z-index: 5;
  padding: 8px 16px;
  border-radius: 100px;
  border: 1px solid #fff;
  background-color: #fff;
  color: #3c5168;
  font-size: 16px;
  font-weight: 500;
}
.landing-page-toggle {
  border: 1px solid #fff !important;
  border-radius: 100px !important;
  padding: 5px;
  height: 50px;
}
.landing-page-toggle-button-1 {
  text-transform: capitalize !important;
  color: #fff !important;
  border: 0px !important;

  /* background: rgba(255, 255, 255, 0.1) !important; */
  font-size: 16px !important;
}
.landing-page-toggle-button-2 {
  text-transform: capitalize !important;
  color: #fff !important;
  /* background: rgba(255, 255, 255, 0.1) !important; */
  font-size: 16px !important;
}
@media all and (max-width: 479px) {
  .company-logo {
    top: 15px !important;
    margin-left: 30px;
  }
  .landing-header {
    height: 60px;
  }
  .cm-menu-btn {
    top: 15px;
  }
}
