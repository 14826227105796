/* Some styling changes to keep Continue button in focus */
.investor-sign-up-page,
.company-sign-up-page {
  min-height: 100vh;
  width: 100vw;
  background-color: #f6f8ff;
}

.investor-sign-up-page-header,
.company-sign-up-page-header {
  width: 100%;
  /* padding: 40px 0px 0px 40px; */
  padding: 20px 0px 0px 40px;
}
.investor-sign-up-page-header img,
.company-sign-up-page-header img {
  height: 24px;
}
.sign-up-block {
  /* margin: 16px auto 0px auto; */
  margin: 0px auto 0px auto;
  display: flex;
  flex-direction: column;
  /* gap : 40px; */
  gap: 10px;
  padding-bottom: 80px;
}
.sign-up-block .heading-block {
  text-align: center;
}
.sign-up-block .middle-block {
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin: auto;
  max-width: 90%;
}
.sign-up-block .sign-up-right-block {
  width: 732px;
  display: flex;
  padding: 32px;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  border-radius: 24px;
  background: #fff;
  box-shadow: 4px 4px 16px 0px rgba(161, 161, 161, 0.12);
  max-width: 100%;
}
.sign-up-input-fields {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px 60px;
  width: 100%;
}
.sign-up-btn-section {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}
.sign-up-left-section .back-icon {
  cursor: pointer;
}
.middle-block-header p {
  color: var(--Primary-Text-Color);
  font-family: Karla;
  font-size: 16px;
  font-weight: 400;
}
.btn-block {
  margin-top: 8px;
}
.sign-up-block .tnc-text-block {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: auto;
}

@media all and (max-width: 991px) {
  .sign-up-input-fields {
    grid-template-columns: 1fr;
    gap: 24px;
  }
}
@media all and (max-width: 576px) {
  .sign-up-block .tnc-text-block {
    width: 100%;
  }
  .sign-up-block {
    margin-top: 24px;
    gap: 16px;
  }
  .sign-up-block .middle-block {
    flex-direction: column;
    position: relative;
    margin-bottom: 100px;
  }
  .sign-up-left-section {
    display: none;
  }
  .sign-up-btn-section-phone {
    position: fixed;
    bottom: 0px;
    left: 0px;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    padding: 16px;
    gap: 16px;
    background: #f6f8ff;
  }
  .investor-register-heading {
    font-size: 20px;
  }
  .sign-up-block .sign-up-right-block {
    padding: 16px;
  }
  .sign-up-block .heading-block {
    display: flex;
    flex-direction: row;
    width: 90%;
    margin: auto;
    align-items: center;
    gap: 16px;
  }
}
