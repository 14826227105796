.investor-noName-deals {
  /* min-height: 70vh; */
  min-width: 90vw;
  /* margin-top: 100px;
  max-width: 1600px; */
  width: 90vw;
  margin: 0px 5vw;
  padding: 120px 0px;
  overflow: hidden;
}
.noNameDeal-heading {
  font-size: 20px;
  font-weight: 400;
}
@media all and (min-width: 1500px) {
  .investor-noName-deals {
    min-width: initial;
  }
}
.investor-noName-deals .text {
  font-weight: 600;
  font-size: 24px;
  font-family: "karla", sans-serif;
  margin: 0px 0px 20px 40px;
}
.investorDealRegister .investorRegisterWithSignUpText {
  margin: auto;
}
.investorDealRegister .section {
  padding: 40px 60px;
  margin: 30px 0px;
  border-radius: 20px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  width: 90%;
  max-width: 1000px;
  background-color: #fff;
}
.investorDealRegister,
.investorDealRegister .investorRegister {
  background-color: #d3d9ee;
}
@media all and (max-width: 991px) {
  .investorDealRegister .section {
    padding: 0px 20px;
  }
  .investorDealRegister,
  .investorDealRegister .investorRegister {
    background-color: #fff;
  }
  .investorDealRegister .section {
    box-shadow: none;
  }
  .investorRegister .goBack {
    margin-top: 20px;
  }
  .investorDealRegister .goBack {
    margin-top: 0px;
  }
}
.investorDealRegister {
  min-height: 100vh;
}

.investorDealRegister .textContent {
  text-align: center;
}
@media all and (min-width: 1500px) {
  .noNameDeal .showInterestButtons-div {
    left: 0px !important;
  }
}
.investorDealSignIn .section {
  max-width: 700px;
}
.investorDealSignIn .text {
  margin-top: 20px;
}
.investorDealSignIn .investorRegister .title {
  margin-bottom: 0px;
}
.CompleteOnBoardingModal-text {
  font-size: 18px !important;
}
.opportunites-display {
  display: grid;
  gap: 20px;
  padding: 20px 0px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
@media all and (max-width: 991px) {
  .investorDealSignIn .section {
    margin-top: 100px;
  }
  .investorDealSignIn .investorRegister .form-control {
    margin-bottom: 0px;
  }
  .investorDealSignIn .investorRegister .checkbox {
    margin-bottom: 0px;
  }
  .investor-noName-deals .opportunites-display {
    justify-content: center;
  }
  .investor-noName-deals .text {
    margin: 0px 20px;
  }
}
.deals-filter-box {
  width: 424px;
  gap: 0px 16px;
  box-shadow: 0px 0px 1px 0px rgba(12, 26, 75, 0.24),
    0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  border-radius: 8px;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 120px;
  right: 20px;
  background-color: #fff;
  z-index: 25;
  max-width: 90%;
}
.deals-filter-box-first-row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.deals-checkboxes {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 16px 0px;
}
.deal-checkbox {
  font-size: 14px !important;
}
.deals-filter-option {
  padding: 16px;
}
.deals-filter-option p {
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: 600;
}
.clear-filters {
  width: fit-content !important;
  text-transform: capitalize !important;
  color: var(--brand-color) !important;
  font-weight: 500;
  font-size: 16px;
}
.noNameDeal-first-section {
  padding: 0px 0px;
  display: flex;
  justify-content: space-between;
}
.deals-filter-box-header {
  display: flex;
  justify-content: space-between;
  border-bottom: 0.5px solid var(--Secondary-Text-Color);
  padding: 16px;
}
.deals-filter-box-header p {
  font-size: 20px;
  color: var(--Primary-Text-Color);
}
.noName-signup-box {
  box-shadow: 0px -50px 60px rgba(255, 255, 255, 1);
}
.open-listing-page .Footer {
  position: absolute;
  z-index: 10;
  background-color: #fff;
  margin-top: 0px;
}
.open-listing-page .Card-div {
  /* margin: auto; */
}
.open-listing-page .landing-header {
  z-index: 30;
}

@media all and (max-width: 768px) {
  .deals-filter-box-first-row {
    grid-template-columns: 1fr;
  }
  .noName-investorFirms-section {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .noName-investorFirms-card {
    padding: 10px;
    width: 100%;
  }
  .more-companies-text {
    font-size: 20px;
    grid-column: 1 / -1;
    justify-self: center;
  }
  /* .noNameDeal-EndMarketFocus {
    border-bottom: 1px solid #cacaca !important;
  } */
}
@media (min-width: 1440px) {
  .investor-noName-deals {
    width: 1400px;
    margin: 0px calc(50vw - 685px);
  }
}
@media (max-width: 1440px) {
  .opportunites-display {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media (max-width: 1100px) {
  .opportunites-display {
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 768px) {
  .opportunites-display {
    grid-template-columns: 1fr;
  }
}
