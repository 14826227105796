.editCompanyDetails,
.FoundingTeamProfile,
.editDealTeam {
  margin-top: 16px;
}
.editCompanyDetails,
.FoundingTeamProfile {
  margin-bottom: 60px;
}
.company-details-input-fields,
.team-profile-input-fields {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 40px;
}
.editCompanyDetails-form,
.editTeamProfile-form {
  display: grid;
  grid-template-columns: 1fr 8fr;
  gap: 40px;
  width: 100%;
  position: relative;
}
.editCompanyDetails-form {
  margin-bottom: 16px;
}
.editTeamProfile-form {
  margin-top: 6vh;
}
.editCompanyDetails-form-upload-btn-wrapper,
.editTemProfile-form-upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}
.editCompanyDetails-form-upload-btn-wrapper input[type="file"],
.editTemProfile-form-upload-btn-wrapper input[type="file"] {
  width: 134px;
  height: 117px;
  font-size: 16px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
.editCompanyDetails-submitBtn-section,
.editTeamProfile-submitBtn-section {
  width: 100%;
  display: flex;
  justify-content: center;
  grid-column: 1/-1;
}
.Add-member-button {
  position: absolute;
  top: -60px;
  right: 0px;
  display: flex;
  flex-direction: row;
  gap: 8px;
}
