.btn-div {
  justify-content: center;
  display: flex;
  max-width: 141px;
  flex-direction: column;
  margin-right: 20px;
}
.addBtn-div {
  justify-content: center;
  border-radius: 6px;
  background-color: #00a6fb;
  display: flex;
  gap: 10px;
  font-size: 16px;
  color: #fff;
  font-weight: 700;
  white-space: nowrap;
  letter-spacing: 0.32px;
  padding: 17px 30px;
  cursor: pointer;
}
.add-img {
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 18px;
  align-self: start;
}

.dropdown-div {
  justify-content: center;
  align-items: start;
  border-radius: 12px;
  box-shadow: 0px 4px 40px 0px rgba(22, 20, 20, 0.24);
  background-color: #fff;
  display: flex;
  margin-top: 5px;
  flex-direction: column;
  font-size: 11px;
  color: #161414;
  font-weight: 500;
  letter-spacing: 0.03px;
  line-height: 140%;
  padding: 4px 10px;
  position: absolute;
  top: 260px;
  /* right: 10px; */
}
.dropdown-div .div-5 {
  display: flex;
  gap: 10px;
  white-space: nowrap;
  padding: 2px 8px;
}
.dropdown-div .img-2 {
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 21px;
}
.dropdown-div .div-6 {
  font-family: Karla, sans-serif;
  margin: auto 0;
}
.dropdown-div .div-7 {
  display: flex;
  margin-top: 8px;
  gap: 10px;
  white-space: nowrap;
  padding: 2px 8px;
}
.dropdown-div .img-3 {
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 21px;
}
.dropdown-div .div-8 {
  font-family: Karla, sans-serif;
  margin: auto 0;
}
.dropdown-div .div-9 {
  display: flex;
  margin-top: 8px;
  gap: 10px;
  color: #000;
  padding: 2px 8px;
}
.dropdown-div .img-4 {
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 21px;
}
.dropdown-div .div-10 {
  font-family: Karla, sans-serif;
  margin: auto 0;
}

.QNAheader {
  display: flex;
  width: 100%;
  gap: 20px;
  font-weight: 500;
  justify-content: space-between;
  height: 36px;
  margin: 25px 0px;
}
.QNAheader .div-2 {
  display: flex;
  gap: 0px;
  color: #667085;
  white-space: nowrap;
  text-transform: capitalize;
  letter-spacing: 0.09px;
  line-height: 140%;
  /* padding: 0 20px; */
}

.QNAheader .div-3 {
  font-family: Karla, sans-serif;
  justify-content: center;
  border-radius: 6px 0px 0px 6px;
  border-color: rgba(0, 166, 251, 1);
  border-style: solid;
  border-width: 1px;
  background-color: #e5f5fe;
  color: #00a6fb;
  font-weight: 700;
  padding: 7px 47px;
}

.QNAheader .div-4 {
  font-family: Karla, sans-serif;
  justify-content: center;
  border-color: rgba(185, 187, 189, 1);
  border-style: solid;
  border-width: 1px;
  background-color: #f4f4f4;
  padding: 7px 22px;
}

.QNAheader .div-5 {
  font-family: Karla, sans-serif;
  justify-content: center;
  border-radius: 0px 6px 6px 0px;
  border-color: rgba(185, 187, 189, 1);
  border-style: solid;
  border-width: 1px;
  background-color: #f4f4f4;
  padding: 7px 14px;
}

.QNAheader .div-6 {
  display: flex;
  gap: 17px;
  padding: 0 20px;
}
.QNAheader .div-7 {
  color: #696f79;
  font-family: Karla, sans-serif;

  margin: auto 0;
}
.QNAheader .div-8 {
  border-radius: 6px;
  border-color: rgba(134, 146, 166, 1);
  border-style: solid;
  border-width: 1px;
  background-color: #fff;
  display: flex;
  gap: 20px;
  color: #494949;
  white-space: nowrap;
  justify-content: space-between;
  padding: 8px 13px;
}

.QNAheader .div-9 {
  font-family: Karla, sans-serif;
  margin: auto 0;
}
.QNAheader .img {
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 16px;
}

.modal-box .div-4 {
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background-color: #f4f2f1;
  display: flex;
  padding: 0 8px;
  cursor: pointer;
}
.qna-section .img {
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 18px;
}
.qna-section .div-5 {
  display: flex;
  /* margin-top: 44px; */
  width: 100%;
  flex-direction: column;
  /* padding: 0 20px 0 56px; */
}

.qna-section .div-6 {
  color: #696f79;
  font: 500 16px Karla, sans-serif;
}

.qna-section .div-7 {
  display: flex;
  align-items: center;
  gap: 20px;
  font-weight: 500;
  white-space: nowrap;
  height: 64px;
}

.qna-section .div-8 {
  border-radius: 6px;
  background-color: #e9edf0;
  align-items: start;
  color: #8692a6;
  justify-content: center;

  /* width: fit-content; */
  width: 300px;
  padding: 27px 30px;
  font: 14px Karla, sans-serif;
}

.qna-section .div-9 {
  justify-content: center;
  border-radius: 6px;
  background-color: #e5f5fe;
  color: #00a6fb;
  text-align: center;
  padding: 22px 41px;
  font: 16px Karla, sans-serif;
}

.qna-section .div-10 {
  color: #696f79;
  /* margin-top: 34px; */
  font: 500 16px Karla, sans-serif;
}

.qna-section .div-11 {
  display: flex;
  /* margin-top: 18px; */
  gap: 20px;
  height: 64px;
}

.qna-section .div-12 {
  border-radius: 6px;
  border-color: rgba(134, 146, 166, 1);
  border-style: solid;
  border-width: 1px;
  align-items: start;
  color: #8692a6;
  white-space: nowrap;
  justify-content: center;

  width: 300px;
  padding: 28px 30px;
  font: 500 14px Karla, sans-serif;
}

.qna-section .div-13 {
  color: #000;

  flex-basis: auto;
  margin: auto 0;
  font: 400 16px Karla, sans-serif;
}
.qna-section .div-14 {
  color: #696f79;
  /* margin-top: 35px; */
  font: 500 16px Karla, sans-serif;
}

.qna-section .div-15 {
  height: 64px;
  border-radius: 6px;
  border-color: rgba(134, 146, 166, 1);
  border-style: solid;
  border-width: 1px;
  /* margin-top: 18px; */
  width: 300px;
  align-items: start;
  color: #8692a6;
  justify-content: center;
  padding: 27px 30px;
  font: 500 14px Karla, sans-serif;
}

.qna-section .div-16 {
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background-color: #00a6fb;
  align-self: center;
  width: 430px;
  max-width: 100%;
  color: #fff;
  white-space: nowrap;
  text-align: center;
  /* margin: 30px 0 0 18px; */
  padding: 22px 60px;
  font: 500 16px Karla, sans-serif;
}

.qna-section .upload-div {
  display: flex;
  max-width: 300px;
  flex-direction: column;
  font-size: 12px;
  text-align: center;
  justify-content: center;
  height: 64px;
}
.upload-div .div-2 {
  border-radius: 6px;
  border-color: rgba(134, 146, 166, 1);
  border-style: dashed;
  border-width: 1px;
  display: flex;
  align-items: center;
  gap: 5px;
  /* padding: 12px 20px; */
  padding: 5px;
  justify-content: space-around;
}
.upload-div .div-3 {
  font-family: Karla, sans-serif;
  justify-content: center;
  border-radius: 6px;
  background-color: #e5f5fe;
  /* align-self: stretch; */
  color: #1775d5;
  font-weight: 500;
  padding: 4px 7px;
  /* font-size: 10px; */
}
.upload-div .upload-img {
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 24px;
  align-self: stretch;
  margin: auto 0;
}
.upload-div .drop-document-text {
  color: #8692a6;
  font-family: Karla, sans-serif;
  font-weight: 400;
  line-height: 161%;
  align-self: stretch;
  flex-grow: 1;
  flex-basis: auto;
  margin: auto 0;
}
.download-zip-btn {
  margin-right: 30px !important;
}
