.emailVerificationPage {
  width: 100%;
  padding: 40px 0px 0px 40px;
}
.emailVerificationPage-header img {
  height: 24px;
}

.emailVerification-block {
  margin: 16px auto 0px auto;
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding-bottom: 80px;
}
.emailVerification-block .heading-block {
  text-align: center;
}

.emailVerification-block .middle-block {
  width: fit-content;
  margin: auto;
  display: flex;
  padding: 32px;
  flex-direction: column;
  align-items: center;
  border-radius: 24px;
  background: #fff;
  box-shadow: 4px 4px 16px 0px rgba(161, 161, 161, 0.12);
}

.emailVerification-block .code-input {
  justify-content: space-evenly;
  height: 100px;
}
.vi__container input {
  height: 48px; /* Adjust input height */
}

.vi__container {
  gap: 12px; /* You can now remove !important */
}

.emailVerification-block .vi__character {
  border-radius: 6px;
  border: 1px solid #8692a6;
  background: #fff;
  display: flex;
  justify-content: center; /* Aligns characters in the center */
  align-items: center;
  font-size: 28px;
}
.emailVerification-block .otp-block {
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  margin-top: 40px;
  height: 100%;
  align-items: center;
}
.otp-block .timer {
  display: flex;
  height: 48px;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background: #ecf0fa;
  color: #696f79;
  font-weight: 600;
  width: 120px;
}
.emailVerification-block .send-again-div {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
}
.emailVerification-block .send-again-div p {
  color: #3c82f6;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
}
.otp-block .otp-block-first {
  display: flex;
  flex-direction: row;
  gap: 40px;
}
.otp-block .btn-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
}
.btn-block p {
  display: inline-flex;
  align-items: center;
  color: #696f79;
  text-align: right;
  font-family: Karla;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.emailVerificationPage {
  width: 100%;
  padding: 40px 0px 0px 40px;
}
.emailVerificationPage-header img {
  height: 24px;
}

.vi__container input {
  height: 48px; /* Adjust input height */
}

.vi__container {
  gap: 12px; /* You can now remove !important */
}

.otp-block .timer {
  display: flex;
  height: 48px;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background: #ecf0fa;
  color: #696f79;
  font-weight: 600;
  width: 120px;
}

.otp-block .otp-block-first {
  display: flex;
  flex-direction: row;
  gap: 40px;
}
.otp-block .btn-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
}
/* Mobile Adjustments */
@media only screen and (max-width: 576px) {
  .emailVerificationPage {
    padding: 20px;
  }

  .emailVerificationPage-header img {
    height: 18px; /* Reduce logo size */
  }

  .emailVerification-block {
    margin: 16px auto 0;
    padding-bottom: 40px;
    gap: 20px;
  }

  .emailVerification-block .middle-block {
    padding: 16px;
    width: 90%;
    border-radius: 16px;
  }
  .otp-block .timer {
    height: 40px;
    padding: 10px;
    width: 85px; /* Smaller timer box */
    color: #696f79 !important;
  }
  .emailVerification-block .otp-block {
    width: 100%;
  }
  .otp-block .btn-block {
    position: fixed;
    bottom: 0px;
    left: 0px;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    padding: 16px;
    gap: 16px;
    background: #f6f8ff;
  }

  .btn-block {
    width: 100%;
  }

  .otp-block-first {
    flex-direction: column;
    gap: 20px;
  }

  /* Hide desktop elements */
  .laptop-design {
    display: none !important;
  }

  /* Show mobile elements */
  .phone-design {
    display: flex !important;
  }
  .emailVerification-block .send-again-div {
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  .emailVerification-block .middle-block {
    width: 100%;
    margin: auto;
  }
  .emailVerification-heading {
    font-size: 20px;
    justify-content: left;
    display: flex;
  }
}
