.investor-sidebar {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  max-width: 260px;
  height: 100vh;
  padding: 20px;
  border-right: 1px solid #e0e0e0;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.sidebar-header {
  display: flex;
  justify-content: center;
  padding: 20px 0;
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  width: 150px;
}

.sidebar-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.menu-item {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.menu-item:hover {
  background-color: #f0f0f0;
  border-radius: 8px;
}

.menu-icon {
  width: 24px;
}

.menu-label {
  font-size: 16px;
  color: #333333;
  font-weight: 500;
  flex: 1;
}

.dropdown-icon {
  width: 16px;
  transition: transform 0.3s ease-in-out;
}

.dropdown-icon.rotate {
  transform: rotate(180deg);
}

.submenu-link {
  text-decoration: none;
  color: #333333;
}

.submenu-item {
  padding: 8px 20px;
  font-size: 14px;
  margin-left: 34px;
  cursor: pointer;
}

.submenu-item:hover {
  background-color: #f0f0f0;
  border-radius: 8px;
}

.menu-link {
  text-decoration: none;
  color: #333333;
}

.logout-item {
  margin-top: auto;
  background-color: #ff585d;
  color: #ffffff;
  font-weight: bold;
}

.logout-item:hover {
  background-color: #e55454;
}

.logout-item .menu-icon {
  filter: invert(100%);
}
