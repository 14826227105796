.investor-account-box {
  width: 100%;
  margin-left: 0px;
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.investor-account-box .input-section {
  width: 90%;
}

.investor-personal-details-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
}
.investor-account-subheading {
  font-size: 20px;
  font-weight: 600;
  color: #696f79;
  margin-bottom: 20px;
}
.investor-account-change-password,
.investor-account-notifications,
.investor-account-delete,
.investor-profile-investment-focus,
.investor-account-fundrev-analyst {
  padding: 20px 0px;
}
.investor-personal-details-section,
.investor-profile-investment-details,
.investor-account-change-password,
.investor-account-notifications,
.investor-profile-investment-focus,
.investor-account-fundrev-analyst {
  border-bottom: 1px dashed #8692a6;
}
.investor-profile-investment-details {
  padding-bottom: 20px;
}
.notifications-switches {
  display: flex;
  flex-direction: column;
  width: fit-content;
  font-size: 20px;
  align-items: flex-start;
}
.investor-account-delete-text {
  font-size: 16px;
  font-weight: 600;
}
.investor-account-delete-text {
  font-size: 14px;
  font-weight: 500;
  color: var(--Primary-Text-Color);
}
.investor-account-delete-button-text {
  font-size: 16px;
  font-weight: 600;
  color: #d32f2f;
  margin-top: 20px;
}
.investor-profile-investment-focus {
  width: 90%;
}
.members-table tr {
  height: 50px;
}
.members-header th {
  background-color: #eaebec !important;
  color: var(--Secondary-Text-Color);
  font-size: 14px;
  font-family: "Karla", sans-serif;
  padding-left: 30px;
  font-weight: 400;
}
.members-table td {
  padding-left: 30px;
}
.name-cell {
  font-size: 14px;
  color: var(--Primary-Text-Color);
  font-weight: 400;
  display: flex;
  height: 50px;
  align-items: center;
}
.email-cell {
  font-size: 14px;
  color: var(--Primary-Text-Color);
  font-weight: 400;
}
.green-dot {
  width: 10px;
  height: 10px;
  background-color: #00cd9e;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
}
.yellow-dot {
  width: 10px;
  height: 10px;
  background-color: #f4bd50;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
}
.members-table .resend-invite {
  font-size: 14px;
  cursor: pointer;
  color: #00a6fb;
}
.members-table .resend-invite:hover {
  text-decoration: underline;
}
.members-table .delete-account {
  font-size: 14px;
  cursor: pointer;
  font-weight: 500;
  color: #d32f2f;
}
.members-first-div {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 16px;
}
.delete-account-btns {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}
.delete-account-btn {
  background-color: #d32f2f !important;
  text-transform: none !important;
}
.cancel-btn {
  background-color: #fff !important;
  color: #8692a6 !important;
  border: 1px solid #8692a6 !important;
  text-transform: none !important;
  padding: 16px 30px !important;
}
.cancel-btn:hover {
  background-color: #f4f5f7 !important;
}
.delete-account-btn:hover {
  background-color: #b71c1c !important;
}

.investor-account-fundrev-analyst {
  display: flex;
  /* max-width: 275px; */
  flex-direction: column;
  padding: 20px;
}

.investor-account-fundrev-analyst .investor-account-subheading {
  color: #696f79;
  width: 100%;
  font: 500 20px Roboto, sans-serif;
  /* margin-top: 20px; */
}

.investor-account-fundrev-analyst .fundrev-analyst-div {
  display: flex;
  gap: 16px;
}
.investor-account-fundrev-analyst .fundrev-img {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 48px;
  fill: #e5f5fe;
}
.investor-account-fundrev-analyst .analyst-name-div {
  display: flex;
  flex-direction: column;
  margin: auto 0;
}
.investor-account-fundrev-analyst .analyst-name {
  color: var(--Primary-Text-Color);
  font-feature-settings: "clig" off, "liga" off;
  font: 700 16px Karla, sans-serif;
}
.investor-account-fundrev-analyst .analyst-joined-date {
  color: #67727e;
  font-feature-settings: "clig" off, "liga" off;
  margin-top: 5px;
  font: 400 14px/171% Karla, -apple-system, Roboto, Helvetica, sans-serif;
}
.investor-account-fundrev-analyst .analyst-connect-div {
  display: flex;
  margin-top: 10px;
  width: 100%;
  flex-direction: column;
  align-items: start;
  font-size: 14px;
  font-weight: 400;
  padding: 0 12px 0 64px;
}
.investor-account-fundrev-analyst .analyst-contact-div {
  display: flex;
  gap: 4px;
  color: #494949;
}
.investor-account-fundrev-analyst .phone-icon-img-2 {
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 24px;
}
.investor-account-fundrev-analyst .analyst-phone {
  font-family: Karla, sans-serif;
  flex-grow: 1;
  flex-basis: auto;
  margin: auto 0;
  text-decoration: none;
  color: #494949;
}
.investor-account-fundrev-analyst .analyst-email-div {
  display: flex;
  gap: 4px;
  color: #00a6fb;
  white-space: nowrap;
}
.investor-account-fundrev-analyst .email-icon-img-3 {
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 25px;
}
.investor-account-fundrev-analyst .analyst-email {
  font-family: Karla, sans-serif;
  text-decoration-line: underline;
  flex-grow: 1;
  flex-basis: auto;
  margin: auto 0;
  color: #00a6fb;
}
.header-with-border-bottom {
  display: flex;
  flex-direction: column;
  position: relative;
}
.typeOfDeals-border {
  border-top: 1px solid #dbdade;
  width: 100%;
  position: absolute;
  top: 29.5px;
}
.top-left-th {
  border-radius: 10px 0px 0px 0px;
}
.top-right-th {
  border-radius: 0px 10px 0px 0px;
}
.select-peers-input {
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;
}
.select-peers-input .react-tagsinput {
  width: 100%;
}
.dialogcontent {
  padding: 0px 25px;
  border-radius: 20px;
  background-color: #fff;
}
.select-peers-input .dialogcontent {
  padding: 0px;
}
.investor-personal-details-section,
.investor-account-change-password,
.investor-account-delete {
  border: 0px;
  padding: 16px 16px 16px 24px;
}
.investor-personal-details-section .input-section {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 24px 40px;
}
.investor-account-details .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: 0px;
}
