.Investor-terms-conditions-page {
  max-width: 1440px;
  margin: auto;
}
.Investor-terms-conditions-header {
  width: 100%;
  padding: 40px 0px 0px 40px;
}
.Investor-terms-conditions-header img {
  height: 24px;
}
.tnc-block {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.tnc-block .heading-block {
  text-align: center;
}
.Investor-terms-conditions-page .middle-block {
  width: 70%;
  margin: auto;
}
.tnc-block .last-block {
  display: flex;
  justify-content: center;
  gap: 24px;
  align-items: center;
}
.tnc-block .tnc-text-block {
  width: 70%;
  display: flex;
  justify-content: center;
  margin: auto;
}
.tnc-text-block p {
  color: var(--Secondary-Text-Color) !important;
  font-family: Karla;
  font-size: 14px;
  font-weight: 400;
  margin-left: 10px;
}
.investors-TermsAndUse,
.companies-TermsAndUse {
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 55vh;
}
@media all and (max-width: 576px) {
  .Investor-terms-conditions-header {
    padding: 20px 0px 0px 40px;
  }
  .Company-terms-conditions-header {
    padding: 20px 0px 0px 40px;
  }
  .tnc-heading {
    font-size: 20px !important;
    display: flex;
    justify-content: left;
  }
  .tnc-block {
    margin-top: 24px;
    gap: 16px;
    width: 90%;
    margin: 24px auto 0px auto;
  }
  .investors-TermsAndUse,
  .companies-TermsAndUse {
    height: 65vh;
  }
  .Investor-terms-conditions-page .middle-block,
  .company-terms-conditions-page .middle-block {
    width: 100%;
    height: 75vh;
    margin: auto;
  }
  .tnc-block .tnc-text-block {
    width: 100%;
  }
  .tnc-last-section {
    position: fixed;
    display: flex;
    flex-direction: column;
    bottom: 0px;
    left: 0px;
    padding: 16px;
    gap: 16px;
    background: #f6f8ff;
  }
  .tnc-block .last-block {
    display: flex;
    flex-direction: column-reverse;
    gap: 16px;
  }
  .tnc-text-block p {
    font-size: 12px;
  }
}
@media all and (max-width: 410px) {
  .investors-TermsAndUse,
  .companies-TermsAndUse {
    height: 60vh;
  }
}
