.button_grp {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.btn {
  width: 75px;
  border-radius: 6px;
  border: none;
  color: white;
  height: 26px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.investor-id {
  border-radius: 6px;
  background-color: #e5f5fe;
  display: flex;
  gap: 15px;
  font-size: 16px;
  color: #00a6fb;
  font-weight: 500;
  letter-spacing: 0.32px;
  padding: 15px 21px;
  border: 0px;
  width: fit-content;
  text-wrap: nowrap;
  align-items: center;
  border: 1px solid #b7e5fd;
  font-family: "Roboto";
}
.userDetails-heading {
  color: #696f79;
  text-align: justify;
  font-size: 14px;
  font-weight: 300;
}
.userDetails-text {
  color: #1a1a1a;
  font-family: Karla;
  font-size: 16px;
  font-weight: 600;
}
.investor-card-section-2 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px 8px;
}
.investor-card-tag {
  font-size: 12px;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 20px;
  display: flex;
  gap: 2px;
  text-wrap: nowrap;
}
.investor-card-date-tag {
  font-size: 12px;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 1px;
  padding-bottom: 1px;
  border-radius: 24px;
  color: #000;
}
